import React from 'react';
import {useStoreActions, useStoreState} from 'easy-peasy'
import {Input, Form} from 'reactstrap'

const SearchBarSubmit = (props) => {

    const {
        isSearching
    } = useStoreState(state => ({
        isSearching: state.isSearching
    }))

    const {searchPosts, searchList} = useStoreActions(action => ({
        searchPosts: action.searchPosts,
        searchList: action.searchList
    }))

    const {focusHandler, type, removeSearching} = props

    const submitForm = (e) => {
        e.preventDefault()

        if (isSearching) return

        const {value} = e.target[0]

        if (value === '' && removeSearching) {
            removeSearching()
        }

        // if (value.length < 3) {
        //     return
        // }

        let payload = {
            query: value,
            type: type //1-Minute, 2-QNA, 0-Posts  4: shared
        }

        const accountSearches = ['accounts', 'notif', 'recommend']

        if (accountSearches.includes(type)) {
            searchList(payload)
        } else {
            searchPosts(payload)
        }

        if (focusHandler) {
            focusHandler()
        }
    }

    return (
        <>
            <Form onSubmit={submitForm}>
                <Input foc name="search" placeholder="Search here..."/>
            </Form>
            <span className="span-grey text-small">Press enter to submit</span>
        </>
    );
};

export default SearchBarSubmit