import { action, thunk } from "easy-peasy";
import { auth } from '../helpers/Firebase';
import { baseUrl, baseHeader, baseMultipartHeader } from '../constants/defaultValues'
import { createNotification } from '../helpers/Notification'
import axios from 'axios'
import { findHashtags } from "../helpers/Regex";
import moment from "moment";
import { config } from "../constants/config"

const defaultState = {
    session: {
        token: localStorage.getItem("token") || "",
        profile: {},
    },
    accounts: [],
    speakers: [],
    thumbnails: [],
    accountsCopy: [],
    podcasts: [],
    loaded: true,
    isSearching: false,
    selectedPodcast: {

    },
    selectedCollection: {

    },
    selectedUser: {

    },
    selectedFlag: {

    },
    selectedFlaggedUser: {

    },
    selectedComment: {

    },
    notifData: {

    },
    posts: {
        featured: {
        },
        posts: [
            //feed
        ],
        minutes: [
            //minutes
        ],
        qna: [

        ],
        shared: [

        ],
        popular: [

        ],
        flagged: [

        ],
        flaggedComments: [

        ],
        searchMinutes: [

        ],
        searchQna: [

        ],
        searchPosts: [

        ],
        searchFlagged: [

        ],
        searchShared: [

        ]

    },
    users: {
        flagged: [

        ]
    },
    hashtags: [],
    podcastCollections: [],
    recommendedUsers: [],
    availableUsers: [],
    recommendedTags: [],
    recommendedSpeakers: [],
    searchNotifTarget: [],
    searchRecommendUser: [],
    notifTimer: {
    },
    pagination: {
        minutesPage: {

        },
        postPage: {

        },
        hashtagsPage: {

        },
        podcastCollectionPage: {

        },
        qnaPage: {

        },
        sharedPage: {

        },
        accountsPage: {

        },
        donationsPage: {

        },
        recommendedUserPage: {

        },
        recommnededTagPage: {

        },
        usersPage: {

        }
    },
}

export default {
   ...defaultState,

    //AUTH

    checkIsExpireToken: thunk(async (actions, payload) => {
        let tokenResult = await auth?.currentUser?.getIdTokenResult()
        if (tokenResult) {
            const expare = moment.tz(new Date(tokenResult.expirationTime).toUTCString(), "GMT").valueOf()
            const now = moment.tz(new Date().toUTCString(), "GMT").valueOf()

            function millisToMinutesAndSeconds(millis) {
                var minutes = Math.floor(millis / 60000);
                var seconds = ((millis % 60000) / 1000).toFixed(0);
                return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
            }
            const difference = expare - now

            console.log(`Token left time - ${millisToMinutesAndSeconds(difference)}`)

            if (difference < 1) {
                actions.renewToken({ fun: () => window.location.reload() })
            }
        } else {
            actions.hideLoading()
        }
    }),

    renewToken: thunk(async (actions, payload) => {
        let user = auth.currentUser
        let { fun } = payload
        if (user != null) {
            user.getIdToken(true)
                .then(idToken => {
                    actions.authenticate({
                        token: idToken,
                        fun: fun
                    })
                })
                .catch(() => {
                    createNotification('custom', "Please log in again!", "Session Expired")
                    actions.logout()
                })
        } else {
            createNotification('custom', "Please log in again!", "Session Expired")
            actions.logout()
        }
    }),

    login: thunk((actions, payload) => {
        const { email, password } = payload.payload
        const { history } = payload
        actions.showLoading()

        auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                userCredential.user.getIdToken()
                    .then(idToken => {

                        actions.authenticate({
                            token: idToken,
                            history: history
                        })

                    })
                    .catch(err => {
                        actions.checkError({ err: err })
                        actions.hideLoading()
                    })
            })
            .catch(err => {
                actions.checkError({ err: err })
                actions.hideLoading()
            })
    }),

    logout: thunk((actions, props) => {
        sessionStorage.clear()
        auth.signOut().then(() => {
            actions.revokeAuth()
        }).catch(err => {
            console.log(err)
        })
    }),

    resetPassword: thunk((actions, email) => {
        auth.sendPasswordResetEmail(email).then(res => {
            createNotification('success', "Instructions has been sent to email.")
        }).catch(err => {
            console.log(err)
            createNotification('error', "Failed to reset password")
        })
    }),

    authenticate: thunk((actions, payload) => {
        const { token, history, fun } = payload

        // state.session = {
        //     token: token
        // }

        //Wait for state to be set

        try {
            localStorage.setItem("token", token)
          } catch(e) {
            console.error(e)
        }
        
        actions.getProfile({
            token: token,
            history: history
        })

        if (fun != null) {
            fun()
        }
    }),

    revokeAuth: action((state) => {
        state.session = {
            token: "",
            profile: {}
        }
        try {
            localStorage.setItem("token", "")
        } catch(e) {
            console.error(e)
        }
    }),

    //UPLOAD
    upload: thunk(async (actions, payload) => {

        actions.showLoading()

        let post = {
            title: payload.title,
            body: payload.body,
            webUrl: "",
            otherId: payload.otherId
        }

        if (payload.file) {
            let formData = new FormData()
            formData.append('video', payload.file)

            axios.post(`${baseUrl}/media`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': localStorage.getItem('token')
                }
            }).then(res => {
            }).catch(err => {
                actions.hideLoading()
                actions.checkError({ err: err })
            })
        } else {
            post.webUrl = ""
            post.postType = 0
            actions.createPost(post)
        }

    }),

    uploadPhoto: thunk((actions, payload) => {

        let formData = new FormData()
        formData.append('photo', payload)

        axios.post(`${baseUrl}/media/photos`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        }).then(res => {
            return res
        }).catch(err => {
            actions.hideLoading()
            actions.checkError({ err: err })
            return ""
        })
    }),

    //PROFILE
    getProfile: thunk(async (actions, payload) => {
        axios.get(`${baseUrl}/me`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            actions.setProfile({
                profile: res.data.d,
                history: payload.history,
                token: payload.token
            })
            actions.hideLoading()

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    searchList: thunk(async (actions, payload) => {
        const { query, type } = payload
        const url = `${baseUrl}/v2/users?q=${query}`

        actions.setIsSearching(true)

        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.setIsSearching(false)
            if (typeof res.data.d === 'string') {

            }
            actions.setSearchList({
                users: typeof res.data.d === 'string' ? [] : res.data.d,
                type: type
            })

        }).catch(err => {
            actions.checkError({ err: err })
            actions.setIsSearching(false)
        })

    }),

    setIsSearching: action((state, isSearching) => {
        state.isSearching = isSearching
    }),

    setSearchList: action((state, payload) => {
        const { users, type } = payload

        switch (type) {
            case 'accounts': state.accounts = users; break;
            case 'notif': state.searchNotifTarget = users; break
            case 'recommend': state.searchRecommendUser = users; break
        }

    }),

    getSpeakers: thunk(async actions => {
        const url = `${baseUrl}/users/speaker_admin`
        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.setSpeakers(res.data)

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    setSpeakers: action((state, payload) => {
        let { d } = payload
        state.speakers = d
    }),

    getUsers: thunk(async (actions, payload) => {
        actions.checkIsExpireToken()
        let { page } = payload

        if (Number.isNaN(page)) {
            page = 1
        }

        const url = `${baseUrl}/v2/users?lim=25&p=${page}`

        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            res.data.d.forEach(user => {
                if (!user.claims || Array.isArray(user.claims)) {
                    user.claims = { speaker: false, admin: false }
                }

            })
            actions.populateUsers(res.data)
            actions.setIsSearching(false)

        }).catch(err => {
            actions.checkError({ err: err })
            actions.setIsSearching(false)
        })
    }),

    populateUsers: action((state, payload) => {

        let { d, p, n, np, lim, next, prev } = payload

        // function compare(a, b) {

        //     if (a.claims.speaker > b.claims.speaker) {
        //         return -1;
        //     }
        //     if (a.claims.admin > b.claims.admin) {
        //         return -1;
        //     }
        //     return 1;
        // }

        // d.sort(compare);

        state.pagination.usersPage = {
            n: n, //total items
            lim: lim, //item per page
            np: np, //total pages
            p: p, //current page
            next: next, //next api call
            prev: prev //prev api call
        }
        if (d) {
            state.accounts = d
        }
    }),

    sortList: action((state, payload) => {

        const { value, order } = payload

        function compare(a, b) {

            let aDate
            let bDate
            let condition

            switch (value) {

                case "firstName":
                    condition = order === 'asc' ? a.firstName > b.firstName : a.firstName < b.firstName
                    if (condition) {
                        return 1
                    } else {
                        return -1
                    }
                case "lastName":
                    condition = order === 'asc' ? a.lastName > b.lastName : a.lastName < b.lastName
                    if (condition) {
                        return 1
                    } else {
                        return -1
                    }
                case "email":
                    condition = order === 'asc' ? a.email > b.email : a.email < b.email
                    if (condition) {
                        return 1
                    } else {
                        return -1
                    }
                case "created":
                    aDate = new Date(a.dateCreated)
                    bDate = new Date(b.dateCreated)

                    condition = order === 'asc' ? aDate < bDate : aDate > bDate

                    if (condition) {
                        return -1
                    } else {
                        return 1
                    }
                case "updated":
                    aDate = new Date(a.dateUpdated)
                    bDate = new Date(b.dateUpdated)

                    condition = order === 'asc' ? aDate < bDate : aDate > bDate
                    if (condition) {
                        return -1
                    } else {
                        return 1
                    };
                default:

                    if (a.claims.speaker > b.claims.speaker) {
                        return -1;
                    }
                    if (a.claims.admin > b.claims.admin) {
                        return -1;
                    }
                    return 1;

            }
        }

        state.accounts = state.accounts.sort(compare)

    }),

    deleteUser: thunk(async (actions, payload) => {
        const { id, pushHistory } = payload
        axios.delete(`${baseUrl}/users/${id}`)
            .then(res => {
                actions.updateUser({
                    userId: id,
                    action: 1,
                    pushHistory: pushHistory
                })
            }).catch(err => {
                actions.checkError({ err: err })
            })
    }),

    createUser: thunk(async (actions, payload) => {

        let imageData = null
        if (payload.file) {

            try {
                let formData = new FormData()
                formData.append('photo', payload.file)
                imageData = await axios.post(`${baseUrl}/media/photos`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': localStorage.getItem('token')
                    }
                })
            } catch (e) {
                console.log(e)
            }
        }

        const tnc = await axios.get(`${baseUrl}/terms/latest`)

        let newImageUrl = ""
        if (imageData) {
            newImageUrl = await imageData.data.d.photoUrl
        } else {
            newImageUrl = payload.imageUrl
        }

        const request = {
            email: payload.email,
            firstName: payload.firstName,
            lastName: payload.lastName,
            phone: payload.phone,
            password: payload.password,
            imageUrl: newImageUrl,
            about: payload.about,
            tncVersion: tnc.data.d.version
        }

        axios.post(`${baseUrl}/register`, request).then(res => {
            createNotification('success', "Account created!")
            payload.push()
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    updateUserLevel: thunk((actions, payload) => {
        const { user, selected } = payload
        let data

        switch (selected) {
            case "1":
                data = {
                    data: {
                        speaker: false,
                        admin: true
                    }
                }
                break;
            case "2":
                data = {
                    data: {
                        speaker: true,
                        admin: false
                    }
                }
                break;

            default: data = {
                data: {
                    speaker: false,
                    admin: false
                }
            }
                break;

        }

        let url = `${baseUrl}/users/${user._id}/makespeaker`

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            createNotification('success', "Account level updated")
            actions.getUsers({
                page: 1
            })

        }).catch(err => {
            console.log(err)
            actions.checkError({ err: err })
        })

    }),

    updateUserLevelinList: action((state, payload) => {
        const { userId, claims } = payload
        state.accounts.forEach(user => {
            if (user._id === userId) {
                user.claims = claims
            }
        })

        state.accountsCopy.forEach(user => {
            if (user._id === userId) {
                user.claims = claims
            }
        })
    }),

    editUser: thunk(async (actions, payload) => {

        let imageData = null

        actions.showLoading()

        if (payload.file) {

            try {
                let formData = new FormData()
                formData.append('photo', payload.file)

                imageData = await
                    axios.post(`${baseUrl}/media/photos`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': localStorage.getItem('token')
                        }
                    })
            } catch (e) {
                imageData = null
            }
        }

        let newImageUrl = ""
        if (imageData) {
            newImageUrl = await imageData.data.d.photoUrl
        } else {
            newImageUrl = payload.imageUrl
        }

        // return

        let newPayload = {
            firstName: payload.firstName,
            lastName: payload.lastName,
            userName: payload.userName,
            imageUrl: newImageUrl,
            email: payload.email,
            phone: payload.phone,
            userLevel: payload.userLevel,
            about: payload.about
        }
        

        if (payload && payload.disableOptions) {
            newPayload = { ...payload }
        }

        if (payload && payload.isCustomPayload) {
            newPayload = { ...payload }
            delete newPayload.isCustomPayload
            delete newPayload._id
        }

        axios.post(`${baseUrl}/users/${payload._id}`, newPayload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.setSelectedUser(res.data.d)
            actions.hideLoading()
            createNotification('success', "Account Updated!")
        }).catch(err => {
            actions.hideLoading()
            // createNotification('error', err.response.message)
            actions.checkError({ err: err })
        })

    }),

    editEmail: thunk((actions, payload) => {
        axios.post(`${baseUrl}/users/email/${payload.uid}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            actions.getUsers({
                page: 1
            })

            actions.setEmailInList(payload)
            createNotification('success', "Email Updated!")
        }).catch(err => {
            actions.hideLoading()
            actions.checkError({ err: err })
        })

    }),

    setEmailInList: action((state, payload) => {
        const { email, _id } = payload
        state.accounts.forEach(account => {
            if (account._id === _id) {
                account.email = email
            }
        })
        state.accountsCopy.forEach(account => {
            if (account._id === _id) {
                account.email = email
            }
        })
    }),

    setProfile: action((state, payload) => {
        const { profile, history, token } = payload

        if (!profile.claims.admin && !profile.claims.speaker) {
            try {
                localStorage.setItem("token", "")
            } catch(e) {
                console.error(e)
            }
            state.session = {
                token: "",
                profile: {}
            }
            createNotification("error", "This account is not authorized access this content")
        } else {
            state.session = {
                token: token,
                profile: profile
            }
        }
        if (history != null) {
            setTimeout(function () {
                history.push("/app")
            }, 200)
        }
    }),

    getUserFromId: action((state, id) => {

        if (state.accounts) {
            state.accounts.forEach(account => {
                if (account._id === id) {
                    state.selectedUser = account
                }
            })
        }

    }),


    getUserFromUID: action((state, uid) => {

        if (state.accounts) {
            state.accounts.forEach(account => {
                if (account.uid === uid) {
                    state.selectedUser = account
                }
            })
        }

    }),

    getFollows: thunk((actions, payload) => {
        const { id, type } = payload

        let url = type === '1' ? `${baseUrl}/users/${id}/followers` : `${baseUrl}/users/${id}/following`

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            let { d } = res.data
            let newPayload = {
                follows: d,
                type: type
            }
            actions.setUserFollows(newPayload)

        }).catch(err => {
            actions.checkError({ err: err })
        })

    }),

    setUserFollows: action((state, payload) => {
        const { follows, type } = payload
        if (type === '1') {
            state.selectedUser.followers = follows
        } else {
            state.selectedUser.following = follows
        }

    }),

    setSelectedUser: action((state, payload) => {
        state.selectedUser = payload
    }),

    updateUser: action((state, payload) => {
        const { userId, action, pushHistory } = payload
        switch (action) {
            case 0: //Update status
                state.accounts.forEach(user => {
                    if (user._id === userId) {
                        user.status = (user.status === 1) ? 2 : 1
                    }
                })
                break;
            case 1: //Delete
                state.accounts = state.accounts.filter(account => account._id !== userId)
                if (pushHistory) {
                    setTimeout(function () {
                        pushHistory()
                    }, 500)
                }
                break;
            case 2: //Something
                break;
            default:
                break;
        }
    }),

    //Podcast Collection
    getThumbnails: thunk((actions, payload) => {
        actions.checkIsExpireToken()
        let { page } = payload
        let url = ''
        if (Number.isNaN(page)) {
            page = 1
        }
        url = `${baseUrl}/thumbnails?p=${page}&lim=20`

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            let { d, n, lim, np, p, next, prev } = res.data
            actions.setThumbnails({
                thumbnails: d,
                n: n,
                lim: lim,
                np: np,
                p: p,
                next: next,
                prev: prev
            })

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    setThumbnails: action((state, payload) => {
        let { thumbnails, n, lim, np, p, next, prev } = payload

        state.thumbnails = thumbnails
        // state.pagination.podcastCollectionPage = {
        //     n: n, //total items
        //     lim: lim, //item per page
        //     np: np, //total pages
        //     p: p, //current page
        //     next: next, //next api call
        //     prev: prev //prev api call
        // }
    }),

    editThumbnail: thunk((actions, payload) => {
        const { id, data, history } = payload

        axios.put(`${baseUrl}/thumbnails/${id}`, data,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const item = res?.data?.d
            actions.updateThumbnails({
                thumbnail: item
            })
            createNotification("success", `Thumbnail Updated!`)
            history.push('/app/thumbnails')

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    updateThumbnails: action((state, payload) => {
        let { thumbnail } = payload
        let index = state.thumbnails.findIndex(i =>  i._id === thumbnail._id)
        state.thumbnails.splice(index, 1, thumbnail)
    }),


    createThumbnail: thunk((actions, payload) => {
        const { data, history } = payload

        axios.post(`${baseUrl}/thumbnails`, data,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const item = res?.data?.d
            actions.pushToThumbnails({
                item
            })
            createNotification('success', `Thumbnail created!`)
            history.push('/app/thumbnails')
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    pushToThumbnails: action((state, payload) => {
        let { item } = payload
        state.thumbnails.push(item)
    }),

    deleteThumbnail: thunk((actions, payload) => {
        const { id } = payload
        axios.delete(`${baseUrl}/thumbnails/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.removeThumbnail({
                id: id,
            })
            createNotification('warning', `Thumbnail deleted!`)
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    removeThumbnail: action((state, payload) => {
        let { id } = payload
        state.thumbnails = state.thumbnails.filter(item => item.id !== id)
    }),

    //MODALS

    //Podcast Collection
    getPodcastCollection: thunk((actions, payload) => {
        actions.checkIsExpireToken()

        let { page } = payload
        let url = ''
        if (Number.isNaN(page)) {
            page = 1
        }
        url = `${baseUrl}/podcastsCollections?p=${page}&lim=10`

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            let { d, n, lim, np, p, next, prev } = res.data
            actions.setPodcastCollection({
                podcastCollections: d,
                n: n,
                lim: lim,
                np: np,
                p: p,
                next: next,
                prev: prev
            })

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    setPodcastCollection: action((state, payload) => {
        let { podcastCollections, n, lim, np, p, next, prev } = payload

        state.podcastCollections = podcastCollections
        state.pagination.podcastCollectionPage = {
            n: n, //total items
            lim: lim, //item per page
            np: np, //total pages
            p: p, //current page
            next: next, //next api call
            prev: prev //prev api call
        }
    }),

    editCollection: thunk((actions, payload) => {
        const { id, data, history } = payload

        axios.put(`${baseUrl}/podcastsCollections/${id}`, data,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const item = res?.data?.d
            actions.updateCollection({
                podcast: item
            })
           createNotification("success", `Collection Updated!`)
            history.push('/app/podcasts')

        }).catch(err => {
            console.log('err', err)
            actions.checkError({ err: err })
        })
    }),

    createCollection: thunk((actions, payload) => {
        const { data, history } = payload

        axios.post(`${baseUrl}/podcastsCollections`, data,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const item = res?.data?.d
            actions.pushInCollection({
                item
            })
            createNotification('success', `Podcast Collection created!`)
            history.push('/app/podcasts')
        }).catch(err => {
            console.log('err', err)
            actions.checkError({ err: err })
        })
    }),

    pushInCollection: action((state, payload) => {
        let { item } = payload
        state.podcastCollections.push(item)
    }),

    deleteCollection: thunk((actions, payload) => {
        const { id } = payload
        axios.delete(`${baseUrl}/podcastsCollections/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.removeCollection({
                id: id,
            })
            createNotification('warning', `Hashtag deleted!`)
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    removeCollection: action((state, payload) => {
        let { id } = payload
        state.podcastCollections = state.podcastCollections.filter(item => item.id !== id)
    }),

    updateCollection: action((state, payload) => {
        let { podcast } = payload
        let index = state.podcastCollections.findIndex(i =>  i._id === podcast._id)
        state.podcastCollections.splice(index, 1, podcast)
    }),

    setSelectedCollection: action((state, payload) => {
        state.selectedCollection = payload
    }),

    //HASHTAGS
    getHashTags: thunk((actions, payload) => {
        let { type, page } = payload
        let url = ''
        if (Number.isNaN(page)) {
            page = 1
        }
        url = `${baseUrl}/hashtag?lim=10&p=${page}`

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            let { d, n, lim, np, p, next, prev } = res.data
            actions.setHashTags({
                type: type,
                hashtags: d,
                n: n,
                lim: lim,
                np: np,
                p: p,
                next: next,
                prev: prev
            })

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    setHashTags: action((state, payload) => {
        let { type, hashtags, n, lim, np, p, next, prev } = payload

        state.hashtags = hashtags
        state.pagination.hashtagsPage = {
            n: n, //total items
            lim: lim, //item per page
            np: np, //total pages
            p: p, //current page
            next: next, //next api call
            prev: prev //prev api call
        }
    }),

    editHashTag: thunk((actions, payload) => {
        const { hashtagId, data, type } = payload

        axios.put(`${baseUrl}/hashtag/${hashtagId}`, data,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const item = res?.data?.d
            actions.updateHashTag({
                hashtag: item
            })
            if (!!type) {
                if (type === 'image') {
                    createNotification('success', `Image uploaded!`)
                } else {
                    const element = item[type]
                    let message = element ? type : `un${type}`
                    let typeNotif = element ? "success" : "warning"
                    createNotification(typeNotif, `Hashtag ${message}!`)
                }
            }
        }).catch(err => {
            console.log('err', err)
            actions.checkError({ err: err })
        })
    }),

    deleteHashTag: thunk((actions, payload) => {
        const { hashtag } = payload
        const { id } = hashtag

        axios.delete(`${baseUrl}/hashtag/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.removeHashTag({
                id: id,
            })
            createNotification('warning', `Hashtag deleted!`)
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    removeHashTag: action((state, payload) => {
        let { id } = payload
        state.hashtags = state.hashtags.filter(item => item.id !== id)
    }),

    updateHashTag: action((state, payload) => {
        let { hashtag } = payload
        if (!hashtag.hasOwnProperty('id')) hashtag.id = hashtag._id
        let index = state.hashtags.findIndex(i =>  i.id === hashtag._id)
        state.hashtags.splice(index, 1, hashtag)
    }),


    //POSTS
    getPosts: thunk((actions, payload) => {
        actions.checkIsExpireToken()

        let { type, page } = payload
        //me/feed
        //posts/minutes
        //posts/popular
        //posts/qna

        // let url = type === '1' ? `${baseUrl}/posts/minutes?lim=10&p=${page}` : `${baseUrl}/me/feed?lim=10&p=${page}`
        let url = ''

        if (Number.isNaN(page)) {
            page = 1
        }

        switch (type) {
            case '1': url = `${baseUrl}/posts/minutes?lim=10&p=${page}`; break;
            case '3': url = `${baseUrl}/posts/qna?lim=10&p=${page}`; break
            // default: url = `${baseUrl}/index/search?q=*&o=posts&lim=200&p=${page}`; break;
            case '4': url = `${baseUrl}/sharedPosts?lim=10&p=${page}`; break;
            default: url = `${baseUrl}/posts?lim=10&p=${page}`; break;

            // case '2': url = `${baseUrl}/me/feed?lim=10&p=${page}`; break;

            // case '1': url = `${baseUrl}/index/search?q=*?o=minutes`; break;
            // case '2': url = `${baseUrl}/index/search?q=*&o=posts&lim=200&p=${page}`; break;
            // case '3': url = `${baseUrl}/index/search?q=*?o=qna`; break;

        }

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            let { d, n, lim, np, p, next, prev } = res.data

            // if (type === '2') {
            //     d = d.posts
            //     np = Math.ceil(n / lim)
            // }

            d.forEach(post => {
                post.isPlaying = false
                post.isLiked = false
                if (!post.comments) {
                    post.comments = []
                }
                post.displayTag = []
            })

            actions.setPosts({
                type: type,
                posts: d,
                n: n,
                lim: lim,
                np: np,
                p: p,
                next: next,
                prev: prev
            }
            )

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    uploadMedia: thunk(async (actions, payload) => {
        let formData = new FormData()
        let { mimeType, postId } = payload
        let fileType = mimeType || (payload && payload.file && payload.file.type)
        let videoApi =  payload.isVideoCompressed ? '/media/compressed/video' : `/media?postId=${postId}`

        let uploadUrl = ""
        let uploadData = null

        if (payload.file) {
            try {
                actions.showLoading()

                if (fileType.includes('video')) {
                    uploadUrl = `${baseUrl}${videoApi}`
                    formData.append('video', payload.file)
                } else {
                    formData.append('photo', payload.file)
                    uploadUrl = `${baseUrl}/media/photos`
                }

                uploadData = await axios.post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': localStorage.getItem('token')
                    }
                }).catch(err => {
                    actions.hideLoading()
                    actions.checkError({ err: err })
                })

                let { d } = uploadData.data
                actions.hideLoading()

                if (fileType.includes('video')) {
                    payload.thumbnailUrl = d.thumbnailUrl
                    payload.webUrl = d.videoUrl
                    payload.mediaUrl = d.origMediaUrl
                    payload.origMediaUrl = d.origMediaUrl
                } else {
                    payload.mediaUrl = d.photoUrl
                    payload.thumbnailUrl = d.photoUrl
                }
                return payload

            } catch (e) {
                console.log(e)
            }
        }
    }),

    createPost: thunk(async (actions, payload) => {

        //Other id if user selected another email in create post as

        const {
            otherId,
            title,
            webUrl,
            body,
            mimeType,
            kind,
            thumbnailUrl,
            postType,
            medias,
            videoInfo
        } = payload

        let payloadPost = {
            title,
            webUrl,
            body,
            mimeType,
            kind,
            thumbnailUrl: thumbnailUrl ? thumbnailUrl : medias[0]?.mediaUrl,
            postType,
            medias
        }

        if (payloadPost.mimeType.includes('video')) {
            payloadPost.videoInfo = videoInfo
        }
        let post = {}
        let url = otherId ? `${baseUrl}/users/${otherId}/posts` : `${baseUrl}/me/posts`
        actions.showLoading()
        axios.post(url, payloadPost, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(async res => {
             post = res && res.data && res.data.d && res.data.d
            if (post && post.body) {
                const hashtags = findHashtags(res.data.d.body)
                if (hashtags && hashtags.length) {
                    let data = {
                        postId: post._id,
                        hashTags: hashtags,
                    }
                    actions.addHashtagsToPost(data)
                }
            }
            if (mimeType.includes('video')) {
                await uploadMedia()
            }
            createNotification('success', "Post created!")
            actions.hideLoading()
            payload.history.push('/app/posts')
        }).catch(err => {
            actions.checkError({ err: err })
            actions.hideLoading()
        })
         const uploadMedia = async() => {
            payload.postId = post._id
            let media = await actions.uploadMedia(payload)
            const newData = {
                mediaUrl: media?.origMediaUrl || '',
                origMediaUrl: media?.origMediaUrl || '',
                thumbnailUrl: media?.thumbnailUrl || '',
            }
            const updatedPayload = {
                post,
                data: newData
            }
            await actions.updatePost(updatedPayload)
        }

    }),

    addHashtagsToPost: thunk(async (actions, payload) => {
        // let { postId, hashTags } = payload
        let url = `${baseUrl}/me/posts/hashtag`

        axios.post(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            createNotification('success', "Post added to hashtag!")
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    likePost: thunk(async (actions, payload) => {

        const { id, isLike, postType, profile, author } = payload
        const url = isLike ? `${baseUrl}/posts/${id}/like` : `${baseUrl}/posts/${id}/unlike`

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            actions.addToLikedPosts({
                id: id,
                like: isLike
            })
            actions.toggleLike({
                id: id,
                postType: postType
            })
            let message = isLike ? "liked" : "unliked"
            let type = isLike ? "success" : "warning"
            createNotification(type, `Post ${message}!`)

        }).catch(err => {
            actions.checkError({ err: err })
        })

    }),

    addToLikedPosts: action((state, payload) => {
        const { id, like } = payload
        if (state.session.profile) {
            let liked = state.session.profile.likedPosts
            if (like) {
                liked.push(id)
            } else {
                let index = -1
                liked.forEach((data, i) => {
                    if (data === id) {
                        index = i
                    }
                })
                if (index > -1) {

                    liked.splice(index, 1)
                }
            }

            state.session.profile.likedPosts = liked
        }
    }),

    deletePost: thunk((actions, payload) => {
        const { post, pushHistory } = payload
        const { _id, postType } = post

        axios.delete(`${baseUrl}/posts/${_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.removePost({
                id: _id,
                postType: postType,
                pushHistory: pushHistory
            })
            createNotification('warning', "Post deleted")
        }).catch(err => {
            actions.checkError({ err: err })
            // createNotification('error', "Could not delete post")
        })
    }),

    updatePost: thunk((actions, payload) => {
        const { post, data } = payload
        axios.post(`${baseUrl}/posts/${post._id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            createNotification('success', "Post Updated!")
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    searchPosts: thunk((actions, payload) => {
        const { query, type } = payload
        let url = `${baseUrl}/index/search?q=${query}&postType=${type}&isAdmin=true`

        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            let posts = res.data.d

            posts.forEach(post => {
                post.isPlaying = false
                post.isLiked = false
                if (!post.comments) {
                    post.comments = []
                }
                post.displayTag = []

            })

            actions.setPostSearchResults({
                type: type,
                data: posts
            })

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    setPostSearchResults: action((state, payload) => {
        let { data, type } = payload

        let newData = [...data]
        newData = newData.filter(post => post.postType === type)

        newData.forEach(post => {
            post.tags.forEach(tag => {
                let displayTag = {
                    id: tag,
                    text: tag
                }
                post.displayTag.push(displayTag)
            })
        })

        switch (type) {
            case 1:
                state.posts.searchMinutes = newData
                break; //Minute
            case 2:
                state.posts.searchQna = newData
                break; // QNA
            // case 0:
            //     state.posts.searchPosts = data
            //     break; //POST
            case 3:
                state.posts.searchShared = newData
                break;
            default: state.posts.searchPosts = newData
                break;//POST
        }
    }),

    clearPostSearchResults: action((state, payload) => {
        state.posts.searchPosts = []
        state.posts.searchMinutes = []
        state.posts.searchQna = []

    }),

    addSponsor: thunk(async (actions, payload) => {
        const { postId, sponsor } = payload
        axios.post(`${baseUrl}/posts/${postId}/sponsor`, sponsor, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            createNotification('success', "Post Sponsored")
            actions.setSponsorsToPost({
                postId: postId,
                sponsors: res.data.d.sponsors
            })
        }).catch(err => {
            actions.checkError({ err: err })
            // createNotification('error', "Could not add sponsor")
        })

    }),

    setSponsorsToPost: action((state, payload) => {
        const { postId, sponsors } = payload
        state.posts.minutes.forEach(minute => {
            if (minute._id === postId) {
                minute.sponsors = sponsors
            }
        })
    }),

    getFeatured: thunk(async actions => {
        axios.get(`${baseUrl}/posts/featured`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            let { d } = res.data
            try {
                actions.featurePost(d)
            } catch (e) {
                console.log(e)
            }


        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    setFeatured: thunk(async (actions, payload) => {
        const data = {}
        axios.post(`${baseUrl}/posts/${payload._id}/featured`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.featurePost(res.data.d)
            createNotification('success', "Post Featured!")

        }).catch(err => {
            actions.checkError({ err: err })
        })

    }),

    //ACTION-POSTS
    playVideo: action((state, payload) => {
        let { _id, postType } = payload
        let array
        switch (postType) {
            case 1: array = state.posts.minutes; break;
            case 2: array = state.posts.qna; break;
            case 0: array = state.posts.posts; break;
            default: array = state.posts.posts; break;

        }
        array.forEach(post => {
            if (post._id === _id) {
                post.isPlaying = true
            } else {
                post.isPlaying = false
            }
        })
    }),

    removePost: action((state, payload) => {
        let { id, postType, pushHistory } = payload
        if (postType === 1) {
            state.posts.minutes = state.posts.minutes.filter(post => post._id !== id)
            state.posts.searchMinutes = state.posts.searchMinutes.filter(post => post._id !== id)

        }
        else if (postType === 2) {
            state.posts.qna = state.posts.qna.filter(post => post._id !== id)
            state.posts.searchQna = state.posts.searchQna.filter(post => post._id !== id)
        }
        else if (postType === 3) {
            state.posts.shared = state.posts.shared.filter(post => post._id !== id)
            state.posts.searchShared = state.posts.searchShared.filter(post => post._id !== id)
        }
        else {
            state.posts.posts = state.posts.posts.filter(post => post._id !== id)
            state.posts.searchPosts = state.posts.searchPosts.filter(post => post._id !== id)
        }

        if (pushHistory) {
            setTimeout(function () {
                pushHistory()
            }, 500)
        }
    }),

    setPosts: action((state, payload) => {
        let { type, posts, n, lim, np, p, next, prev } = payload
        posts.forEach(post => {
            post.tags.forEach(tag => {
                let displayTag = {
                    id: tag,
                    text: tag
                }
                post.displayTag.push(displayTag)
            })
        })

        if (type === '1') {
            state.posts.minutes = posts
            state.pagination.minutesPage = {
                n: n, //total items
                lim: lim, //item per page
                np: np, //total pages
                p: p, //current page
                next: next, //next api call
                prev: prev //prev api call
            }
        } else if (type === '3') {
            state.posts.qna = posts
            state.pagination.qnaPage = {
                n: n, //total items
                lim: lim, //item per page
                np: np, //total pages
                p: p, //current page
                next: next, //next api call
                prev: prev //prev api call
            }
        } else if (type === '4') {
            state.posts.shared = posts
            state.pagination.sharedPage = {
                n: n,
                lim: lim,
                np: np,
                p: p,
                next: next,
                prev: prev
            }
        }
        else {
            state.posts.posts = posts
            state.pagination.postPage = {
                n: n,
                lim: lim,
                np: np,
                p: p,
                next: next,
                prev: prev
            }
        }

    }),

    toggleLike: action((state, payload) => {
        const { id, postType } = payload
        const { likedPosts } = state.session.profile
        if (postType === 1) {
            state.posts.minutes.forEach(post => {
                if (post._id === id) {
                    if (!likedPosts.includes(post._id)) {
                        post.likeCount--
                    } else {
                        post.likeCount++
                    }
                    post.isLiked = !post.isLiked

                }
            })
            state.posts.searchMinutes.forEach(post => {
                if (post._id === id) {
                    if (!likedPosts.includes(post._id)) {
                        post.likeCount--
                    } else {
                        post.likeCount++
                    }
                    post.isLiked = !post.isLiked

                }
            })
        } else if (postType === 2) {
            state.posts.qna.forEach(post => {
                if (post._id === id) {
                    if (!likedPosts.includes(post._id)) {
                        post.likeCount--
                    } else {
                        post.likeCount++
                    }
                    post.isLiked = !post.isLiked

                }
            })
            state.posts.searchQna.forEach(post => {
                if (post._id === id) {
                    if (!likedPosts.includes(post._id)) {
                        post.likeCount--
                    } else {
                        post.likeCount++
                    }
                    post.isLiked = !post.isLiked

                }
            })
        }

        else {
            state.posts.posts.forEach(post => {
                if (post._id === id) {
                    if (!likedPosts.includes(post._id)) {
                        post.likeCount--
                    } else {
                        post.likeCount++
                    }
                    post.isLiked = !post.isLiked

                }
            })
            state.posts.searchPosts.forEach(post => {
                if (post._id === id) {
                    if (!likedPosts.includes(post._id)) {
                        post.likeCount--
                    } else {
                        post.likeCount++
                    }
                    post.isLiked = !post.isLiked

                }
            })
        }

    }),

    featurePost: action((state, payload) => {
        state.posts.featured = payload
    }),

    //POSTS-TAGS
    addTag: thunk((actions, payload) => {

        const { type, tag, post } = payload

        let newTags = post.tags
        newTags.push(tag)
        let keywords = ""

        newTags.forEach((tag, index) => {
            keywords = keywords + tag
            if (index < newTags.length - 1) {
                keywords = keywords + ","
            }
        })

        let data = {
            tags: newTags,
            keywords: keywords
        }

        actions.setTagToState({
            post: post,
            newTags: newTags,
            type: type,
            text: tag.text
        })

        axios.post(`${baseUrl}/posts/${post._id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

        }).catch(err => {
            actions.checkError({ err: err })
        })

    }),

    setTagToState: action((state, payload) => {
        const { type, post, text, newTags } = payload
        let array = state.posts.minutes
        let searchArray = state.posts.searchMinutes
        switch (type) {
            case 'minute':
                array = state.posts.minutes;
                searchArray = state.posts.searchMinutes
                break;
            case 'post':
                array = state.posts.posts;
                searchArray = state.posts.searchPosts
                break;
            case 'shared':
                array = state.posts.shared;
                searchArray = state.posts.searchShared
                break;
            default: array = state.posts.qna;
                searchArray = state.posts.searchQna
                break;
        }
        array.forEach(posts => {
            if (posts._id === post._id) {
                posts.tags = newTags
                posts.displayTag.push({ id: text, text: text })
            }
        });
        searchArray.forEach(posts => {
            if (posts._id === post._id) {
                posts.tags = newTags
                posts.displayTag.push({ id: text, text: text })
            }
        });
    }),

    removeTag: thunk((actions, payload) => {
        const { index, post } = payload

        let newTags = post.tags.filter((tag, i) => i !== index)
        let newDisplayTag = post.displayTag.filter((tag, i) => i !== index)

        let keywords = ""

        newTags.forEach((tag, index) => {
            keywords = keywords + tag
            if (index < newTags.length - 1) {
                keywords = keywords + ","
            }
        })


        let data = {
            tags: newTags,
            keywords: keywords
        }

        axios.post(`${baseUrl}/posts/${post._id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.removeTagFromState({ payload, newTags, newDisplayTag })
        }).catch(err => {
            actions.checkError({ err: err })
        })

    }),

    removeTagFromState: action((state, payload) => {
        const { id, type } = payload.payload
        const { newTags, newDisplayTag } = payload

        let array = state.posts.minutes
        let searchArray = state.posts.searchMinutes
        switch (type) {
            case 'minute':
                array = state.posts.minutes;
                searchArray = state.posts.searchMinutes
                break;
            case 'post':
                array = state.posts.posts;
                searchArray = state.posts.searchPosts
                break;
            case 'shared':
                array = state.posts.shared;
                searchArray = state.posts.shared
                break;
            default: array = state.posts.qna;
                searchArray = state.posts.searchQna
                break;
        }
        array.forEach(post => {
            if (post._id === id) {
                post.tags = newTags
                post.displayTag = newDisplayTag
            }
        })

        searchArray.forEach(post => {
            if (post._id === id) {
                post.tags = newTags
                post.displayTag = newDisplayTag
            }
        })

    }),

    dragTag: action((state, payload) => {

    }),

    /* COMMENT */

    getComments: thunk((actions, payload) => {

        const { _id } = payload
        axios.get(`${baseUrl}/posts/${_id}/comments`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            let { d } = res.data
            payload.comments = d
            actions.addCommentOnPost(payload)


        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    deleteComment: thunk((actions, payload) => {
        const { comment, post, parentId, pushHistory } = payload
        const url = parentId ? `${baseUrl}/comments/${parentId}/replies/${comment._id}` : `${baseUrl}/comments/${comment._id}`

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            if (post) {
                actions.getComments(post)
            }
            if (pushHistory) {

                createNotification('success', "Flagged comment deleted!")
                setTimeout(function () {
                    pushHistory()
                }, 500)

            }

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    addComment: thunk((actions, payload) => {
        const { post, request, isReply, comment } = payload

        const url = isReply ? `${baseUrl}/comments/${comment._id}/replies` : `${baseUrl}/posts/${post._id}/comments`

        axios.post(url, request, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.getComments(post)
        }).catch(err => {
            actions.checkError({ err: err })
        })

    }),

    addCommentOnPost: action((state, payload) => {
        const { _id, postType, comments } = payload

        let commentCount = 0
        comments.forEach(comment => {
            commentCount++
            commentCount += comment.replies.length

        })

        if (postType === 1) {
            //minute
            state.posts.minutes.forEach(post => {
                if (post._id === _id) {
                    post.commentCount = commentCount
                    return
                }
            })
            state.posts.searchMinutes.forEach(post => {
                if (post._id === _id) {
                    post.commentCount = commentCount
                    return
                }
            })
        } else if (postType === 2) {
            state.posts.qna.forEach(post => {
                if (post._id === _id) {
                    post.commentCount = commentCount
                }
            })
            state.posts.searchQna.forEach(post => {
                if (post._id === _id) {
                    post.commentCount = commentCount
                }
            })
        } else if (postType === 3) {

        }

        else {
            //post
            state.posts.posts.forEach(post => {
                if (post._id === _id) {
                    post.comments = comments.reverse()
                    post.commentCount = commentCount
                    return
                }
            })
            state.posts.searchPosts.forEach(post => {
                if (post._id === _id) {
                    post.comments = comments.reverse()
                    post.commentCount = commentCount
                    return
                }
            })
        }

    }),


    /* DONATIONS */

    /** RECOMMEND */

    getAvailableUsers: thunk(async actions => {
        axios.get(`${baseUrl}/users`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {

            actions.populateUsers(res.data)

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    getRecommendedTags: thunk(actions => {

        axios.get(`${baseUrl}/search/suggestions?settingName=all`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.setRecommendedTags({
                keywords: res.data.d.keywords,
                type: "all"
            })
        }).catch(err => {
            actions.checkError({ err: err })
        })

        axios.get(`${baseUrl}/search/suggestions?settingName=speaker`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.setRecommendedTags({
                keywords: res.data.d.keywords,
                type: "speaker"
            })
        }).catch(err => {
            actions.checkError({ err: err })
        })

    }),

    getRecommendedUsers: thunk(actions => {
        axios.get(`${baseUrl}/users/featured`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.setRecommendedUsers(res.data.d)
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    recommendUser: thunk((actions, payload) => {
        const { _id, data, user, index } = payload
        axios.post(`${baseUrl}/users/${_id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            if (data.isFeatured) {
                actions.addToRecommendedUsers(user)
                createNotification('success', "User Featured!")
            } else {
                actions.removeFromRecommendedUsers(index)
            }

            actions.getAvailableUsers()

        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    suggesTag: thunk((actions, data) => {
        const { keywords, type } = data
        const settingName = type === 'all' ? 'all' : 'speaker'
        let newData = {
            keywords: keywords,
            settingName: settingName
        }
        axios.post(`${baseUrl}/search/suggestions`, newData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.setRecommendedTags({
                keywords: res.data.d.keywords,
                type: data.type
            })


        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    setUsersToRecommend: action((state, payload) => {
        state.availableUsers = payload
    }),

    setRecommendedTags: action((state, payload) => {
        let { keywords, type } = payload
        if (type === 'all') {

            state.recommendedTags = keywords
        } else {
            state.recommendedSpeakers = keywords
        }
    }),

    addToRecommendedTags: action((state, payload) => {
        state.recommendedTags.push(payload)
    }),

    removeFromRecommendedTags: action((state, index) => {
        state.recommendedTags.splice(index, 1)
    }),

    setRecommendedUsers: action((state, payload) => {
        state.recommendedUsers = payload
    }),

    setAvailableUsers: action((state, payload) => {
        state.availableUsers = payload
    }),

    addToRecommendedUsers: action((state, payload) => {
        let isFound = false
        state.recommendedUsers.forEach(user => {
            if (user._id === payload._id) {
                isFound = true;
            }
        })
        if (!isFound) {
            state.recommendedUsers.push(payload)
        }

        let yFilter = state.recommendedUsers.map(itemY => { return itemY._id; });

        let filteredX = state.accounts.filter(itemX => !yFilter.includes(itemX._id));
        state.accounts = filteredX
    }),

    removeFromRecommendedUsers: action((state, index) => {
        state.recommendedUsers.splice(index, 1)
    }),

    filterRecommendedUsers: action(state => {
        let yFilter = state.recommendedUsers.map(itemY => { return itemY._id; });

        let filteredX = state.accounts.filter(itemX => !yFilter.includes(itemX._id));
        state.accounts = filteredX
    }),

    getFlaggedPost: thunk(actions => {
        const url = `${baseUrl}/posts/flagged`
        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.setFlaggedPost(res.data.d)
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),
    setFlaggedPost: action((state, payload) => {
        state.posts.flagged = payload
    }),

    setSelectedFlag: action((state, payload) => {
        state.selectedFlag = payload
    }),

    getFlaggedUser: thunk(actions => {
        const url = `${baseUrl}/users/flagged`
        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            console.log('data = ', res.data)
            actions.setFlaggedUser(res.data.d)
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),
    setFlaggedUser: action((state, payload) => {
        state.users.flagged = payload
    }),
    setSelectedFlaggedUser: action((state, payload) => {
        state.selectedFlaggedUser = payload
    }),
    getFlaggedComments: thunk(actions => {
        const url = `${baseUrl}/comments/flagged`
        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            actions.setFlaggedComments(res.data.d)
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),
    setFlaggedComments: action((state, payload) => {
        state.posts.flaggedComments = payload
    }),
    setSelectedComment: action((state, payload) => {
        state.selectedComment = payload
    }),

    registerToken: thunk((actions, payload) => {
        const url = `${baseUrl}/register/notifications`
        axios.post(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            console.log(res)
        }).catch(err => {
            actions.checkError({ err: err })
        })
    }),

    setNotifData: action((state, payload) => {
        state.notifData = payload
    }),

    sendLastNotifTimer: action((state, isError) => {
        if (!isError)
            state.notifTimer.lastNotifTime = new Date()
        else
            state.notifTimer = {}
    }),

    sendTargettedNotif: thunk((actions, payload) => {
        actions.sendLastNotifTimer(false)

        const { type } = payload
        const url = type === '2' ? `${baseUrl}/notifications/multicast` : `${baseUrl}/notifications/topics/all`

        axios.post(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            createNotification("success", "Notification sent")
        }).catch(err => {
            actions.sendLastNotifTimer(true)
            actions.checkError({ err: err })
        })
    }),

    //PODCASTS
    setSelectedPodcast: action((state, payload) => {
        state.selectedPodcast = payload
    }),

    //RE-AUTHENTICATE
    checkError: thunk(async (actions, payload) => {
        let { err } = payload
        const errUrl = err?.config?.url ? '|| ' + err?.config?.url.replace(config.host.apiUrl, '') : ''

        if (err.response) {
            try {
                let { data, status } = err.response
                if (status) {
                    switch (status) {
                        case 401:
                            actions.renewToken({ fun: () => window.location.reload() })
                            break;
                        case 503:
                            createNotification('error', "Too many requests. Please wait")
                            break;
                        case 502:
                            createNotification('error', "Bad Gateway")
                            break;
                        default: createNotification('error', `${data.message} || ${status || ''} ${errUrl}`)
                            actions.hideLoading()
                            break;
                    }
                }

            } catch (e) {
                console.log(e)
            }
        } else if (err.message) {
            try {
                const errInfo = err?.config?.method ? '|| ' + err?.config?.method : ''
                createNotification('error', `${err.message} ${errInfo} ${errUrl}`)
            } catch (e) {
                console.log(e)
            }
        }
    }),


    //timer
    startTimer: action((state) => {
        // state.selectedComment = seconds
    }),

    /** LOADER */
    showLoading: action(state => {
        state.loaded = false
    }),
    hideLoading: action(state => {
        state.loaded = true
    }),

}
