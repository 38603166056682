import React, { useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Pagination from '../../../components/common/Pagination';
import { Separator } from "../../../components/common/CustomBootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import CollectionItem from "../../../components/items/CollectionItem";

const PodcastCollection = (props) => {
    const { podcastCollections, selectedCollection } = useStoreState(state => ({
        podcastCollections: state.podcastCollections,
    }))
    const { getPodcastCollection } = useStoreActions(action => ({
        getPodcastCollection: action.getPodcastCollection,
    }))

    useEffect(() => {
        // getPodcastCollection({ page: 1 })
    }, [])

    const createCollection = () => {
        props.history.push('/app/podcast_collection/create')
    }

    const createPodcast = () => {
        props.history.push('/app/podcast_episode/create')
    }

    return (
        <>
            <Row>
                <Col>
                    <Button className={'podcast-button'} style={{ marginRight: 10 }} color="primary" onClick={createCollection}>Add Podcast</Button>
                    <Button className={'podcast-button'} color="primary" onClick={createPodcast}>Add Episode</Button>
                </Col>
            </Row>
            <Separator />
            <Row>
                {podcastCollections && podcastCollections.map(item => {
                  if (item) return <CollectionItem item={item} key={item._id} history={props.history} />
                })}
            </Row>
            <Row>
                <Col sm="12">
                    <Pagination type={"podcast_collection"} />
                </Col>
            </Row>
        </>
    )
}

export default PodcastCollection
