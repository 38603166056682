import React, {useState} from 'react';
import {Button, Collapse, Form, Input, Row, Spinner} from 'reactstrap'
import ReactPlayer from 'react-player';
import {Separator} from '../common/CustomBootstrap';
import {confirmAlert} from 'react-confirm-alert';
import {useStoreActions} from 'easy-peasy'
import PostActions from '../common/PostActions';
import PostCounts from '../common/PostCounts';
import logo from '../../assets/images/logo.jpg'
import profileImg from '../../assets/images/profile-pic.png'
import Comment from '../forms/Comment';
import ModalSponsors from '../common/ModalSponsors';
import ModalSponsorUpdate from '../common/ModalSponsorUpdate';
import ImagesSlider from "../common/ImagesSlider";

function PostMedia(props) {

  const {post, donations, profile, type} = props

  const [viewState, setIsOpen] = useState({
    isOpen: false,
    isEdit: false,
    title: post.title

  });

  const toggle = () => setIsOpen({
    isOpen: !isOpen,
    isEdit: isEdit,
    title: title,

  });

  const edit = () => setIsOpen({
    isOpen: isOpen,
    isEdit: !isEdit,
    title: title
  })

  const cancel = () => setIsOpen({
    isOpen: isOpen,
    isEdit: !isEdit,
    title: post.title
  })

  const onChangeTitle = (e) => setIsOpen({
    isOpen: isOpen,
    isEdit: isEdit,
    title: e.target.value
  })

  const {playVideo, deletePost, addSponsor, addTag, removeTag, updatePost, setNotifData} = useStoreActions(action => ({
    playVideo: action.playVideo,
    deletePost: action.deletePost,
    addSponsor: action.addSponsor,
    addTag: action.addTag,
    removeTag: action.removeTag,
    updatePost: action.updatePost,
    setNotifData: action.setNotifData
  }))

  const {isOpen, isEdit, title} = viewState

  const deletePostConfirm = () => {
    confirmAlert({
      title: 'Delete Post',
      message: 'Are you sure to delete this post?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deletePost({
            post: post
          })

        },
        {
          label: 'No'
        }
      ]
    });
  }

  const handleDelete = (i) => {
    const payload = {
      id: post._id,
      type: type,
      index: i,
      post: post
    }
    removeTag(payload)
  }

  const handleAddition = (tag) => {
    // this.setState(state => ({ tags: [...state.tags, tag] }));
    const payload = {
      id: post._id,
      type: type,
      tag: tag,
      post: post
    }

    addTag(payload)
  }

  const submitForm = (e) => {
    e.preventDefault()
    let title = e.target[0].value

    confirmUpdate(title)
  }

  const submitTag = (e) => {
    e.preventDefault()
    let tag = e.target[0].value

    if (!post.tags.includes(tag)) {

      handleAddition(tag)
      e.target[0].value = ""
    }

  }

  const confirmUpdate = (title) => {
    confirmAlert({
      title: 'Update Title',
      message: 'Are you sure to change this post\'s title?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            post.title = title
            edit()
            updatePost({
              post: post,
              data: {
                title: title
              }
            })
          }

        },
        {
          label: 'No'
        }
      ]
    });
  }

  const removeSponsor = (index, isDelete) => {
    const {sponsors} = post
    let newSponsors = sponsors

    newSponsors.splice(index, 1)
    confirmSponsorUpdate(newSponsors, isDelete)
  }

  const updateSponsor = async (payload) => {
    const {index, sponsor, isDelete} = payload
    let {sponsors} = post

    let newSponsors = sponsors

    newSponsors.splice(index, 1)
    newSponsors.push(sponsor)

    // console.log(newSponsors)
    confirmSponsorUpdate(newSponsors, isDelete)
  }

  const announce = () => {
    setNotifData(post)
    props.history.push("/app/announcements/post")
  }

  const confirmSponsorUpdate = (newSponsors, isDelete) => {
    const title = isDelete ? 'Delete Sponsors' : 'Update Sponsors'
    confirmAlert({
      title: title,
      message: 'Are you sure to change this post\'s sponsorship?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {

            updatePost({
              post: post,
              data: {
                sponsors: newSponsors
              }
            })
          }

        },
        {
          label: 'No'
        }
      ]
    });
  }

  let imagesList

  if (post.mimeType.includes('image')) {
    if (post.medias.length > 1) {
      imagesList = <ImagesSlider images={post.medias}/>
    } else if (post.medias.length <= 1) {
      imagesList = <img width="100%" alt="thumbnail" src={post.thumbnailUrl}/>
    }
  }

  return (
    <>
      <div className="card post-container">
        <p>ID: {post?.id}</p>
        <Row>
          <div className="post-options">
            <Button className='ml-1' color="danger" onClick={deletePostConfirm}> Delete </Button>
            {type === 'minute' ?
              <ModalSponsors donations={donations} changePage={props.changePage} postId={post._id} addSponsor={addSponsor}/> : ""} {' '}
            <Button className='mr-1' onClick={announce}>Promote</Button>
          </div>
        </Row>
        <br/>
        {type === 'shared' ?
          <>
            <Row>
              <div className="post-user">
                <img src={post.imageUrl || logo} alt="profile" className="avatar" width="50px"
                     height="50px"/>
                <div className="post-info">
                  <span className="span-author">{post.authorName}</span> <span
                  className="span-grey">reposted</span>
                </div>
              </div>
            </Row>
            <Row>
              <div className="post-wrapper">
                <span>{title}</span>
              </div>
            </Row>
            <Separator/>
          </>
          :
          null}

        <Row>
          <div className="post-user">
            <img src={post.origAuthorImageUrl || post.imageUrl || profileImg} alt="profile" className="avatar"
                 width="60px" height="60px"/>

            {
              isEdit ?
                <div className="post-info">
                  <Form onSubmit={submitForm}>
                    <Input type="text" name="title" placeholder="Enter Title"
                           onChange={onChangeTitle} value={title} autoComplete="off"/>
                    <span className="span-grey text-small">Press enter to submit</span> <span
                    className="span-grey text-small" onClick={cancel}><b>Cancel</b></span>
                  </Form>
                </div>
                :
                <div className="post-info">
                                    <span
                                      className="span-author">{post.origAuthorName || post.authorName || ''}</span>
                  {type === 'minute' || type === 'qna' ?
                    <span className="span-grey text-small" onClick={edit}><b>Edit</b></span> : ""}

                </div>

            }


            {/* <UncontrolledDropdown direction="right">
                            <DropdownToggle className="p-0" color="empty">

                                <span className="span-horizontal-ellipsis">...</span>
                            </DropdownToggle>
                            <DropdownMenu className="mt-3" right>
                                <DropdownItem onClick={announce}>
                                    Promote
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={deletePostConfirm}>
                                    Delete
                               </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown> */}
          </div>
          {type === 'minute' ? <span className="post-body">{title}</span> : ''}

        </Row>
        <Row>
          <div className="post-wrapper">
            {
              post.sponsors.length > 0 ? <div>
                <span className="span-grey text-small">Sponsors: {post.sponsors.length}</span>
              </div> : ""
            }

            {
              post.sponsors.map((sponsor, index) => {

                return <ModalSponsorUpdate
                  key={sponsor._id}
                  sponsor={sponsor}
                  index={index}
                  removeSponsor={removeSponsor}
                  updateSponsor={updateSponsor}/>
              })
            }

            {type === 'post' || type === 'shared' ? <span className="post-body">{post.body}</span> : <></>}

            {
              post.mimeType.includes('video') ?
                post.status === 'video-uploading' ?
                  <div className='thumbnail-box'>
                    <img className='thumbnail' src={post.thumbnailUrl} />
                    <div className='overlay'>
                      <Spinner size={30} color='dark' /> 
                      <p> Refresh the page in few minutes to see your post
                        Aprroximate compressing time for 400mb - 7 min</p>
                    </div>
                  </div>
                : <div>
                  <ReactPlayer
                    width="100"
                    height="100"
                    url={post.mediaUrl || post.webUrl}
                    // url ="https://d30usy2s9n1syq.cloudfront.net/output/hls/test1234.m3u8"
                    controls
                    config={{
                      file: {
                        hlsOptions: {
                          maxMaxBufferLength: 1
                        }
                      }
                    }}
                    playing={post.isPlaying}
                    onPlay={() => {
                      playVideo(post)
                    }}
                  />
                </div> : ""

            }

            {imagesList}

            {
              post.displayTag.length > 0 ? <div>
                <span className="span-grey text-small">Tags: {post.displayTag.length}</span>
              </div> : ""
            }
            {/* <ReactTags
                            tags={post.displayTag}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            delimiters={delimiters}
                            inline={false} />
                        <span className="span-grey text-small">Press enter to submit</span> */}

            <div>
              {
                post.tags.map((tag, index) => {
                  return <span key={index} className="sponsor-tag"> <span> {tag} </span>   <span
                    className="span-delete"
                    onClick={() => handleDelete(index)}>  x  </span>  </span>
                })
              }
            </div>
            <Separator/>
            <div>
              <Form onSubmit={submitTag}>
                <Input placeholder="Type new keyword..."/>
                <span className="span-grey text-small">Press enter to submit</span>

              </Form>
            </div>

          </div>
        </Row>
        <Row>
          <PostCounts post={post}/>
        </Row>
        <Row>
          <PostActions post={post} type={type} toggle={toggle}/>
        </Row>
        <Collapse isOpen={isOpen}>
          <Comment history={props.history} post={post} profile={profile} isReply={false}/>
        </Collapse>
      </div>
    </>
  );
}

export default PostMedia;