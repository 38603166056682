import axios from "axios"
import { baseUrl } from "../constants/defaultValues"
import { createNotification } from "./Notification"

export default class Api {
  
  getUpdateModals = (setCallback) => {
    let url = `${baseUrl}/modals/update`
    axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("token") || ''
        }
    }).then(res => {
        let { d, n, lim, np, p, next, prev } = res.data
        setCallback(d)
    }).catch(err => {
      this.checkError({ err: err })
    })
  }

  getModals = (page, setCallback) => {
    this.checkIsExpireToken()

    let url = ''
    if (Number.isNaN(page)) {
        page = 1
    }
    url = `${baseUrl}/modals?p=${page}&lim=100`

    axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("token") || ''
        }
    }).then(res => {

        let { d, n, lim, np, p, next, prev } = res.data
        setCallback({
            modals: d,
            n: n,
            lim: lim,
            np: np,
            p: p,
            next: next,
            prev: prev
        })

    }).catch(err => {
      this.checkError({ err: err })
    })
  }


    editModal = (payload) => {
      const { id, data, history } = payload

      axios.put(`${baseUrl}/modals/${id}`, data, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem("token") || ''
          }
      }).then(res => {
          createNotification("success", `Modal Updated!`)
          history.push('/app/modals')

      }).catch(err => {
          this.checkError({ err: err })
      })
  }

  createModal = (payload) => {
      const { data, history } = payload

      axios.post(`${baseUrl}/modals`, data,{
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem("token") || ''
          }
      }).then(res => {
          createNotification('success', `Modal created!`)
          history.push('/app/modals')
      }).catch(err => {
          this.checkError({ err: err })
      })
  }

  deleteModal = (payload) => {
      const { id } = payload
      axios.delete(`${baseUrl}/modals/${id}`, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem("token") || ''
          }
      }).then(res => {
          createNotification('warning', `Modal deleted!`)
      }).catch(err => {
          this.checkError({ err: err })
      })
  }

  getDonations = (payload) => {
    this.checkIsExpireToken()
    const { page, callback, status } = payload

    const statusParam = status ? `&status=${status}` : ''

    axios.get(`${baseUrl}/donations?lim=20&p=${page}${statusParam}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("token") || ''
        }
    }).then(res => {
        callback(res.data)
    }).catch(err => {
        this.checkError({ err: err })
    })
  }

}
