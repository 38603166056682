import axios from 'axios';
import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import logo from '../../assets/images/logo.jpg'
import { baseUrl } from '../../constants/defaultValues';
import { createNotification } from '../../helpers/Notification';
import { secondsToDhms } from '../../helpers/utils';
import { Spinner } from 'reactstrap'

const defaultErrMsf = 'Commenting from your account has been temporarily suspended due to multiple policy violations. Reach out to admin for further clarification.'

const arr = [10, 30, 60, 120, 300, 720, 1440, 2880, 7200, 14400, 28800, 43200]


class DisableUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            reason: '',
            period: arr[0],
            startTime: new Date().getTime(),
            selectedUser: this.props.selectedUser,
            customTime: '',
            timeArr: arr,
            loading: true
        }
    }

    componentDidMount() {
        this.fetchUserByUid()
    }

    fetchUserByUid = async () => {
        let uid = this.props.match.params.id
        let isUid = this.props.location.search === '?isUid'
        if (isUid) {
            this.setState({ loading: true })
            axios.get(baseUrl + '/users/byuid?uidRef=' + uid, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") || ''
                }
            })
            .then((res) => {
                let user = res.data.d
                this.setState({
                    selectedUser: user,
                    loading: false
                })
    
            })
            .catch(() => this.setState({ loading: false }))
        }
    }

    register = (e) => {
        e.preventDefault()
         if (!this.state.period) {
            createNotification('warning', "Please select disable time!")
            return
         }
        const payload = {
            _id: this.state.selectedUser._id,
            disableOptions: {
                startTime: new Date().getTime(),
                period: this.state.period,
                reason: this.state.reason, 
            }
        }

        this.props.editUser(payload)
        this.props.history.goBack()
    }

    onSelect = (el) => {
      const i = el.target.value
      const item = this.state.timeArr[i]

      if (item) {
        this.setState({ period: item })
      }
    }

    valueChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }
    
    addCustomTime = () => {
        if (this.state.customTime) {
            this.setState({
                timeArr: [parseInt(this.state.customTime), ...this.state.timeArr],
                customTime: '',
                period: parseInt(this.state.customTime),
            })
        } else {
            createNotification('warning', "Please enter time!")
        }
    }
    

    render() {
        return (
            <div className="form-disable">
                <Form onSubmit={this.register}>
                    {this.state.loading
                        ? <Spinner size={30} color='dark' />
                        : <FormGroup>
                            <h2>{this.state.selectedUser.firstName} {this.state.selectedUser.lastName}</h2>
                            <img alt="Profile" src={this.state.selectedUser.imageUrl || logo} width="180px" />
                        </FormGroup>
                    }
                    <FormGroup>
                        <Label for="firstName">Disable reason</Label>
                        <Input type="textarea" onChange={this.valueChangeHandler} name="reason" placeholder={defaultErrMsf} value={this.state.reason} />
                    </FormGroup>
                     <FormGroup>
                      <Label for="body">Disable time: </Label>
                        <Input type="select" name="otherId" id="exampleSelect" onChange={this.onSelect}>
                          {this.state.timeArr.map((item, index) => {
                            return <option
                              // selected={item === state?.screen}
                              key={index}
                              value={index}>{item ? secondsToDhms(item) : ''}</option>
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        
                        <Label for="firstName">Add custom time</Label>
                        <div className='custom-time'>
                            <Input onChange={this.valueChangeHandler} type="text" name="customTime" placeholder="Enter minutes" value={this.state.customTime} />
                            <Button color={this.state.customTime.length > 0 ? 'primary' : 'secondary'} onClick={this.addCustomTime}>Add</Button>
                        </div>
                        <Label for="firstName">{secondsToDhms(this.state.customTime)}</Label>

                    </FormGroup>
                    <Button color="primary">Submit</Button>
                </Form>
            </div>
        );
    }
}

export default DisableUser;