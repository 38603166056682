import React, { useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Button } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { useStoreActions, useStoreState } from "easy-peasy";
import { confirmAlert } from "react-confirm-alert";
import ThumbItem from '../../../components/items/ThumbItem';

const Thumbnails = (props) => {
  const { thumbnails } = useStoreState(state => ({
    thumbnails: state.thumbnails,
  }))
  const { getThumbnails, deleteThumbnail } = useStoreActions(action => ({
    getThumbnails: action.getThumbnails,
    deleteThumbnail: action.deleteThumbnail,
  }))

  useEffect(() => {
    getThumbnails({
      page: 1
    })
  }, [])

  const createThumbnail = () => {
    props.history.push('/app/thumbnails/create')
  }

  const edit = (id) => {
    props.history.push(`/app/thumbnails/edit/${id}`)
  }

  const removeThumbnail = (id) => {
    confirmAlert({
      title: 'Delete Thumbnail',
      message: 'Are you sure to delete this thumbnail?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteThumbnail({
            id: id
          })

        },
        {
          label: 'No'
        }
      ]
    });
  }

  return (
    <>
      <Row>
        <Colxx xxs='12'>
          <Breadcrumb>
            <BreadcrumbItem>Thumbnails</BreadcrumbItem>
            <BreadcrumbItem active >{'List'}</BreadcrumbItem>
          </Breadcrumb>
          <Separator />
          <Row>
            <Colxx lg='6' xxs='3'>
              <Button className={'podcast-button'} color="primary" onClick={createThumbnail}>Add Thumbnail</Button>
            </Colxx>
          </Row>
          <Row>
            {thumbnails && thumbnails.map(item => {
              return <ThumbItem item={item} remove={removeThumbnail} edit={edit} />
            })}
          </Row>
        </Colxx>
      </Row>

    </>
  )
}

export default Thumbnails
