import React from 'react';
import ReactPlayer from 'react-player';
import { useStoreActions } from 'easy-peasy'
import { Card, CardBody, CardText, CardTitle, Row, Button, Col } from 'reactstrap';
import { Colxx, Separator } from '../common/CustomBootstrap';


import logo from '../../assets/images/logo.jpg'

const FeaturedCard = (props) => {

    const { post } = props

    const { setNotifData } = useStoreActions(action => ({
        setNotifData: action.setNotifData
    }))

    const announce = () => {
        setNotifData(post)
        props.history.push("/app/announcements/post")
    }

    return (
        <div className="card">
            <Card>
                <CardBody>
                    <CardTitle>Today's Minute  </CardTitle>
                    <Row>
                        <Col>
                            <Button color="warning" onClick={announce}>Announce</Button>
                        </Col>

                    </Row>
                    <Separator />
                    <Row>
                        <Colxx xxs='12'>
                            <div className="post-user">
                                <img src={post.imageUrl || logo} alt="profile" className="avatar" width="70px" height="70px" />
                                <div className="post-info">
                                    <span>{post.title}</span>
                                </div>
                            </div>
                        </Colxx>
                    </Row>
                    <div className="post-wrapper">
                        {post.mimeType ?
                            <div >
                                <ReactPlayer
                                    width="100"
                                    height="100"
                                    url={post.mediaUrl || post.webUrl}
                                    controls
                                    playing={post.isPlaying}
                                />
                            </div> :
                            <></>
                        }

                        {
                            post.mimeType === 'image' ? <img width="100%" alt="thumbnail" src={post.thumbnailUrl}></img> : <></>
                        }

                    </div>
                    <CardText>
                        <small>
                            {post.body || ""}
                        </small>
                    </CardText>
                    {/* <CardText>
                        <small className="text-muted">Last updated 3 mins ago</small>
                    </CardText> */}
                </CardBody>
            </Card>
        </div>
    );
};

export default FeaturedCard;