import React from 'react';
import {
    Row, Button
} from 'reactstrap'
import ReactPlayer from 'react-player';
import { Separator } from '../common/CustomBootstrap';
import logo from '../../assets/images/logo.jpg'
import { confirmAlert } from 'react-confirm-alert';
import { useStoreActions } from 'easy-peasy'
import PostCounts from './PostCounts';

function FlaggedPostLayout(props) {
    const { post, pushHistory, type } = props
    const { deletePost } = useStoreActions(action => ({
        deletePost: action.deletePost
    }))

    const deletePostConfirm = () => {
        confirmAlert({
            title: 'Delete Post',
            message: 'Are you sure to delete this post?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deletePost({
                        post: post,
                        pushHistory: pushHistory
                    })

                },
                {
                    label: 'No'
                }
            ]
        });
    }

    return (
        <>
            <div className="card post-flagged">
                <Row>
                    <div className="post-user">
                        <img src={post.origAuthorImageUrl || post.imageUrl || logo} alt="profile" className="avatar" width="70px" height="70px" />

                        {/* {
                            isEdit  ?
                                <div className="post-info">
                                    <Form onSubmit={submitForm}>
                                        <Input type="text" name="title" placeholder="Enter Title" onChange={onChangeTitle} value={title} autoComplete="off" />
                                        <span className="span-grey text-small">Press enter to submit</span>  <span className="span-grey text-small" onClick={cancel}><b>Cancel</b></span>
                                    </Form>
                                </div>
                                : */}
                        <div className="post-info">
                            <span>{ post.origAuthorName || post.authorName}</span>
                            {/* {type === 'minute' || type === 'qna' ? <span className="span-grey text-small" onClick={edit}><b>Edit</b></span> : ""} */}

                        </div>
                        {/* } */}

                        {
                            type === 'post' ? "" : <Button color="danger" onClick={deletePostConfirm}> Delete </Button>
                        }

                    </div>

                </Row>
                <Row>
                    <div className="post-wrapper">
                        {/* {
                            post.sponsors.length > 0 ? <div>
                                <span className="span-grey text-small">Sponsors: {post.sponsors.length}</span>
                            </div> : ""
                        }

                        {
                            post.sponsors.map((sponsor, index) => {
                                return <ModalSponsorUpdate key={sponsor._id} sponsor={sponsor} index={index} removeSponsor={removeSponsor} updateSponsor={updateSponsor} />
                            })
                        } */}

                        {post.postType === 0 ? <span className="post-body">{post.body}</span> : <></>}

                        {post.mimeType.includes('video') ?
                            <div >
                                <ReactPlayer
                                    width="100"
                                    height="100"
                                    url={post.mediaUrl || post.webUrl}
                                    // url ="https://d30usy2s9n1syq.cloudfront.net/output/hls/test1234.m3u8"
                                    controls

                                />
                            </div> :
                            <></>
                        }

                        {
                            post.mimeType.includes('image') ? <img width="100%" alt="thumbnail" src={post.thumbnailUrl}></img> : <></>
                        }

                        <Separator />
                        {/* {
                            post.displayTag.length > 0 ? <div>
                                <span className="span-grey text-small">Tags: {post.displayTag.length}</span>
                            </div> : ""
                        } */}
                        {/* <ReactTags
                            tags={post.displayTag}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            delimiters={delimiters}
                            inline={false} />
                        <span className="span-grey text-small">Press enter to submit</span> */}

                        {/* <div>
                            {
                                post.tags.map((tag, index) => {
                                    return <span key={index} className="sponsor-tag"> <span> {tag} </span>   <span className="span-delete" onClick={() => handleDelete(index)}>  x  </span>  </span>
                                })
                            }
                        </div> */}
                        {/* <Separator />
                        <div>
                            <Form onSubmit={submitTag} >
                                <Input placeholder="Type new keyword..." />
                                <span className="span-grey text-small">Press enter to submit</span>

                            </Form>
                        </div> */}

                    </div>
                </Row>
                <Row>
                    <PostCounts post={post} />
                </Row>
                {/* <Row>
                    <PostActions post={post} type={type} toggle={toggle} />
                </Row> */}
                {/* <Separator />
                <Collapse isOpen={isOpen}>
                    <Comment post={post} profile={profile} isReply={false} />
                </Collapse> */}
            </div>
        </>
    );
}

export default FlaggedPostLayout;