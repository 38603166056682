import React, { useState } from 'react';
import { Button, Form, Input, Row } from "reactstrap";
import { confirmAlert } from 'react-confirm-alert';
import { Separator } from "../common/CustomBootstrap";
import PostCounts from "../common/PostCounts";
import axios from "axios";
import { baseUrl, imageAcceptFormat } from "../../constants/defaultValues";
import { createNotification } from "../../helpers/Notification";

const HashTagItem = (props) => {
  const hashtag = props.item

  const [isEdit, satIsEdit] = useState(false);
  const [title, setTitle] = useState(hashtag.title)

  const [photo, setPhoto] = useState(null)
  const [isUploadingPhoto, setUploading] = useState(false)

  const cancel = () => {
    satIsEdit(!isEdit)
    setTitle(hashtag.title)
  }

  const edit = () => satIsEdit(!isEdit)

  const onChangeTitle = (e) => setTitle(e.target.value)

  const submitForm = (e) => {
    e.preventDefault()
    let title = e.target[0].value

    confirmUpdate(title)
  }

  const updateTitle = (type) => {
    let res = {
      hashtagId: hashtag.id,
      type,
      data: {
        title: title,
      }
    }
    props.editHashTag(res)
    satIsEdit(false)
    setTitle(title)
  }

  const confirmUpdate = (title) => {
    confirmAlert({
      title: 'Update Title',
      message: 'Are you sure to change this post\'s title?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            updateTitle()
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const deleteTagConfirm = () => {
    confirmAlert({
      title: 'Delete Hashtag',
      message: 'Are you sure to delete this hashtag?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => props.deleteHashTag({
            hashtag: hashtag
          })

        },
        {
          label: 'No'
        }
      ]
    });
  }

  const featuredTag = (type) => {
    let res = {
      hashtagId: hashtag.id,
      type,
      data: {
        featured: !hashtag.featured,
      }
    }
    props.editHashTag(res)
  }

  const promotedTag = (type) => {
    let res = {
      hashtagId: hashtag.id,
      type,
      data: {
        promoted: !hashtag.promoted,
      }
    }
    props.editHashTag(res)
  }

  const editPhoto = (image) => {
    let res = {
      hashtagId: hashtag.id,
      type: 'image',
      data: {
        thumbnailUrl: image,
      }
    }
    props.editHashTag(res)
  }

  const onFileSelectHandler = (e) => {
    let files = e.target.files

    if (files && files.length !== 0) {
      let reader = new FileReader()
      reader.readAsDataURL(files[0])
      const formData = new FormData()
      reader.onload = (e) => {
        formData.append('photo', files[0])
        setPhoto(files[0])
      }
    }
  }

  const uploadPhoto = () => {
    if (!photo) {
      createNotification('waning', 'Please select image')
      return
    }
    setUploading(true)

    let formData = new FormData()
    formData.append('photo', photo)

    axios.post(`${baseUrl}/media/photos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('token')
      }
    }).then(res => {
      let photoUrl = res.data.d.photoUrl
      if (photoUrl) {
        editPhoto(photoUrl)
        resetFile()
      }
      setUploading(false)

    }).catch(err => {
      console.log(err)
      createNotification('waning', 'Failed to upload image')
      setUploading(false)
      setPhoto(null)
    })
  }

  const resetFile = () => {
    document.getElementById("file").value = "";
    setPhoto(null)
  }

  return (
    <>
      <div className="card card-hashtag">
        <p>ID: {hashtag?.id}</p>
        <Row>
          <div >
            <Button className={'button-tag'} color="danger" onClick={deleteTagConfirm}> Delete </Button>
            <Button className={'button-tag'} color={hashtag.promoted ? "primary" : "success"} onClick={() => promotedTag('promoted')}>{`Promote${hashtag.promoted ? 'd' : ''}`}</Button>
            <Button className={'button-tag'} color={hashtag.featured ? "secondary" : "warning"} onClick={() => featuredTag('featured')}>{`Feature${hashtag.featured ? 'd' : ''}`}</Button>
          </div>
        </Row>
        <br />
        {
          isEdit ?
            <div className="hashtag-info">
              <Form onSubmit={submitForm}>
                <Input type="text" name="title" placeholder="Enter Title" onChange={onChangeTitle} value={title} autoComplete="off" />
                <span className="span-grey text-small">Press enter to submit</span>  <span className="span-grey text-small" onClick={cancel}><b>Cancel</b></span>
              </Form>
            </div>
            :
            <div className="hashtag-info">
              <span className="span-author">{hashtag.title}</span>
              <span className="span-grey text-small" onClick={edit}><b>Edit</b></span>
            </div>
        }

        <img src={hashtag?.thumbnailUrl} alt="thumbnail" className='hashtag-image' />
        <div className={'hashtag-file'}>
          {isUploadingPhoto ? <span>Uploading...</span> : null}
          <Input id={'file'} type="file" name="file" onChange={onFileSelectHandler} accept={imageAcceptFormat} />

          {photo ?
            <>
              <Button className={'button-file'} color={"primary"} onClick={() => uploadPhoto()}>Save</Button>
              <Button className={'button-file'} color={"secondary"} onClick={() => {
                resetFile()
              }}>Cancel</Button>
            </>
            : null}
        </div>

        <Row>
          <div className="post-wrapper">
            <Separator />
          </div>
        </Row>
        <Row>
          <PostCounts hashtag={hashtag} isHashtag />
        </Row>
      </div>
    </>
  );
}


export default HashTagItem;
