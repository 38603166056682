import React, {useEffect, useState} from 'react'
import firebase from "firebase";
import { config } from '../../constants/config'


const Dot = () => {
  const [isNotRead, setNotRead] = useState(false)

  useEffect(() => {
    getMessages()
  }, [])

  const getMessages = () => {
    const db = firebase.firestore()
    db.collection(config.messageCollection)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          let el2 = change.doc.data()

          let el = el2?.latestMessage?.isNotReadForAdmin
          if (change.type === 'added') {
            if (el) {
              setNotRead(el)
            }
          } else {
            if (el && change.type === 'modified') {
              setNotRead(el)
            } else {
              setNotRead(false)
            }
          }
       })
    })
  }

  if (!isNotRead) return null

  return (
    <div className={'dot-msg'} />
  )
}

export default Dot