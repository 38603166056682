import React from 'react';

function PostCounts({post, isHashtag, hashtag}) {
    if (isHashtag) {
        return (
            <div className="post-counts">
                <span className="span-grey">{hashtag?.postsCount || 0} Posts</span>
                <span className="span-grey">{hashtag?.followersCount || 0} Followers</span>
                <span className="span-grey">{hashtag?.viewsCount || 0} Views</span>
            </div>
        );
    } else {
        return (
            <>
                <div className="post-counts">
                    <span className="span-grey">{post?.likeCount || 0} Likes</span>
                    <span className="span-grey">{post?.commentCount || 0} Comments</span>
                    <span className="span-grey">{post?.viewCount || 0} Views</span>
                </div>
                <span className="span-grey">{`${post.uniqueViewCount || 0} Impression${!post.uniqueViewCount || post.uniqueViewCount !== 1 ? 's' : ''}`}</span>
            </>
        );
    }
}

export default PostCounts;