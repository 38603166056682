import React, { useState } from 'react';
import logo from '../../assets/images/logo.jpg'
import { Button, Collapse, Row } from 'reactstrap';
import { useStoreActions } from 'easy-peasy'
import { confirmAlert } from 'react-confirm-alert';
import Comment from '../forms/Comment';

function CommentItem(props) {
    const { comment, post, profile, isReply } = props
    const [viewState, mouseEnter] = useState({
        hovered: false,
        isOpen: false
    });
    const hover = () => mouseEnter({
        hovered: true,
        isOpen: viewState.isOpen
    });

    const hoverOut = () => mouseEnter({
        hovered: false,
        isOpen: viewState.isOpen
    });

    const openReply = () => mouseEnter({
        hovered: viewState.hovered,
        isOpen: !viewState.isOpen
    })

    const { deleteComment, getUserFromUID } = useStoreActions(action => ({
        deleteComment: action.deleteComment,
        getUserFromUID: action.getUserFromUID
    }))

    const confirmDeleteComment = () => {
        confirmAlert({
            title: 'Delete Comment',
            message: 'Are you sure you want to delete this comment?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (isReply) {
                            deleteComment({
                                post: post,
                                comment: comment,
                                parentId : props.parentId
                            })
                        } else {
                            deleteComment({
                                post: post,
                                comment: comment
                            })
                        }

                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const disableFunc = () => {
        getUserFromUID(comment.authorUid)
        props.history.push(`/app/accounts/disable/${comment.authorUid}?isUid`)
    }

    const getReplyLabel = () => {
        if (comment.replies) {
            if (comment.replies.length > 0) {
                if (comment.replies.length === 1) {
                    return "Reply \u2022 " + comment.replies.length + " reply"
                } else {
                    return "Reply \u2022 " + comment.replies.length + " replies"
                }

            } else {
                return "Reply"
            }
        } else {
            return "Reply"
        }
    }

    return (
        <>
            <Row>
                <div className="comment-user" onMouseEnter={hover} onMouseLeave={hoverOut}>

                    <img src={logo} alt="profile" className="avatar" width={isReply ? "30px" : "40px"} height={isReply ? "30px" : "40px"} />
                    <div className="comment-info">
                        <span className="name">{comment.authorName}</span>
                        <span className="comment">{comment.text}</span>
                        {
                            !isReply ? <span className="span-grey text-small" onClick={openReply}><b>{!viewState.isOpen ? getReplyLabel() : "Hide"}</b></span> : ""
                        }

                    </div>

                    {
                        // && comment.authorUid === profile.uid
                        viewState.hovered ?
                            <div className='comment-btns'>
                                <Button className='comment-btn' onClick={confirmDeleteComment} color="link">Delete</Button>
                                <Button className='comment-btn' onClick={disableFunc} color="link">Disable user</Button>
                            </div>
                            : <></>
                    }

                </div>
            </Row>

            {
                !isReply ?
                    <Collapse isOpen={viewState.isOpen}>
                        <Comment history={props.history} post={post} profile={profile} isReply={true} comment={comment} />
                    </Collapse>
                    : ""
            }

        </>

    );
}

export default CommentItem;