import React from 'react'
import { Route, Redirect } from 'react-router-dom'


const AuthRoute = ({ component: Component, authUser, ...rest }) => (

    <Route
        {...rest}
        render={
            props =>
                authUser.token !== "" ?
                    (<Component {...props} />) :
                    (<Redirect
                        to={{
                            pathname: '/user',
                            state: { from: props.location },
                        }}
                    />
                    )
        }
    />
);

export default AuthRoute