import React, { useEffect, useState } from 'react'
import { ChatItem } from 'react-chat-elements'
import 'react-chat-elements/dist/main.css'
import firebase from 'firebase'
import profile from '../../../assets/images/profile-pic.png'
import { config } from "../../../constants/config"

const ChatMessages = (props) => {
  const [threads, setThreads] = useState([])

  useEffect(() => {
    firebaseGetData()
  }, [])

  const firebaseGetData = () => {
    const db = firebase.firestore();
    const unsubscribe = db.collection(config.messageCollection)
      .orderBy('latestMessage.createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        const threadsAll =
          querySnapshot &&
          querySnapshot.docs.map(documentSnapshot => {
            return {
              _id: documentSnapshot.id,
              name: '',
              latestMessage: { text: '' },
              ...documentSnapshot.data(),
            }
          })
        setThreads(threadsAll)
      })

    return () => unsubscribe()
  }

  const clickToItem = (id, name, userId) => {
    let name2 = name.split(' ').join('_').toLowerCase()
    props.history
      .push({
        pathname: `/app/chat/${name2}`,
        state: { name, id, userId }
      })
  }

  const renderItem = item => {
    const latestMsg = item && item.latestMessage
    const image = latestMsg.image
    const thumbnailUrl = latestMsg.thumbnailUrl
    const msg = latestMsg.text === '' && !latestMsg.image
      ? ' '
      : latestMsg.text.slice(0, 90)

    if (latestMsg.text === 'createNewChat') return

    return (
      <ChatItem
        avatar={latestMsg.imageUrl || profile}
        alt={'image'}
        title={item.name}
        onClick={(el) => clickToItem(item._id, item.name, item.userId)}
        subtitle={image ? 'Photo' : thumbnailUrl ? 'Video' : msg}
        statusText={'red'}
        lazyLoadingImage={image || thumbnailUrl}
        date={latestMsg?.createdAt}
        unread={latestMsg?.countIsNotRead} />
    )
  }

  return (
    <div>
      {threads.map(d => {
        return renderItem(d)
      })}
    </div>
  )
}

export default ChatMessages