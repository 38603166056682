import React from 'react';

function LandingRegistrationItem(props) {

    const { user, lastNonExist } = props
    var dateFormat = require('dateformat')

    return (
        <tr>
            <td>{user.fullName || ''}</td>
            <td>{user.email}</td>
            {/* {user.phone ? <td>{user.phone}</td> : null} */}
            <td>{dateFormat(user.dateCreated, "mmm dd, yyyy - h:MM tt")}</td>
            {!lastNonExist && <td>{user.description}</td>}
        </tr>
    );
}

export default LandingRegistrationItem;