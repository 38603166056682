import React from 'react';
import { Row, TabContent, TabPane, Nav, NavItem, NavLink, Col, Form, Label, Input, FormGroup } from 'reactstrap';
import ReactAudioPlayer from 'react-audio-player';

function PodcastAnnounce({ podcast }) {
    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Label>ID</Label>
                            <Input type="text" readOnly value={podcast._id} />
                        </FormGroup>

                        <FormGroup>
                            <Label>Title</Label>
                            <Input type="textarea" readOnly value={podcast.title} />
                        </FormGroup>

                        <FormGroup>
                            <Label>Duration</Label>
                            <Input type="text" readOnly value={podcast.duration} />
                        </FormGroup>

                    </Form>
                </Col>
                <Col>
                    <Form>
                        <FormGroup>
                            <Label>Artwork</Label>
                            <img alt="artwork" src={podcast.artwork} width="300px" />
                        </FormGroup>
                        <FormGroup>
                            <ReactAudioPlayer
                                src={podcast.mediaUrl}
                                controls
                            />
                        </FormGroup>

                    </Form>
                </Col>
            </Row>

        </>
    );
}

export default PodcastAnnounce;