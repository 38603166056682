import React, {useCallback, useEffect, useState} from 'react';
import 'react-chat-elements/dist/main.css'
import { Navbar, MessageBox } from 'react-chat-elements'
import { GiftedChat, Send, Bubble } from 'react-gifted-chat'
import firebase from 'firebase'
import Viewer from 'react-viewer';
import { useStoreActions, useStoreState } from "easy-peasy";
import axios from 'axios'

import gallery from '../../../assets/images/gallery.png'
import profile from '../../../assets/images/profile-pic.png'
import { Input, Spinner } from 'reactstrap'
import { baseUrl } from '../../../constants/defaultValues'

import back from '../../../assets/images/back.png'
import { config } from "../../../constants/config"

const Chat = (props) => {
  let chatId = props.location.state.id
  let name = props.location.state.name
  let userId = props.location.state.userId

  const { session } = useStoreState(state => ({
    session: state.session,
  }))

  let user = session?.profile

  const { uploadMedia } = useStoreActions(action => ({
    uploadMedia: action.uploadMedia,
  }))

  const [image, setImage] = useState(null)
  const [video, setVideo] = useState(null)
  const [thumbnailUrl, setThumbnailUrl] = useState(null)

  const [isUploading, setUploading] = useState(false)

  const [messages, setMessages] = useState([])
  const [customText, setCustomText] = useState('')

  const [isSend, setSend] = useState(false)

  const [visibleImage, setVisible] = useState(null)

  useEffect(() => {
      if (isSend) {
        onSend({ text: customText })
        setSend(false)
      }
  }, [isSend])

  useEffect(() => {
    getData()
    updateData()
  }, [])

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        setSend(true)
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  
  const updateData = async () => {
    const db = firebase.firestore();
    const docRef = db.collection(config.messageCollection).doc(chatId)

    await docRef.update(
      {
        'latestMessage.isNotReadForAdmin': false,
        'latestMessage.countIsNotRead': 0,
      });
  }

  const getData = () => {
    const db = firebase.firestore();

    db.collection(config.messageCollection)
      .doc(chatId)
      .collection('MESSAGES')
      .orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        const messages =
          querySnapshot &&
          querySnapshot.docs.map(doc => {
            const firebaseData = doc.data()

            const data = {
              _id: doc.id,
              text: '',
              ...firebaseData,
            }

            if (!firebaseData.system) {
              data.user = {
                ...firebaseData.user,
                name: firebaseData.user.displayName,
              }
            }

            return data
          })
        setMessages(messages)
      })
  }

  const goBack = () => {
    props.history.goBack()
  }

  const onSend = async (newMessage) => {
    const isAdmin = true

    const countM = !props?.countIsNotRead ? 1 : props?.countIsNotRead + 1
    const text = newMessage.text
    if ((text.trim() !== '' || video || image) && !isUploading) {
      setMessages(GiftedChat.append(messages, newMessage))
      const db = firebase.firestore();
      await db.collection(config.messageCollection)
        .doc(chatId)
        .collection('MESSAGES')
        .add({
          text,
          createdAt: new Date().getTime(),
          user: {
            _id: user.uid,
            displayName: user.fullName,
            avatar: user.imageUrl,
          },
          image: image,
          video: video,
        })
      await db.collection(config.messageCollection)
        .doc(chatId)
        .set(
          {
            latestMessage: {
              text,
              createdAt: new Date().getTime(),
              isNotReadForAdmin: !isAdmin,
              isNotReadForOther: isAdmin,
              countIsNotRead: isAdmin ? 0 : countM,
              image: image,
              video: video,
              thumbnailUrl: thumbnailUrl,
            },
          },
          {merge: true}
        )

      setCustomText('')
      setImage(null)
      setVideo(null)
      setThumbnailUrl(null)
      sendPush()
    }
  }

  const sendPush = token => {
    if (!userId) return
    const title = 'New Message'
    const message =
      'Open this notification to view the message from contact support'
    let notifPayload = {
      notification: {
        title: title,
        body: message,
      },
      ids: [userId],
      type: 'contactUs',
      data: {
        resourceType: 'contactUs',
        senderId: user?._id,
        senderName: user?.fullName,
        title: title,
        body: message,
        message: message,
      },
    }
    const body = JSON.stringify(notifPayload)
    const path = `${baseUrl}/notifications/single`

    axios.post(path, body, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem("token") || ''
      }
    }).then(res => {
        console.log(res)
    }).catch(err => {
        console.log(err)
    })
  }

  const onFileSelectHandler = async (e) => {
    let files = e.target.files
    let reader = new FileReader()
    reader.readAsDataURL(files[0])
    let payload = {
      file: files[0],
      isVideoCompressed: true
    }
    await setUploading(true)

    let media = await uploadMedia(payload)
    if (media) {
      if (payload && payload.file && payload.file.type && payload.file.type.includes('video')) {
        setVideo(media?.origMediaUrl)
        setThumbnailUrl(media?.thumbnailUrl)
      } else {
        setImage(media?.thumbnailUrl)
      }
      await setUploading(false)
    }
  }

  const renderMessageVideo = (el) => {
    const { currentMessage } = el

    return (
      <div className={'image-box'}>
        <video controls width={300} height={300}>
          <source src={currentMessage?.video} type='video/mp4'/>
          Your browser does not support HTML video.
        </video>
      </div>
    )
  }

  const renderBubble = (props) => {
    const { currentMessage } = props
    const type = !!currentMessage.image ? 'photo' : !!currentMessage.video ? 'video' : 'text'
    const url = type === 'photo' ? currentMessage.image : type === 'video' ? currentMessage.video : null

    return <Bubble
      {...props}
      renderMessageVideo={renderMessageVideo}
    />
    // return (
    //   <MessageBox
    //     position={props.position}
    //     type={type}
    //     text={currentMessage.text}
    //     date={currentMessage.createdAt}
    //     data={{
    //       uri: url,
    //       videoURL: url,
    //       status: {
    //         download: true,
    //       }
    //     }}/>
    // )
  }

  const renderAvatar = (el) => {
    const avatar = el?.currentMessage?.user?.avatar
    return <img src={avatar ? avatar : profile} className='avatar' width={45} height={45} />
  }

  const clickImage = useCallback((url) => {
    setVisible(url)
  }, [])

  const renderMessageImage = (el) => {
    const { currentMessage } = el

    return (
      <div onClick={() => clickImage(currentMessage?.image)} className={'image-box'}>
        <img className={'photo-msg'} src={currentMessage?.image} width={300} height={300} />
      </div>
    )
  }

  const renderInputToolbar = () => {
    return (
      <div className={'footer-row'}>
        <div className={'icon-block'}>
          {image || thumbnailUrl
            ? <img width={30} height={30} src={image || thumbnailUrl} />
            : isUploading ? <Spinner size={30} color='dark' /> : <>
              <label className={'upload-icon'} htmlFor='upload-photo'><img width={30} height={30} src={gallery}/></label>
              <Input id='upload-photo' type='file' name='file' onChange={onFileSelectHandler} accept='video/*, image/*' />
            </>
          }
        </div>
        <Input
          className={'input-msg'}
          type='text'
          value={customText}
          onChange={e => setCustomText(e.target.value)}
        />
        <Send
          alwaysShowSend
          text={customText}
          onSend={(messages) => onSend(messages)}
        />
      </div>
    )
  }

  return (
    <>
      <Navbar
        left={
          <div className={'btn-back'} onClick={goBack}><img width={25} height={25} src={back}/></div>
        }
        center={
          <div>{name}</div>
        }
      />
    <div className={'message-block'}>

      <GiftedChat
        messages={messages}
        user={{
          _id: user?.uid,
        }}
        // loadEarlier
        renderAvatar={renderAvatar}
        renderMessageImage={renderMessageImage}
        renderInputToolbar={renderInputToolbar}
        isCustomViewBottom
        scrollToBottom
        alwaysShowSend
        renderBubble={renderBubble}
      />
      <Viewer
        visible={!!visibleImage}
        minScale={0.5}
        drag={false}
        changeable={false}
        onClose={() => { setVisible(null); } }
        images={[{src: visibleImage, alt: ''}]}
      />
    </div>
    </>
  )
}

export default Chat