import React from 'react';
import Login from './Login';
import {
    Route,
    Switch
} from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy'

function MainUser(props) {

    const loaded = useStoreState(state => state.loaded)
    const login = useStoreActions(actions => actions.login)

    const userLogin = (payload, history) => {
        login({
            payload,
            history
        })
    }

    return (
        <div className="login-container">
            <Switch>
                <Route
                    path="/user"
                    render={props => <Login {...props} login={userLogin}  loaded={loaded} />} />
            </Switch>
        
        </div>
    );
}

export default MainUser;