import React from 'react';
import { Input, Form, FormGroup } from 'reactstrap'
import CommentItem from '../items/CommentItem';
import { useStoreActions } from 'easy-peasy'

function Comment(props) {

    const { post, profile, isReply, comment } = props

    const { addComment } = useStoreActions(action => ({
        addComment: action.addComment
    }))

    const onSubmit = (e) => {
        e.preventDefault()

        var payload = {

            post: post,
            request: {
                authorName: `${profile.firstName} ${profile.lastName}`,
                authorUid: `${profile.uid}`,
                imageUrl: `${profile.imageUrl}`,
                text: e.target[0].value
            },
            isReply: isReply,
            comment: comment,
            profile: profile

        }

        addComment(payload)
        e.target[0].value = ""

    }

    return (
        <div className={isReply ? "comment-reply" : ""}>
            {
                //Parent comments
                post.comments && !isReply ?
                    post.comments.map(comment => {
                        return <CommentItem {...props} comment={comment} key={comment._id} post={post} profile={profile} isReply={isReply} />
                    })
                    : <></>
            }

            {

                //show Replies
                comment && isReply ?
                    comment.replies ?
                        comment.replies.map(reply => {
                            return <CommentItem {...props} comment={reply} key={reply._id} post={post} profile={profile} isReply={isReply} parentId={comment._id} />
                        })
                        : ""
                    : ""
            }

            <Form onSubmit={onSubmit} className="comment-form">
                <FormGroup>
                    <Input type="text" name="comment" placeholder={isReply ? "Write a reply..." : "Write a comment..."} autoComplete="off" />
                    <span className="span-grey text-small">Press enter to submit</span>
                </FormGroup>
            </Form>

        </div>
    );
}

export default Comment;