import React, { Component } from 'react'
import HeaderNavigation from '../components/nav/HeaderNavigation'
import SidebarNavigation from '../components/nav/SidebarNavigation'

class AppLayout extends Component {

    UNSAFE_componentWillMount() {
        const token = localStorage.getItem("token")
        this.props.getProfile({
            token: token
        })

    }

    render() {
        return (
            <div className="layout-container">
                <HeaderNavigation props={this.props} />
                <div className='app-container'>
                    <SidebarNavigation />
                    {this.props.children}
                </div>

            </div>
        )
    }

}

export default AppLayout