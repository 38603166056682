import { useStoreActions } from "easy-peasy";
import React, { useEffect } from "react";

const WindowFocusHandler = () => {
    const { checkIsExpireToken } = useStoreActions(action => ({
        checkIsExpireToken: action.checkIsExpireToken,
    }))

    // User has switched back to the tab
    const onFocus = () => {
        checkIsExpireToken()
    };

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        // Calls onFocus when the window first loads
        onFocus();
        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener("focus", onFocus);
        };
  }, []);

    return <></>;
};

export default WindowFocusHandler;