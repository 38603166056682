import React, { Component } from 'react'
import { Nav, NavItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'

import Dot from '../items/Dot'

class SidebarNavigation extends Component {
    render() {
        return (
            <div vertical className="sidebar">
                <div vertical className="sidebar-inner">
                    <NavItem>
                        <NavLink
                            exact
                            to="/app"
                            activeClassName="active">
                            Dashboard
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/announcements/create">
                            Announcements
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/landing/registrations">
                            Landing Registrations
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/modals">
                            Modal windows
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/messages">
                            Messages
                        </NavLink>
                        <Dot />
                    </NavItem>

                <NavItem>
                    <NavLink
                    to="/app/thumbnails"
                    activeClassName="active">
                    Thumbnails
                    </NavLink>
                </NavItem>
                    <NavItem>
                        <NavLink
                            to="/app/accounts"
                            activeClassName="active">
                            Accounts
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            to="/app/podcasts"
                            activeClassName="active">
                            Podcasts
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/posts">
                            Posts
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/hashtags">
                            Hashtags
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/donations">
                            Donations
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/recommend/users">
                            Recommend Users
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/recommend/tags">
                            Search Recommendations
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/flagged/posts">
                            Flagged Posts
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/flagged/users">
                            Flagged Users
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            activeClassName="active"
                            to="/app/flagged/comments">
                            Flagged Comments
                        </NavLink>
                    </NavItem>
                </div>
            </div>
        )
    }
}

export default SidebarNavigation
