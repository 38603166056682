import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Receiver from '../../../components/forms/Receiver';
import FlaggedPostLayout from '../../../components/common/FlaggedPostLayout';
import { createNotification } from '../../../helpers/Notification';
import PodcastAnnounce from '../../../components/common/PodcastAnnounce';

class AnnouncementCreate extends Component {
    constructor(props) {
        super(props)

        const { _id, firstName, lastName, imageUrl } = this.props.profile

        this.state = {
            senderId: _id,
            senderName: `${firstName} ${lastName}`,
            imageUrl: `${imageUrl}`,
            ids: [],
            type: '1', //1 - all user, 2- specific,

        }

        this.props.fetchUsers()

    }

    setNotifType = (type) => {
        this.setState({
            type: type
        })
    }

    setSelectedIds = (ids) => {
        this.setState({
            ids: ids
        })
    }

    sendNotif = (e) => {
        e.preventDefault()

        if (this.props.notifTimer.lastNotifTime) {
            let currentTime = new Date()

            var difference = (currentTime - this.props.notifTimer.lastNotifTime) / 1000;

            if (difference < 60) {
                createNotification("warning", "You can only send 1 notification per minute.")
                return
            }

        }

        const { notifData } = this.props
        const { senderId, senderName, ids, title, message, type } = this.state

        if (!title || !message) {
            createNotification("warning", "Title and message is required")
            return
        }

        if (type === '2') {
            if (ids.length < 1) {
                createNotification("warning", "Select users to notify")
                return
            }
        }

        let data = {}
        if (this.props.match.params.type === 'post' || this.props.match.params.type === 'podcast') {
            const { type } = this.props.match.params
            data = {
                imageUrl: notifData.imageUrl || '',
                resourceType: type,
                resourceId: notifData._id,
                senderId: senderId,
                senderName: senderName,
                title: title,
                body: message,
                message: message
            }
        } else {
            data = {
                imageUrl: notifData.imageUrl || '',
                senderId: senderId,
                senderName: senderName,
                title: title,
                body: message,
                message: message
            }
        }
        if (this.props.match.params.type === 'post') {
            data.postId = notifData._id
        } else if (this.props.match.params.type === 'podcast') {
            data.podcastId = notifData._id
        }
        let image =  notifData.artwork || notifData.thumbnailUrl

        let notifPayload = {
            notification: {
                title: title,
                body: message,
                image: image,
            },
            ids: ids,
            type: type,
            data: data
        }

        this.props.sendTargettedNotif(notifPayload)
    }

    valueChangeHandler = (e) => {
        const { name, value } = e.target

        this.setState({
            [name]: value
        })

    }



    render() {
        const { type } = this.props.match.params
        const { notifData } = this.props

        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Announcements</BreadcrumbItem>
                            <BreadcrumbItem active >Create</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <FormGroup>
                                <Label>Notification Title</Label>
                                <Input type="text" name="title" onChange={this.valueChangeHandler} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Notification Message</Label>
                                <Input type="textarea" rows="5" name="message" onChange={this.valueChangeHandler} />
                            </FormGroup>
                            <Receiver users={this.props.users} setNotifType={this.setNotifType} setSelectedIds={this.setSelectedIds} searchList={this.props.searchList} />
                            <Separator />
                            <Button onClick={this.sendNotif} color="primary">Send</Button>
                        </Form>
                    </Col>
                    {
                        type === 'post' && notifData.mimeType ?
                            <Col>
                                <FlaggedPostLayout post={this.props.notifData} pushHistory={null} type={type} />
                            </Col> : ""
                    }
                    {
                        type === 'podcast' ?
                            <Col>
                                <PodcastAnnounce podcast={notifData} />
                            </Col>
                            :
                            ""
                    }

                </Row>
            </>
        );
    }
}

export default AnnouncementCreate;