import { NotificationManager } from 'react-notifications';

const createNotification = (type, message, title) => {

  switch (type) {
    case 'info':
      NotificationManager.info(message);
      break;
    case 'success':
      NotificationManager.success(message, 'Success!', 10000);
      break;
    case 'warning':
      NotificationManager.warning(message, "Notice", 5000);
      break;
    case 'error':
      NotificationManager.error(message, "Error", 10000);
      break;
    case 'custom':
        NotificationManager.warning(message, title, 5000);
      break;
    default:
      break;
  }
}

export { createNotification }