import React from 'react';
import { Button } from 'reactstrap';
import { useStoreActions } from 'easy-peasy'

function FlaggedPostItem(props) {


    const { setSelectedFlag } = useStoreActions(action => ({
        setSelectedFlag: action.setSelectedFlag
    }))


    const { post } = props
    var dateFormat = require('dateformat')

    const viewFullPost = () => {
        setSelectedFlag(post)
        props.history.push(`/app/flagged/posts/${post._id}`)
    }

    return (
        <tr>
            {/* <td><Button color="danger">Unresolved</Button></td> */}
            <td>{post.authorName}</td>
            <td>{post.flagCount}</td>
            <td>{dateFormat(post.dateCreated, "mmm dd, yyyy - h:MM tt")}</td>
            <td>{post._id}</td>
            <td>
                {/* <ModalContactUser buttonType="link" />/ */}
                <Button color="link" onClick={viewFullPost}>View Post</Button></td>
        </tr>
    );
}

export default FlaggedPostItem;