import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Button, Spinner } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import FlaggedUserItem from '../../../components/items/FlaggedUserItem';
import axios from 'axios'
import { CSVLink } from "react-csv";
import { baseUrl } from '../../../constants/defaultValues'

class FlaggedUsers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            csvData: [],
            isExporting: false,
            isLoaded: true
        }
    }

    componentDidMount() {
        this.props.getFlaggedUsers()
    }

    getFlaggedForExport = async (newUrl) => {

        this.setState({
            isLoaded: false,
            isExporting: true
        })

        const url = newUrl === '' ? `${baseUrl}/users/flagged` : newUrl
        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const { next, d } = res.data

            this.addToCsvData(d)

            if (next) {
                this.getFlaggedForExport(next)
            } else {
                this.setState({
                    isLoaded: true
                })
            }

        }).catch(err => {
            console.log(err)
            this.setState({
                isLoaded: true,
                isExporting: false
            })
        })
    }

    addToCsvData = async (newData) => {
        const { csvData } = this.state

        let csvToUpdate = [...csvData, ...newData]
        this.setState({
            csvData: csvToUpdate
        })
    }

    render() {
        let { flaggedUsers } = this.props

        const { csvData, isLoaded, isExporting } = this.state
        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Users</BreadcrumbItem>
                            <BreadcrumbItem active >Flagged</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>
                {!isExporting ?
                    <Button color="success" onClick={() => { this.getFlaggedForExport('') }}>Export</Button>
                    :
                    !isLoaded ?
                        <><Spinner size="sm" color="primary" /> Constructing data </>
                        :
                        <CSVLink
                            filename={"meaningful-minute-flagged-users.csv"}
                            className='csv-link'
                            onClick={() => {
                                this.setState({
                                    isExporting: false,
                                    isLoaded: true,
                                    csvData: []
                                })
                            }}
                            data={csvData}>DOWNLOAD CSV</CSVLink>
                }
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Account</th>
                            <th>Flag count</th>
                            <th>Date of User</th>
                            <th>User ID</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {
                        flaggedUsers ?
                            <tbody>
                                {
                                    flaggedUsers.map(user => (
                                        <FlaggedUserItem key={user._id} {...this.props} user={user} />
                                    ))
                                }
                            </tbody> : ""
                    }

                </table>
            </>
        );
    }
}

export default FlaggedUsers;