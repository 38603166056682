import React from 'react';
import {
    Row, Button
} from 'reactstrap'
import logo from '../../assets/images/logo.jpg'
import { confirmAlert } from 'react-confirm-alert';
import { useStoreActions } from 'easy-peasy'

function FlaggedPostLayout(props) {
    const { user, pushHistory, type } = props
    const { deletePost } = useStoreActions(action => ({
        deletePost: action.deletePost
    }))

    const deletePostConfirm = () => {
        confirmAlert({
            title: 'Delete User',
            message: 'Are you sure to delete this user?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deletePost({
                        user: user,
                        pushHistory: pushHistory
                    })

                },
                {
                    label: 'No'
                }
            ]
        });
    }

    return (
        <>
            <div className="card post-flagged">
                <Row>
                    <div className="post-user">
                        <img src={user?.origAuthorImageUrl || user?.imageUrl || logo} alt="profile" className="avatar" width="70px" height="70px" />
                        {
                            type === 'user' ? "" : <Button color="danger" onClick={deletePostConfirm}> Delete </Button>
                        }
                    </div>
                </Row>
            </div>
        </>
    );
}

export default FlaggedPostLayout;