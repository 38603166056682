import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row } from 'reactstrap';
import FeaturedCard from '../../../components/card/FeaturedCard';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';

//Dashboard
class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.props.featured()

    }

    render() {
        return (
            <div>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Home</BreadcrumbItem>
                            <BreadcrumbItem active >Overview</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>
                <Row>
                    <Colxx sm='12' md='6'  >
                        <FeaturedCard {...this.props} post={this.props.featuredPost} />
                    </Colxx>
                </Row>
            </div>
        )

    }
}

export default Dashboard