import React, { useState } from 'react';
import { Input, FormGroup, Label, Nav, NavLink, NavItem, TabContent, TabPane, Row, Col } from 'reactstrap';
import { Separator } from '../common/CustomBootstrap';
import SearchFriend from './SearchFriend';

function Receiver(props) {

    const { users, setNotifType, setSelectedIds, searchList } = props

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        setNotifType(tab)
        if (activeTab !== tab) setActiveTab(tab);
    }
    
    return (
        <FormGroup>

            <Label>Send to: </Label>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={activeTab === '1'}
                        onClick={() => { toggle('1'); }}>
                        All Users
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={activeTab === '2'}
                        onClick={() => { toggle('2'); }} >
                        Selected Users
                    </NavLink>
                </NavItem>
            </Nav>
            <Separator />
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col>
                            <Input type="text" readOnly value="This will be sent to all registered users" />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col>
                            <SearchFriend users={users} setSelectedIds={setSelectedIds} searchList={searchList} />
                        </Col>
                    </Row>

                </TabPane>
            </TabContent>
        </FormGroup>
    );
}

export default Receiver;