import React from 'react'
import spin from '../../assets/images/Spinner.svg'

const Spinner = () => {
  return (
    <div style={{
      background: "rgba(0,0,0,0.40)",
      color: '#4e6afc',
      position: "absolute",
      width: '100%',
      height: '100vh',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99999
    }}>
      <div style={{ display: "flex", justifyContent: "center", position: "absolute", maxHeight: 70, maxWidth: 70 }}>
        <img src={spin} alt=""/>
      </div>
    </div>
  )
}

export default Spinner;
