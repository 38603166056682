import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import {createNotification} from '../../helpers/Notification';
import ImagesSlider from '../common/ImagesSlider';
import {Button} from 'reactstrap'

export default class MultipleImageUploadComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      files: [],
      isPlaying: false
    }
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
  }

  uploadMultipleFiles(e) {
    if (e.target.files?.length + this.state.files?.length > 10) {
      createNotification('warning', `Limit is 10 items!`)
      return
    }
    let arr = []
    let fileType
    for (let i = 0; i < e.target.files?.length; i++) {
      let item = e.target.files[i]
      if (!fileType && fileType !== item.type) {
        fileType = item.type
      }
      console.log('fileType', fileType)
      if (fileType !== item.type && fileType) {
        createNotification('warning', `You can't upload photo with video!`)
        document.getElementById("file-input").value = "";
        this.setState({files: []})
        return
      }
      if (fileType.includes('video') && e.target.files?.length + this.state.files?.length > 1) {
        createNotification('warning', `You can't upload more than 1 video!`)
        document.getElementById("file-input").value = "";
        this.setState({files: []})
        return
      }
      item.uri = URL.createObjectURL(item)
      if (fileType.includes('video')) {
        var blob = new Blob([item], {
          type: fileType
        }), // create a blob of buffer
        url = (URL).createObjectURL(blob), // create o-URL of blob
        video = document.createElement("video"); // create video element
  
        video.preload = "metadata"; // preload setting
  
        video.addEventListener("loadedmetadata", function() { // when enough data loads
          (URL).revokeObjectURL(url); // clean up
        })
        video.src = url; // start video load
  
      setTimeout(() => {
        if (video.videoHeight && video.videoWidth) {
          this.props.updateVideoInfo({ width: video.videoWidth, height: video.videoHeight })
        }
      }, 500)  
    }


      arr.push(item)
    }
    this.setState({
      files: [...this.state.files, ...arr],
    }, () => {
      this.props.onSelected(arr)
    })
  }

  removeEl = (index) => {
    let arr = this.state.files.slice()
    arr.splice(index, 1)
    this.setState({files: arr}, () => {
      if (this.state.files?.length === 0) {
        this.props.clear()
      }
    })
  }

  render() {
    let renderImages
    if (this.state.files?.length > 1) {
      renderImages = <ImagesSlider images={this.state.files}/>
    } else if (this.state.files?.length <= 1 && this.state.files?.length !== 0) {
      renderImages = <img width="100%" alt="thumbnail" src={this.state.files[0]?.uri}/>
    }
    const isVideo = this.state.files[0]?.type.includes('video')
    return (
      <form>
        {this.state.files?.length !== 0 ?
          <div className="form-group multi-preview">
            {
              isVideo ?
                <div>
                  <ReactPlayer
                    width="350px"
                    height="400px"
                    url={this.state.files[0]?.uri}
                    controls
                    config={{
                      file: {
                        hlsOptions: {
                          maxMaxBufferLength: 1
                        }
                      }
                    }}
                    playing={this.state.isPlaying}
                    onPlay={() => {
                      this.setState({isPlaying: !this.state.isPlaying})
                    }}
                  />
                </div> : ""
            }
            {!isVideo && renderImages}
          </div>
          : null
        }
        {isVideo ? '' : <p>{this.state.files?.length}/10 photos</p>}

        {this.state.files?.length ?
          this.state.files.map((el, i) => {
            return (
              <div key={i} className='row-img'>
                <p className='file-name'>{i + 1}. {el.name}</p>
                <Button className='rm-btn' onClick={() => this.removeEl(i)}>X</Button>
              </div>
            )
          }) : null}

        <div className="form-group">
          <input id="file-input" type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple
                 accept={this.props.accept}/>
        </div>
      </form>
    )
  }
}