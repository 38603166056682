import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Col,
    Spinner
} from 'reactstrap';
import axios from 'axios'
import {CSVLink} from "react-csv";
import {Colxx, Separator} from '../../../components/common/CustomBootstrap';
import PostMedia from '../../../components/items/PostMedia';
import Pagination from '../../../components/common/Pagination';
import SearchBarSubmit from '../../../components/common/SearchBarSubmit';
import {baseUrl} from '../../../constants/defaultValues'
import { useStoreActions } from 'easy-peasy';
import Api from '../../../helpers/Api';

const ApiReq = new Api()

//Videos Management
class Posts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: '1',
            isSearching: false,
            csvDataMinutes: [],
            csvDataPosts: [],
            csvDataQna: [],
            csvDataShared: [],
            isExportingMinutes: false,
            isLoadedMinutes: true,
            isExportingPosts: false,
            isLoadedPosts: true,
            isExportingQna: false,
            isLoadedQna: true,
            isExportingShared: false,
            isLoadedShared: true,

            donations: {},
            page: 1,
        }
    }

    componentDidMount() {
        ApiReq.getDonations({ page: this.state.page, status: 'paid', callback: (res) => this.setState({ donations: res })  })
        window.scrollTo(0, 0)
    }

    changePage = (e) => {
        let page = e.selected < 1 ? 1 : e.selected + 1

        this.setState({ page }, () => {
            ApiReq.getDonations({ page, status: 'paid', callback: (res) => this.setState({ donations: res })  })
        })
    }

    createPost = () => {
        let typeUrl = 'minute'
        switch (this.state.activeTab) {
            case '1':
                typeUrl = 'minute';
                break;
            case '2':
                typeUrl = 'post';
                break;
            default:
                typeUrl = 'qna';
                break;
        }
        this.props.history.push(`/app/posts/create/${typeUrl}`)
    }

    selectTab = (tab) => {
        this.removeSearching()
        if (this.state.activeTab !== tab) {
            // this.props.fetchPosts({
            //     type: tab,
            //     page: 1
            // })

            this.setState({
                activeTab: tab
            })
        }
    }

    focusHandler = () => {
        this.setState({
            isSearching: true
        })
    }

    removeSearching = () => {
        this.setState({
            isSearching: false
        }, () => {
            window.scrollTo(0, 0)
        })

        this.props.clearPostSearchResults()
    }

    getPostsForExport = async (newUrl, tab) => {
        let postUrl = ''

        switch (tab) {
            case '1': {
                postUrl = `${baseUrl}/posts/minutes?lim=100&p=1`
                this.setState({
                    isLoadedMinutes: false,
                    isExportingMinutes: true
                })
            }
                ;
                break;
            case '3': {
                postUrl = `${baseUrl}/posts/qna?lim=100&p=1`
                this.setState({
                    isLoadedQna: false,
                    isExportingQna: true
                })
            }
                break
            // default: url = `${baseUrl}/index/search?q=*&o=posts&lim=200&p=${page}`; break;
            case '4': {
                postUrl = `${baseUrl}/sharedPosts?lim=100&p=1`
                this.setState({
                    isLoadedShared: false,
                    isExportingShared: true
                })
            }
                break;
            default: {
                this.setState({
                    isLoadedPosts: false,
                    isExportingPosts: true
                })
                postUrl = `${baseUrl}/posts?lim=100&p=1`
            }
                break;
        }

        const url = newUrl === '' ? postUrl : newUrl
        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const {next, d} = res.data

            this.addToCsvData(d, tab)

            if (next) {
                this.getPostsForExport(next, tab)
            } else {
                switch (tab) {
                    case '1': {
                        this.setState({
                            isLoadedMinutes: true,
                        })
                    }
                        ;
                        break;
                    case '3': {
                        this.setState({
                            isLoadedQna: true
                        })
                    }
                        break
                    case '4': {
                        this.setState({
                            isLoadedShared: true
                        })
                    }
                        break;
                    default: {
                        this.setState({
                            isLoadedPosts: true
                        })
                    }
                        break;
                }
            }

        }).catch(err => {
            console.log(err)
            this.setState({
                isLoaded: true,
                isExporting: false
            })
        })
    }

    addToCsvData = async (newData, tab) => {
        const {csvDataMinutes, csvDataPosts, csvDataQna, csvDataShared} = this.state

        // const newCsvData = []
        // await newData.forEach(user => {
        //     let object = {
        //         _id: user._id,
        //         uId: user.uid,
        //         email: user.email,
        //         firstName: user.firstName,
        //         lastName: user.lastName,
        //         imageUrl: user.imageUrl,
        //         phone: user.phone,
        //         admin: user.claims.admin,
        //         speaker: user.claims.speaker,
        //         dateCreated: user.dateCreated,
        //         dateUpdated: user.dateUpdated
        //     }
        //     newCsvData.push(object)
        // });

        // console.log("NEW CSV DATA: " + newCsvData.length)


        let csvToUpdate = []

        switch (tab) {
            case '1': {
                csvToUpdate = [...csvDataMinutes, ...newData]
                this.setState({
                    csvDataMinutes: csvToUpdate
                })
            }
                break;
            case '2': {
                csvToUpdate = [...csvDataPosts, ...newData]
                this.setState({
                    csvDataPosts: csvToUpdate
                })
            }
                break;
            case '3': {
                csvToUpdate = [...csvDataQna, ...newData]
                this.setState({
                    csvDataQna: csvToUpdate
                })
            }
                break;
            case '4': {
                csvToUpdate = [...csvDataShared, ...newData]
                this.setState({
                    csvDataShared: csvToUpdate
                })
            }
                break;
        }

    }

    render() {
        let {
            activeTab,
            csvDataMinutes,
            csvDataPosts,
            csvDataQna,
            csvDataShared,
            isExportingMinutes,
            isLoadedMinutes,
            isExportingPosts,
            isLoadedPosts,
            isExportingQna,
            isLoadedQna,
            isExportingShared,
            isLoadedShared
        } = this.state
        let {posts, minutes, qna, shared, searchMinutes, searchPosts, searchQna, searchShared} = this.props.posts

        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Posts</BreadcrumbItem>
                            <BreadcrumbItem active>Videos</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator/>
                    </Colxx>
                </Row>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={activeTab === '1'}
                            onClick={() => {
                                this.selectTab('1');
                            }}>
                            Minutes
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={activeTab === '2'}
                            onClick={() => {
                                this.selectTab('2');
                            }}>
                            Posts
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={activeTab === '3'}
                            onClick={() => {
                                this.selectTab('3');
                            }}>
                            Q &amp; A
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={activeTab === '4'}
                            onClick={() => {
                                this.selectTab('4');
                            }}>
                            Shared Content
                        </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={activeTab}>
                    {activeTab === '1' && <TabPane tabId="1">
                        <Separator/>
                        <Row>
                            <Colxx xs='3'>
                                <SearchBarSubmit focusHandler={this.focusHandler} removeSearching={this.removeSearching}
                                                 type={1}/>
                            </Colxx>
                            {this.state.isSearching ?
                                <Colxx lg='6' xxs='3'>
                                    <Button color="warning" onClick={this.removeSearching}>Reset</Button>
                                </Colxx>
                                :
                                <>
                                    <Colxx lg='6' xxs='3'>
                                        <Button color="primary" onClick={this.createPost}>Create Minute</Button> <></>
                                        {!isExportingMinutes ?
                                            <Button color="success" onClick={() => {
                                                this.getPostsForExport('', '1')
                                            }}>Export</Button>
                                            :
                                            !isLoadedMinutes ?
                                                <><Spinner size="sm" color="primary"/> Constructing data </>
                                                :
                                                <CSVLink
                                                    filename={"meaningful-minute-minutes.csv"}
                                                    className='csv-link'
                                                    onClick={() => {
                                                        this.setState({
                                                            isExportingMinutes: false,
                                                            isLoadedMinutes: true,
                                                            csvDataMinutes: []
                                                        })
                                                    }}
                                                    data={csvDataMinutes}>DOWNLOAD CSV</CSVLink>
                                        }
                                    </Colxx>

                                </>
                            }

                        </Row>
                        {this.state.isSearching ?
                            <Row>
                                <Col sm="12">
                                    {searchMinutes.map(post => {
                                        return <PostMedia post={post} donations={this.state.donations} changePage={this.changePage} key={post._id}
                                                          type={'minute'} profile={this.props.profile}
                                                          history={this.props.history}/>
                                    })}
                                </Col>
                            </Row> :
                            <>
                                <Row>
                                    <Col sm="12">
                                        {minutes.map(post => {
                                            return <PostMedia
                                                post={post}
                                                donations={this.state.donations} changePage={this.changePage}
                                                key={post._id} type={'minute'}
                                                profile={this.props.profile}
                                                history={this.props.history}/>
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Pagination type={"1"}/>
                                    </Col>
                                </Row>
                            </>
                        }
                    </TabPane>}
                    {activeTab === '2' && <TabPane tabId="2">
                        <Separator/>
                        <Row>
                            <Colxx xs='3'>
                                <SearchBarSubmit type={0} focusHandler={this.focusHandler}
                                                 removeSearching={this.removeSearching}/>
                            </Colxx>
                            {this.state.isSearching ?
                                <Colxx lg='6' xxs='3'>
                                    <Button color="warning" onClick={this.removeSearching}>Reset</Button>
                                </Colxx>
                                :
                                <Colxx lg='6' xxs='3'>
                                    <Button color="primary" onClick={this.createPost}>Create Post</Button> <></>
                                    {!isExportingPosts ?
                                        <Button color="success" onClick={() => {
                                            this.getPostsForExport('', '2')
                                        }}>Export</Button>
                                        :
                                        !isLoadedPosts ?
                                            <><Spinner size="sm" color="primary"/> Constructing data </>
                                            :
                                            <CSVLink
                                                filename={"meaningful-minute-posts.csv"}
                                                className='csv-link'
                                                onClick={() => {
                                                    this.setState({
                                                        isExportingPosts: false,
                                                        isLoadedPosts: true,
                                                        csvDataPosts: []
                                                    })
                                                }}
                                                data={csvDataPosts}>DOWNLOAD CSV</CSVLink>
                                    }
                                </Colxx>
                            }
                        </Row>

                        {this.state.isSearching ?
                            <Row>
                                <Col sm="12">
                                    {searchPosts.map(post => {
                                        return <PostMedia post={post} donations={this.state.donations} changePage={this.changePage} key={post._id}
                                                          type={'post'} profile={this.props.profile}
                                                          history={this.props.history}/>
                                    })}
                                </Col>
                            </Row>
                            : <>
                                <Row>
                                    <Col sm="12">
                                        {posts.map(post => {
                                            return <PostMedia post={post} donations={this.state.donations} changePage={this.changePage}
                                                              key={post._id} type={'post'} profile={this.props.profile}
                                                              history={this.props.history}/>
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Pagination type={"2"}/>
                                    </Col>
                                </Row></>
                        }


                    </TabPane>}
                    {activeTab === '3' && <TabPane tabId="3">
                        <Separator/>
                        <Row>
                            <Colxx xs='3'>
                                <SearchBarSubmit type={2} focusHandler={this.focusHandler}
                                                 removeSearching={this.removeSearching}/>
                            </Colxx>
                            {this.state.isSearching ?
                                <Colxx lg='6' xxs='3'>
                                    <Button color="warning" onClick={this.removeSearching}>Reset</Button>
                                </Colxx>
                                :
                                <Colxx lg='6' xxs='3'>
                                    <Button color="primary" onClick={this.createPost}>Create Q &amp; A</Button> <></>
                                    {!isExportingQna ?
                                        <Button color="success" onClick={() => {
                                            this.getPostsForExport('', '3')
                                        }}>Export</Button>
                                        :
                                        !isLoadedQna ?
                                            <><Spinner size="sm" color="primary"/> Constructing data </>
                                            :
                                            <CSVLink
                                                filename={"meaningful-minute-qna.csv"}
                                                className='csv-link'
                                                onClick={() => {
                                                    this.setState({
                                                        isExportingQna: false,
                                                        isLoadedQna: true,
                                                        csvDataQna: []
                                                    })
                                                }}
                                                data={csvDataQna}>DOWNLOAD CSV</CSVLink>
                                    }
                                </Colxx>
                            }

                        </Row>

                        {this.state.isSearching ?
                            <Row>
                                <Col sm="12">
                                    {searchQna.map(post => {
                                        return <PostMedia post={post} donations={this.state.donations} changePage={this.changePage} key={post._id}
                                                          type={'qna'} profile={this.props.profile}
                                                          history={this.props.history}/>
                                    })}
                                </Col>
                            </Row>
                            :
                            <>
                                <Row>
                                    <Col sm="12">
                                        {qna.map(post => {
                                            return <PostMedia post={post} donations={this.state.donations} changePage={this.changePage}
                                                              key={post._id} type={'qna'} profile={this.props.profile}
                                                              history={this.props.history}/>
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Pagination type={"3"}/>
                                    </Col>
                                </Row>
                            </>}

                    </TabPane>}
                    {activeTab === '4' && <TabPane tabId="4">
                        <Separator/>
                        <Row>
                            <Colxx xs='3'>
                                <SearchBarSubmit type={3} focusHandler={this.focusHandler}
                                                 removeSearching={this.removeSearching}/>
                            </Colxx>
                            {this.state.isSearching ?
                                <Colxx lg='6' xxs='3'>
                                    <Button color="warning" onClick={this.removeSearching}>Reset</Button>
                                </Colxx>
                                :

                                <Colxx lg='6' xxs='3'>
                                    {!isExportingShared ?
                                        <Button color="success" onClick={() => {
                                            this.getPostsForExport('', '4')
                                        }}>Export</Button>
                                        :
                                        !isLoadedShared ?
                                            <><Spinner size="sm" color="primary"/> Constructing data </>
                                            :
                                            <CSVLink
                                                filename={"meaningful-minute-shared.csv"}
                                                className='csv-link'
                                                onClick={() => {
                                                    this.setState({
                                                        isExportingShared: false,
                                                        isLoadedShared: true,
                                                        csvDataShared: []
                                                    })
                                                }}
                                                data={csvDataShared}>DOWNLOAD CSV</CSVLink>
                                    }
                                </Colxx>
                            }
                        </Row>

                        {this.state.isSearching ?
                            <Row>
                                <Col sm="12">
                                    {searchShared.map(post => {
                                        return <PostMedia post={post} donations={this.state.donations} changePage={this.changePage} key={post._id}
                                                          type={'shared'} profile={this.props.profile}
                                                          history={this.props.history}/>
                                    })}
                                </Col>
                            </Row>
                            :
                            <>
                                <Row>
                                    {shared ?
                                        <Col sm="12">
                                            {shared.map(post => {
                                                return <PostMedia
                                                    post={post} donations={this.state.donations} changePage={this.changePage} key={post._id}
                                                    type={'shared'} profile={this.props.profile}
                                                    history={this.props.history}/>
                                            })}
                                        </Col>
                                        : null
                                    }

                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Pagination type={"4"}/>
                                    </Col>
                                </Row>
                            </>}

                    </TabPane>}
                </TabContent>
            </>
        )
    }
}

const PostsContainer = (props) => {
    const { checkIsExpireToken, checkError } = useStoreActions(action => ({
      checkIsExpireToken: action.checkIsExpireToken,
      checkError: action.checkError,
    }))
  
    ApiReq.checkIsExpireToken = checkIsExpireToken
    ApiReq.checkError = checkError
    
    return <Posts {...props} />
  
  }
  
  export default PostsContainer;