import React from 'react'
import logo from '../../assets/images/logo.jpg'
import profile from '../../assets/images/profile-pic.png'
import {
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu
} from "reactstrap";
import { useStoreState, useStoreActions } from 'easy-peasy'

const HeaderNavigation = (props) => {

    const session = useStoreState(state => state.session)

    const user = session.profile

    const logout = useStoreActions(actions => actions.logout)

    const getLevel = (claims) => {
        try {
            let { speaker, admin } = claims

            if (admin) return "Admin"
            else if (speaker) return "Speaker"
            else return "User"

        } catch (e) {
            return "User"
        }
    }

    return (
        <header className="header">
            <div className="header-search">
                <img src={logo} alt="logo" className="logo" width="5%" />
                {/* <Form>
                    <Input name="search" placeholder="Search for something..." />
                </Form> */}
            </div>

            <nav>
                <div className="user">
                    <UncontrolledDropdown className="dropdown-menu-right">
                        <DropdownToggle className="p-0" color="empty">
                            <span className="name mr-1">{user.firstName} {user.lastName}</span>
                            <span className="name mr-1">({getLevel(user.claims)})</span>
                            <span>
                                <img alt="Profile" src={profile} className="avatar" width="50px" />
                            </span>
                        </DropdownToggle>
                        <DropdownMenu className="mt-3" right>
                            {/* <DropdownItem>Account</DropdownItem> */}
                            <DropdownItem divider />
                            <DropdownItem onClick={() => logout(props)}>
                                Sign out
                         </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </nav>
        </header>
    )
}

export default HeaderNavigation