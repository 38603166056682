import React, { Component } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment';
import axios from 'axios'
import DatePicker from "react-datepicker";
// import imageCompression from 'browser-image-compression';
import { Breadcrumb, BreadcrumbItem, Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { baseUrl, imageAcceptFormat } from '../../../constants/defaultValues';
import { createNotification } from '../../../helpers/Notification';
import {compressImg} from "../../../helpers/compressImg";
import CropImage from '../../../components/common/CropImage';
import { dataURLtoFile, getAudioDuraion, getDuration } from '../../../helpers/utils';
import Spinner from "../../../components/common/Spinner"

let forEdit = false
// let dateReleased = ''
class PodcastCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            _id: '',
            isEdit: false,
            dateReleased: '',
            originalDateReleased: '',
            duration: '',
            mediaUrl: '',
            artwork: '',
            originalArtwork: '',
            title: '',
            artist: '',
            hours: '',
            minutes: '',
            seconds: '',
            podcastsCollection: '',
            description: '',
            file: null,
            artworkFile: null,
            isUploading: false,
            isUploadingArtwork: false,
            isSubmitting: false,
        }

    }

    componentDidMount() {
        if (this.props.match.params.id) {
            forEdit = true
            this.handleEditData()

        } else {
            forEdit = false
        }
    }

    startSubmitting = () => {
        this.setState({ isSubmitting: true })
    }
    endSubmitting = () => {
        this.setState({ isSubmitting: false })
    }

    handleEditData = () => {
        const { selectedPodcast } = this.props

        if (selectedPodcast) {
            // this.setState({
            //     isEdit: true
            // })
            const { _id, title, artist, mediaUrl, artwork, originalArtwork, duration, dateReleased, podcastsCollection, description } = selectedPodcast
            let hours, minutes, seconds = ''

            try {
                let durationArr = duration.split(':')
                hours = durationArr[0]
                minutes = durationArr[1]
                seconds = durationArr[2]
            } catch (e) {
                console.log(e)
            }

            this.setState({
                artist,
                _id: _id,
                title: title,
                description,
                mediaUrl: mediaUrl,
                artwork: artwork,
                originalArtwork: originalArtwork,
                hours: hours,
                minutes: minutes,
                seconds: seconds,
                podcastsCollection: podcastsCollection,
                originalDateReleased: dateReleased
                // dateReleased: dateReleased
            })

            // dateReleased = '25-07-2020'

        }
    }

    handleChange = date => {
        this.setState({
            dateReleased: date
        });
    };

    valueChangeHandler = (e) => {
        const { name, value } = e.target

        this.setState({
            [name]: value
        })

    }

    onAudioSelectHandler = async (e) => {
        try {
            let files = e.target.files

            let reader = new FileReader()
            reader.readAsDataURL(files[0])
            const audioDuration = await getAudioDuraion(files[0])
            const duration = getDuration(audioDuration)

            let hours, minutes, seconds = ''
            let durationArr = duration.split(':')
            hours = durationArr[0]
            minutes = durationArr[1]
            seconds = durationArr[2]

            this.setState({
                file: files[0],
                hours,
                minutes,
                seconds,
            }, () => {
                this.uploadAudio()
            })

        } catch (e) {
            console.log(e)
        }
    }


    onFileSelectHandler = async (e) => {
        let files = e.target.files

        try {
            const compressedImg = await compressImg(files[0])

            const formData = new FormData()

            let reader = new FileReader()
            reader.readAsDataURL(compressedImg)

            reader.onload = (e) => {

                // this.props.upload(formData)
                // console.log("file data", reader.result)

                formData.append('photo', compressedImg)
                this.setState({
                    logo: reader.result,
                    artworkFile: compressedImg
                }, () => this.uploadPhoto())
            }
        } catch (error) {
            console.log(error);
            createNotification('waning', 'Failed to upload image')
        }
    }

    uploadAudio = () => {
        const { file } = this.state

        this.setState({
            isUploading: true
        })

        let formData = new FormData()
        formData.append('podcast', file)

        axios.post(`${baseUrl}/media/podcasts`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        }).then(res => {
            const { mediaUrl } = res.data.d
            this.setState({
                isUploading: false,
                mediaUrl: mediaUrl
            })
        }).catch(err => {
            console.log(err)
            createNotification('waning', 'Failed to upload audio')
            this.setState({
                isUploading: false,
                mediaUrl: '',
                file: null
            })
            createNotification('error', 'Failed to upload file')
        })

    }

    uploadPhoto = (base64) => {
        // const { artworkFile } = this.stat
        var artworkFile = dataURLtoFile(base64, 'image.png');

        this.setState({
            isUploadingArtwork: true
        })

        let formData = new FormData()
        formData.append('photo', artworkFile)

        axios.post(`${baseUrl}/media/photos`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        }).then(res => {

            let artworkUrl = res.data.d.thumbnailUrl
            let originalArtwork = res.data.d.photoUrl
            this.setState({
                isUploadingArtwork: false,
                artwork: artworkUrl,
                originalArtwork
            })

        }).catch(err => {
            console.log(err)
            createNotification('waning', 'Failed to upload image')
            this.setState({
                isUploadingArtwork: false,
                artwork: '',
                artworkFile: null
            })
        })
    }

    createPodcast = () => {


        const { title, artist, dateReleased, mediaUrl, artwork, originalArtwork, hours, minutes, seconds, originalDateReleased, podcastsCollection, description } = this.state

        if (!mediaUrl || mediaUrl === '') {
            createNotification('warning', 'Upload an Audio File')
            return
        }

        if (!artwork || artwork === '') {
            createNotification('warning', 'Upload an Artwork')
        }

        if (title === '') {
            createNotification('warning', 'Input all details')
            return
        }

        if (artist === '') {
            createNotification('warning', 'Input all details')
            return
        }

        if (hours === '' || minutes === '' || seconds === '') {
            createNotification('warning', 'Invalid duration format. ex: 01:35:27')
            return
        }

        let duration = `${hours}:${minutes}:${seconds}`
        if (duration.length < 8) {
            createNotification('warning', 'Invalid duration format. ex: 01:35:27')
            return
        }

        if (!forEdit && (dateReleased === null || dateReleased === '')) {
            createNotification('warning', 'Input date released')
            return
        }

        let payload = {
            artist,
            title,
            description,
            duration,
            mediaUrl,
            podcastsCollection,
            artwork,
            originalArtwork,
            dateReleased: dateReleased ? dateReleased : originalDateReleased
        }

        this.postPodcast(payload)

    }

    onSelectCollection = (el) => {
        const i = el.target.value
        let index = parseInt(i)
        let correctIndex = typeof index === 'number' && index >= 0 ? index - 1 : 0
        const item = this.props.podcastCollections[correctIndex]
        this.setState({
            podcastsCollection: item?._id || null
        })
    }

    postPodcast = (payload) => {
        this.startSubmitting()
        const url = forEdit ? `${baseUrl}/podcasts/${this.state._id}` : `${baseUrl}/podcasts`

        axios.post(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            this.endSubmitting()
            createNotification('success', !forEdit ? "Episode Added!" : "Episode Updated")
            this.props.history.goBack()

        }).catch(err => {
            console.log(err)
            this.endSubmitting()
            createNotification('error', 'Failed to add podcast')
        })
    }

    render() {

        const { isUploading, artist, isUploadingArtwork, mediaUrl, title, description, isSubmitting } = this.state

        return (
            <div style={{ height: '800px' }}>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Episode</BreadcrumbItem>
                            <BreadcrumbItem active >{forEdit ? `Edit` : `Create`}</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Podcast artwork</Label>
                            <CropImage url={this.state.artwork} uploadPhoto={this.uploadPhoto} accept={imageAcceptFormat} />
                            {/* <Input type="file" name="file" onChange={this.onFileSelectHandler} accept={imageAcceptFormat} /> */}
                            {isUploadingArtwork ? <span>Uploading...</span> : null}
                            <br></br>
                            {
                                this.state.artwork || this.state.logo
                                    ?
                                    <>
                                        <img alt="Profile" src={this.state.artwork || this.state.logo} width="300px" />
                                    </>
                                    :
                                    null
                            }
                        </FormGroup>

                        <Separator />

                        <FormGroup>
                            <Label>Podcast Audio File</Label>
                            <br />
                            <Input type="file" name="file" onChange={this.onAudioSelectHandler} accept="audio/mp3, audio/mp4, audio/wav" ref={ref => this.podcastFile = ref} />
                            {/* <input type="file" className="file" onChange={this.onFileSelectHandler} accept={'audio/*'} /> */}
                            <br />
                            {isUploading ?
                                <span>Uploading...</span>
                                :
                                mediaUrl && mediaUrl !== ''
                                    ?
                                    <ReactAudioPlayer
                                        src={mediaUrl}
                                        controls
                                    />
                                    :
                                    null
                            }

                        </FormGroup>

                    </Col>

                    <Col>
                        <Form>

                            <FormGroup>
                                <Label>Date Released</Label>
                                {/* <Input type="textarea" rows="5" name="message" onChange={this.valueChangeHandler} /> */}
                                <br />
                                <DatePicker
                                    placeholderText={forEdit ? moment.utc(this.state.originalDateReleased).format('MM/DD/YYYY') : ''}
                                    selected={this.state.dateReleased}
                                    onChange={this.handleChange}
                                    isClearable={forEdit}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>Artist</Label>
                                <Input type="text" name="artist" value={artist} onChange={this.valueChangeHandler} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Title</Label>
                                <Input type="text" name="title" value={title} onChange={this.valueChangeHandler} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Duration ( hh : mm : ss)</Label><br></br>
                                {/* <Input type="text" name="duration" onChange={this.valueChangeHandler} /> */}
                                {/* <NumberFormat format="##:##:##" allowEmptyFormatting mask="_" onChange={this.valueChangeHandler} name="duration" value={this.state.duration} /><br></br> */}

                                <Row>
                                    <Col>
                                        <Input disabled type="text" name="hours" className="input-podcast-duration" value={this.state.hours} maxLength={2} onChange={this.valueChangeHandler} />
                                    </Col>
                                    :
                                    <Col>
                                        <Input disabled type="text" name="minutes" className="input-podcast-duration" value={this.state.minutes} maxLength={2} onChange={this.valueChangeHandler} />
                                    </Col>
                                    :
                                    <Col>
                                        <Input disabled type="text" name="seconds" className="input-podcast-duration" value={this.state.seconds} maxLength={2} onChange={this.valueChangeHandler} />
                                    </Col>

                                </Row>

                            </FormGroup>

                            <Separator />

                            <FormGroup>
                                <Label for="body">Podcast Collection: </Label>
                                <Input type="select" name="otherId" id="exampleSelect" onChange={this.onSelectCollection}>
                                    {['', ...this.props.podcastCollections].map((item, index) => {
                                        return <option
                                            selected={this.state.podcastsCollection === item?._id}
                                            key={item?._id}
                                            value={index}>{item?.title}</option>
                                    })}
                                </Input>
                            </FormGroup>

                            <Separator />
                            <FormGroup>
                                <Label>Description</Label>
                                <Input type="textarea" rows="4" name="description" value={description} onChange={this.valueChangeHandler} />
                            </FormGroup>

                            <Button onClick={this.createPodcast} color="primary">{forEdit ? `Save` : `Send`}</Button>
                        </Form>
                    </Col>

                </Row>
                {isSubmitting && <Spinner />}

            </div>
        );
    }
}

export default PodcastCreate;