import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Button, NavItem, NavLink, Nav } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import axios from 'axios'
import { baseUrl } from '../../../constants/defaultValues';
import PodcastCollection from "./PodcastCollection";

class Podcasts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            podcasts: [],
        }
    }

    getPodcasts = () => {
        this.props.checkIsExpireToken()
        axios.get(`${baseUrl}/podcasts`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            if (res.data.d) {
                this.setState({
                    podcasts: res.data.d
                })
            }


        }).catch(err => {

        })
    }

    createPodcast = () => {
        this.props.history.push('/app/podcasts/create')
    }

    render() {
        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>All</BreadcrumbItem>
                            <BreadcrumbItem active >{'Podcasts'}</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>
                <PodcastCollection {...this.props} />
            </>
        );
    }
}

export default Podcasts;