import React, { Component } from 'react';
import {Breadcrumb, BreadcrumbItem, Row, Button, Spinner, NavItem, NavLink, Nav} from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import LandingRegistrationItem from '../../../components/items/LandingRegistrationItem';
import axios from 'axios'
import { CSVLink } from "react-csv";
import { baseUrl } from '../../../constants/defaultValues'

class LandingRegistrations extends Component {
    constructor(props) {
        super(props)

        this.state = {
            types: ['', 'tefilah', 'with_hope'],
            csvData: [],
            data: [],
            dataTab1: [],
            dataTab2: [],
            dataTab3: [],
            isExporting: false,
            isLoaded: true,
            activeTab: 1
        }
    }

    componentDidMount(props) {
        this.getData('we_lost')
    }

    getData = (type) => {
        const params = type ? `?type=${type}` : ''
        const url = `${baseUrl}/landing/registrations${params}`
        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            const { next, d } = res.data

            if (d) {
                let arr = this.sortAndFormatedArr(d)
                this.setState({ data: arr, [`dataTab${this.state.activeTab}`]: arr })
            }

        }).catch(err => {
            console.log(err)
        })
    }

    sortAndFormatedArr = (arr) => {
        arr.sort(function(a,b){
            return new Date(b.dateCreated) - new Date(a.dateCreated);
          });
          let unique = arr.reduce(
            (accumulator, current) => {
              if(!accumulator.some(x => x.email === current.email)) {
                accumulator.push(current)
              }
              return accumulator;
            }, []
          )
          return unique
      }

    getFlaggedForExport = async () => {
        const type = this.state.types[this.state.activeTab - 1]
        console.log(4444, type)
        const params = type ? `?type=${type}` : ''

        this.setState({
            isLoaded: false,
            isExporting: true
        })

        const url =`${baseUrl}/landing/registrations${params}`
        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            const { next, d } = res.data
            let arr = this.sortAndFormatedArr(d)
            this.addToCsvData(arr)

            if (next) {
                this.getFlaggedForExport(next)
            } else {
                this.setState({
                    isLoaded: true
                })
            }

        }).catch(err => {
            console.log(err)
            this.setState({
                isLoaded: true,
                isExporting: false
            })
        })
    }

    addToCsvData = async (newData) => {
        const { csvData } = this.state
        let csvToUpdate = [...csvData, ...newData]
        this.setState({
            csvData: csvToUpdate
        })
    }

    selectTab = (tab) => {
        if (this.state.activeTab !== tab) {
            if (tab === 2 && this.state.dataTab2.length === 0) {
                this.getData('tefilah')
            }

            if (tab === 3 && this.state.dataTab3.length === 0) {
                this.getData('with_hope')
            }
            this.setState({
                activeTab: tab,
                data: this.state[`dataTab${tab}`]
            })
        }
    }

    render() {
        const { flaggedComments } = this.props
        const { csvData, isLoaded, isExporting, data, activeTab } = this.state
        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Landing</BreadcrumbItem>
                            <BreadcrumbItem active >Registrations</BreadcrumbItem>
                        </Breadcrumb>
                    </Colxx>
                </Row>
                <Nav className={'landing-tabs'} tabs>
                    <NavItem>
                        <NavLink
                          active={activeTab === 1}
                          onClick={() => { this.selectTab(1); }}>
                            What we lost
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                          active={activeTab === 2}
                          onClick={() => { this.selectTab(2); }} >
                            Tefilah Before Tech
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                          active={activeTab === 3}
                          onClick={() => { this.selectTab(3); }} >
                            Mourning with hope
                        </NavLink>
                    </NavItem>
                </Nav>

                <>
                    <p>Users list count: {data.length}</p>
                    {!isExporting ?
                        <Button color="success" onClick={() => { this.getFlaggedForExport('') }}>Export</Button>
                        :
                        !isLoaded ?
                            <><Spinner size="sm" color="primary" /> Constructing data </>
                            :
                            <CSVLink
                                filename={"meaningful-minute-landing-registrations.csv"}
                                className='csv-link'
                                onClick={() => {
                                    this.setState({
                                        isExporting: false,
                                        isLoaded: true,
                                        csvData: []
                                    })
                                }}
                                data={csvData}>DOWNLOAD CSV</CSVLink>
                    }
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Date Created</th>
                                {this.state.activeTab !== 3 &&  <th>{this.state.activeTab === 1 ? 'How did you hear about this film?' : `I am committing to Tefilah Before Tech L'zechus:`}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {data ? data.map((user, index) => {
                                return <LandingRegistrationItem user={user} key={index} lastNonExist={this.state.activeTab === 3} />
                            }) : <tr></tr>
                            }
                        </tbody>
                    </table>
                </>
            </>
        );
    }
}

export default LandingRegistrations;