import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Button } from 'reactstrap';

function PostActions({ post, type, toggle }) {

    const { profile } = useStoreState(state => ({
        profile: state.session.profile
    }))

    const { likePost, setFeatured, getComments } = useStoreActions(action => ({
        likePost: action.likePost,
        setFeatured: action.setFeatured,
        getComments: action.getComments
    }))

    const toggleComments = () => {
        getComments(post)
        toggle()
    }

    return (
        <div className="post-actions">

            {
                profile.likedPosts ?
                    <Button outline={!profile.likedPosts.includes(post._id)} color="primary" onClick={() => likePost({
                        id: post._id,
                        postType: post.postType,
                        isLike: !profile.likedPosts.includes(post._id),
                        profile: profile,
                        author: post.authorUid
                    })}>{profile.likedPosts.includes(post._id) ? 'Liked' : 'Like'}</Button>
                    : ""
            }

            {
                type === 'minute' ? <Button
                    outline={!post.isFeatured}
                    color="warning"
                    onClick={() => setFeatured(post)} >{post.isFeatured ? 'Featured' : 'Feature'}</Button>
                    : <></>
            }
            <Button color="link"
                onClick={toggleComments} >Comments</Button>

        </div>
    );

    // if (post.mimeType === "video") {
    //     return (
    //         <div className="post-actions">
    //             <Button outline={!post.isLiked} color="primary" onClick={() => likePost({
    //                 id: post._id,
    //                 isLike: !post.isLiked
    //             })}>{post.isLiked ? 'Liked' : 'Like'}</Button>
    //             <Button outline={!post.isFeatured} color="warning" onClick={() => setFeatured(post)} >{post.isFeatured ? 'Featured' : 'Feature'}</Button>
    //         </div>
    //     );
    // } else {
    //     return (
    //         <div className="post-actions">
    //             <Button outline={!post.isLiked} color="primary" onClick={() => likePost({
    //                 id: post._id,
    //                 isLike: !post.isLiked
    //             })}>{post.isLiked ? 'Liked' : 'Like'}</Button>
    //         </div>
    //     );
    // }

}

export default PostActions;