import React from 'react';
import { Button } from 'reactstrap';
import { useStoreActions } from 'easy-peasy'

function FlaggedUserItem(props) {


    const { setSelectedFlaggedUser } = useStoreActions(action => ({
      setSelectedFlaggedUser: action.setSelectedFlaggedUser
    }))


    const { user } = props
    let dateFormat = require('dateformat')
    const viewFullUser = () => {
        setSelectedFlaggedUser(user)
        props.history.push(`/app/flagged/users/${user._id}`)
    }

    return (
        <tr>
            <td>{user.fullName}</td>
            <td>{user.numberOfComplaints}</td>
            <td>{dateFormat(user.dateCreated, "mmm dd, yyyy - h:MM tt")}</td>
            <td>{user._id}</td>
            <td>
                <Button color="link" onClick={viewFullUser}>View User</Button></td>
        </tr>
    );
}

export default FlaggedUserItem;