
//Write regex here

const validEmailRegex =
    // eslint-disable-next-line
    RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    function findHashtags(searchText) {
        let regexp = /#(\w+)/g
        let result = searchText.match(regexp)
        if (result) {
          return result
        } else {
          return false
        }
      }

    const reqExUserName = /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/

    const validateUserName = username => {
      let re = reqExUserName
      return re.test(String(username).toLowerCase())
    }

    export{
        validEmailRegex,
        validateUserName,
        findHashtags,
    }