import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    Label
} from 'reactstrap';

class ModalSponsorUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sponsor: {},
            index: 0,
            modal: false,
            error: false,
            originalSponsor: {}
        }

    }

    componentDidMount() {
        const { sponsor, index } = this.props
        this.setState({
            sponsor: sponsor,
            index: index,
            originalSponsor: sponsor
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    cancel = () => {
        this.setState({
            sponsor: this.state.originalSponsor
        })

        this.toggle()
    }

    valueChangeHandler = (e) => {
        const { name, value } = e.target

        let error = value ? false : true
        this.setState({
            error: error
        })

        let newSponsor = this.state.sponsor
        newSponsor[name] = value
        this.setState({
            sponsor: newSponsor
        })

    }

    render() {
        const { modal, error } = this.state
        const { removeSponsor, updateSponsor, sponsor, index } = this.props

        return (
            <div>
                <span className="sponsor-tag"> <span onClick={this.toggle} >{sponsor.dedication || sponsor.sponsorFor || "Anonymous"} </span>   <span className="span-delete" onClick={() => removeSponsor(index, true)}>  x  </span>  </span>
                <Modal isOpen={modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.cancel}>Update Sponsor</ModalHeader>
                    <ModalBody>
                        {error ? <span className="error">All fields are required.</span> : ""}
                        <Form>
                            <FormGroup>
                                <Input type="select" name="sponsorFor" onChange={this.valueChangeHandler} id="exampleSelect" defaultValue={sponsor.sponsorFor}>
                                    <option
                                        key='R'
                                        value='R'>Refua Shaleima</option>
                                    <option
                                        key='S'
                                        value='S'>Shidduchim</option>
                                    <option
                                        key='P'
                                        value='P'>Parnassa</option>
                                    <option
                                        key='L'
                                        value='L'>L'iluy Nishmas</option>
                                    <option
                                        key='H'
                                        value='H'>Hatzlacha</option>
                                    <option
                                        key='O'
                                        value='O'>Other</option>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="dedication">Dedication</Label>
                                <Input type="text" name="dedication" onChange={this.valueChangeHandler} placeholder="" defaultValue={sponsor.dedication} />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            updateSponsor({
                                sponsor: this.state.sponsor,
                                index: index,
                                isDelete: false
                            })

                            this.toggle()
                        }}>Update</Button>{' '}
                        <Button color="secondary" onClick={this.cancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalSponsorUpdate;