import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Input, Label, Spinner, Button, Col } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import DonationItem from '../../../components/items/DonationItem';
import axios from 'axios'
import { CSVLink } from "react-csv";
import { baseUrl } from '../../../constants/defaultValues'
import Pagination from '../../../components/common/Pagination';
import Api from '../../../helpers/Api';
import { useStoreActions } from 'easy-peasy';

const ApiReq = new Api()

class Donations extends Component {

    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            donateRes: null,
            list: [],
            isExporting: false,
            isLoaded: true,
            csvData: []
        }

    }

    componentDidMount() {
        ApiReq.getDonations({ page: this.state.page, callback: (res) => this.setState({ donateRes: res, list: res.d })  })
    }

    filterData = (e) => {
        let value = e.target.value

        let listToFilter = this.state.donateRes?.d

        switch (value) {
            case "all":
                break;
            case "success":
                listToFilter = listToFilter.filter(donation => donation.status !== "pending")
                break;
            case "pending":

                listToFilter = listToFilter.filter(donation => donation.status !== "paid")
                break;
            default: break;
        }

        this.setState({
            list: listToFilter
        })

    }

    changePage = (e) => {
        let page = e.selected < 1 ? 1 : e.selected + 1

        this.setState({ page }, () => {
            ApiReq.getDonations({ page, callback: (res) => this.setState({ donateRes: res, list: res.d })  })
        })
    }

    getDontaionsForExport = async (newUrl) => {

        this.setState({
            isLoaded: false,
            isExporting: true
        })

        const url = newUrl === '' ? `${baseUrl}/donations` : newUrl
        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const { next, d } = res.data

            this.addToCsvData(d)

            if (next) {
                this.getDontaionsForExport(next)
            } else {
                this.setState({
                    isLoaded: true
                })
            }

        }).catch(err => {
            console.log(err)
            this.setState({
                isLoaded: true,
                isExporting: false
            })
        })
    }

    addToCsvData = async (newData) => {
        const { csvData } = this.state

        const newCsvData = []
        await newData.forEach(donation => {
            let object = {
                _id: donation._id,
                status: donation.status,
                amount: donation.amount,
                sponsorFor: donation.sponsorFor,
                firstName: donation.firstName,
                lastName: donation.lastName,
                hebrewName: donation.hebrewName,
                email: donation.emailAddress,
                phoneNumber: donation.phoneNumber,
                companyName: donation.companyName,
                fathersHebrewName: donation.fathersHebrewName,
                mothersHebrewName: donation.mothersHebrewName,
                comment: donation.comment,
                address: donation.address,
                city: donation.city,
                zip: donation.zip,
                country: donation.country,
                dateCreated: donation.dateCreated,
                dateUpdated: donation.dateUpdated,

            }
            newCsvData.push(object)
        });

        let csvToUpdate = [...csvData, ...newCsvData]
        this.setState({
            csvData: csvToUpdate
        })
    }

    render() {
        const { csvData, isLoaded, isExporting } = this.state
        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Donations</BreadcrumbItem>
                            <BreadcrumbItem active >List</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12'>
                        <Label for="filter">Filter by Status:</Label>
                        <Input type="select" name="filter" id="filter" onChange={this.filterData} defaultValue="all">
                            <option value="all">All</option>
                            <option value="success">Success</option>
                            <option value="pending">Failed</option>
                        </Input>
                    </Colxx>

                </Row>
                <br/>
                <Row>
                    <Colxx lg='6' xxs='3'>
                        {!isExporting ?
                            <Button color="success" onClick={() => { this.getDontaionsForExport('') }}>Export</Button>
                            :
                            !isLoaded ?
                                <><Spinner size="sm" color="primary" /> Constructing data </>
                                :
                                <CSVLink
                                    filename={"meaningful-minute-donations.csv"}
                                    className='csv-link'
                                    onClick={() => {
                                        this.setState({
                                            isExporting: false,
                                            isLoaded: true,
                                            csvData: []
                                        })
                                    }}
                                    data={csvData}>DOWNLOAD CSV</CSVLink>
                        }
                    </Colxx>
                </Row>
                <Separator />
                <table className='table table-horizontal'>
                    <thead>
                        <tr>
                            {/* <th>id</th> */}
                            <th>Status</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Sponsor</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Phone Number</th>
                            <th>City</th>
                            <th>Zip</th>
                            <th>Country</th>
                            <th>State</th>
                            <th>Comment</th>
                            <th>Hebrew Name</th>
                            <th>Father's Hebrew Name</th>
                            <th>Mother's Hebrew Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Add key to each iteration */}
                        {this.state.list?.map(donation => (
                            <DonationItem donation={donation} key={donation._id} />
                        ))}
                    </tbody>
                </table>
                <Row>
                    <Col sm="12">
                        <Pagination page={this.state.page -1} maxPage={this.state.donateRes?.np} changePage={this.changePage} />
                    </Col>
                </Row>
            </>
        );
    }
}

const DonationContainer = (props) => {
  const { checkIsExpireToken, checkError } = useStoreActions(action => ({
    checkIsExpireToken: action.checkIsExpireToken,
    checkError: action.checkError,
  }))

  ApiReq.checkIsExpireToken = checkIsExpireToken
  ApiReq.checkError = checkError
  
  return <Donations {...props} />

}

export default DonationContainer;