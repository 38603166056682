import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import logo from '../../assets/images/logo.jpg'
import { validateUserName, validEmailRegex } from '../../helpers/Regex'
import NumberFormat from 'react-number-format';
import { imageAcceptFormat } from '../../constants/defaultValues';

let forEdit = false

class Register extends Component {
    constructor(props) {
        super(props)

        this.state = {
            imageUrl: "",
            email: "",
            firstName: "",
            lastName: "",
            userName: "",
            phone: "",
            password: "",
            confirmPassword: "",
            tncVersion: "",
            userLevel: 3,
            about: "",
            errors: {
                email: "",
                firstName: "",
                lastName: "",
                userName: "",
                contact: "",
                password: ""
            }
        }

        if (this.props.match.params.id) {
            forEdit = true

        } else {
            forEdit = false
        }
    }


    UNSAFE_componentWillMount() {
        let { selectedUser } = this.props

        // this.setState({
        //     firstName: selectedUser.firstName,
        //     lastName: selectedUser.lastName,
        //     imageUrl: selectedUser.imageUrl,
        //     phone: selectedUser.phone,
        //     email: selectedUser.email
        // })

        this.setState(selectedUser)
    }

    valueChangeHandler = (e) => {
        const { name, value } = e.target
        let errors = this.state.errors

        switch (name) {
            case 'firstName':
                errors.firstName =
                    value.length < 1
                        ? 'First name cannot be empty'
                        : '';
                break;
            case 'lastName':
                errors.lastName =
                    value.length < 1
                        ? 'Last name cannot be empty'
                        : '';
                break;
            case 'userName':
                const firsSymbol = value[0]
                const lastSymbol = value[value.length - 1]
                errors.userName =
                    value.length < 4
                        ? 'Username must be at least 4 characters'
                        : (firsSymbol === '_' || lastSymbol === '_' || firsSymbol === '.' || lastSymbol === '.')
                            ? 'You only can use symbols inside the Username'
                            : validateUserName(value)
                                ? ''
                                : 'Spaces not allowed in Username' 
                break;
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid!';
                break;
            case 'password':
                errors.password =
                    value.length < 6
                        ? 'Password must be 6 characters long!'
                        : '';
                break;
            case 'phone':
                errors.contact =
                    value.charAt(value.length - 1) === '_' ? 'Enter a valid contact number' : ''
                break;
            default:
                break;
        }


        this.setState({ errors, [name]: value })

    }

    register = (e) => {
        e.preventDefault()

        if (this.validateForm(this.state.errors)) {
            if (forEdit) {
                this.props.editUser(this.state)
            } else {
                this.props.register(this.state)
            }
        } else {
            console.error('Invalid Form')
        }

    }

    validateForm = (errors) => {
        let valid = true;

        if ((this.state.password !== ""
            && this.state.confirmPassword === this.state.password) || forEdit) {

            Object.values(errors).forEach(
                // if we have an error string set valid to false
                (val) => val.length > 0 && (valid = false)
            );
            return valid;

        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    password: "Passwords do not match"
                }
            })

            return false
        }

    }

    onFileSelectHandler = (e) => {
        let files = e.target.files

        let reader = new FileReader()
        reader.readAsDataURL(files[0])


        const formData = new FormData()

        reader.onload = (e) => {

            // this.props.upload(formData)
            // console.log("file data", reader.result)

            formData.append('photo', files[0])
            this.setState({
                imageUrl: reader.result,
                file: files[0]
            })
        }

    }

    render() {

        const { errors } = this.state

        return (
            <div className="form-register">
                <Form onSubmit={this.register}>
                    <FormGroup>
                        <img alt="Profile" src={this.state.imageUrl || logo} width="180px" />
                        <Input type="file" name="file" onChange={this.onFileSelectHandler} accept={imageAcceptFormat} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="firstName">First Name</Label>
                        <Input onChange={this.valueChangeHandler} type="text" name="firstName" placeholder="John" value={this.state.firstName} />
                        <span className="error">{errors.firstName}</span>
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName">Last Name</Label>
                        <Input type="text" onChange={this.valueChangeHandler} name="lastName" placeholder="Doe" value={this.state.lastName} />
                        <span className="error">{errors.lastName}</span>
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName">User Name</Label>
                        <Input  autocomplete="off" type="text" onChange={this.valueChangeHandler} name="userName" placeholder="user" value={this.state.userName} />
                        <span className="error">{errors.userName}</span>
                    </FormGroup>
                    {!forEdit ?
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="email" onChange={this.valueChangeHandler} name="email" placeholder="minute@gmail.com" value={this.state.email} />
                            <span className="error">{errors.email}</span>
                        </FormGroup> : ""}
                    <Label for="contactNumber">Contact Number</Label><br></br>
                    <NumberFormat format="+################" allowEmptyFormatting mask=" " onChange={this.valueChangeHandler} name="phone" value={this.state.phone} /><br></br>
                    <span className="error">{errors.contact}</span><br></br>
                    <FormGroup>
                        <Label for="body">About</Label>
                        <Input type="textarea" onChange={this.valueChangeHandler} name="about" id="about" value={this.state.about} />
                    </FormGroup>
                    {
                        !forEdit ? <>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input autocomplete="new-password" type="password" onChange={this.valueChangeHandler} name="password" placeholder="password" />
                                <span className="error">{errors.password}</span>
                            </FormGroup>
                            <FormGroup>
                                <Label for="confirmPassword">Confirm Password</Label>
                                <Input type="password" onChange={this.valueChangeHandler} name="confirmPassword" placeholder="confirm password" />
                            </FormGroup>
                        </> : <></>
                    }
                    <Button>Submit</Button>
                </Form>
            </div>
        );
    }
}

export default Register;