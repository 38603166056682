import React, { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ReactPaginate from 'react-paginate';

function Pagination(props) {
    const { type, setPage } = props;

    const [page, setPageNum] = useState(0);

    const { pagination } = useStoreState(state => ({
        pagination: state.pagination,
    }));

    const { getPosts, getUsers, getHashTags, getPodcastCollection } = useStoreActions(action => ({
        getPosts: action.getPosts,
        getUsers: action.getUsers,
        getHashTags: action.getHashTags,
        getPodcastCollection: action.getPodcastCollection,
    }));

    const changePage = (e) => {
        const selectedPage = e.selected < 1 ? 1 : e.selected + 1;

        if (setPage) {
            setPage(selectedPage);
        }
        setPageNum(selectedPage);

        switch (type) {
            case 'user':
                getUsers({ page: selectedPage });
                break;
            case 'hashtags':
                getHashTags({ page: selectedPage });
                break;
            case 'podcast_collection':
                getPodcastCollection({ page: selectedPage });
                break;
            default:
                getPosts({ type: type, page: selectedPage });
                break;
        }
    };

    const getMaxPage = () => {
        switch (type) {
            case '1':
                return pagination.minutesPage?.np || 1;
            case '3':
                return pagination.qnaPage?.np || 1;
            case '4':
                return pagination.sharedPage?.np || 1;
            case 'hashtags':
                return pagination.hashtagsPage?.np || 1;
            case 'podcast_collection':
                return pagination.podcastCollectionPage?.np || 1;
            case 'user':
                return pagination.usersPage?.np || 1;
            default:
                return pagination.postPage?.np || 1;
        }
    };

    const getInitialPage = () => {
        switch (type) {
            case '1':
                return (pagination.minutesPage?.p || 1) - 1;
            case '3':
                return (pagination.qnaPage?.p || 1) - 1;
            case '4':
                return (pagination.sharedPage?.p || 1) - 1;
            case 'hashtags':
                return (pagination.hashtagsPage?.p || 1) - 1;
            case 'podcast_collection':
                return (pagination.podcastCollectionPage?.p || 1) - 1;
            case 'user':
                return (pagination.usersPage?.p || 1) - 1;
            default:
                return (pagination.postPage?.p || 1) - 1;
        }
    };

    const isPaged = () => {
        switch (type) {
            case '1':
                return Boolean(pagination.minutesPage);
            case '3':
                return Boolean(pagination.qnaPage);
            case '4':
                return Boolean(pagination.sharedPage);
            case 'hashtags':
                return Boolean(pagination.hashtagsPage);
            case 'podcast_collection':
                return Boolean(pagination.podcastCollectionPage);
            case 'user':
                return Boolean(pagination.usersPage);
            default:
                return Boolean(pagination.postPage);
        }
    };

    return (
        <div>
            {isPaged() && (
                <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={getMaxPage()}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={changePage}
                    initialPage={props.page || getInitialPage()}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active-page'}
                />
            )}
        </div>
    );
}

export default Pagination;
