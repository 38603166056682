export const checkIsAllowPostCommentOrReply = (date_future, periodInMins) => {
  if (date_future && periodInMins) {
    let date_now = new Date().getTime()
    let delta = Math.abs(date_future - date_now) / 1000;
    let minutes = Math.floor(delta / 60) % 60;
    return { isAllow: periodInMins >= minutes, leftMins: periodInMins - minutes }
  } else return null
}

export function secondsToDhms(mins) {
  mins = Number(mins);

  var d = Math.floor(mins / 1440);
  var h = Math.floor((mins-(d*1440))/60);
  var m = Math.floor(mins % 60);
  
  var dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
  return dDisplay + hDisplay + mDisplay;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}

export const getAudioDuraion = file => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = error => reject(error);
    })
  }

export function getDuration(duration) {
  if (!duration) return '00:00:00'

  let hours = Math.floor(duration / 3600)
  duration %= 3600
  let minutes = Math.floor(duration / 60)
  let seconds = Math.floor(duration % 60)

  if (minutes === 0 && seconds === 0 && hours === 0) {
    return '00:00:00'
  }

  const hoursString =
    hours.toString().length <= 1 ? '0' + hours.toString() : hours

  const secondsString =
    seconds.toString().length <= 1 ? '0' + seconds.toString() : seconds
  const minuteString =
    minutes.toString().length === 1 ? '0' + minutes.toString() : minutes

  return hoursString + ':' + minuteString + ':' + secondsString
}
