import React from 'react'
import AppLayout from "../../layout/AppLayout";
import { useStoreState, useStoreActions } from 'easy-peasy'
import {
    Route,
    Switch
} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Dashboard from './dashboard/Dashboard.js'
import Accounts from './dashboard/Accounts';
import Posts from './dashboard/Posts';
import HashTags from './dashboard/HashTags';
import Donations from './dashboard/Donations';
import Profile from '../user/Profile';
import Post from '../../components/forms/Post';
import Register from '../../components/forms/Register';
import DisableUser from '../../components/forms/DisableUser';
import RecommendUser from './dashboard/RecommendUser';
import RecommendTags from './dashboard/RecommendTags';
import FlaggedPosts from './dashboard/FlaggedPosts';
import FlaggedComments from './dashboard/FlaggedComments';
import LandingRegistrations from './dashboard/LandingRegistrations';
import ModalWindowCreate from './dashboard/ModalWindowCreate';
import ModalWindows from './dashboard/ModalWindows';
import FlaggedDetails from './dashboard/FlaggedDetails';
import AnnouncementCreate from './dashboard/AnnouncementCreate';
import Podcasts from './dashboard/Podcasts';
import Episodes from './dashboard/Episodes';
import PodcastCreate from './dashboard/PodcastCreate';
import PodcastCollectionCreate from "./dashboard/PodcastCollectionCreate";
import PodcastCollection from "./dashboard/PodcastCollection";
import Thumbnails from "./dashboard/Thumbnails";
import ThumbnailCreate from "./dashboard/ThumbnailCreate";
import ChatMessages from "./dashboard/ChatMessages";
import Chat from "./dashboard/Chat";
import FlaggedUsers from "./dashboard/FlaggedUsers";
import FlaggedUserDetails from "./dashboard/FlaggedUserDetails";

function MainApp(props) {

    const {
        users,
        podcastCollections,
        speakers,
        posts,
        hashtags,
        selectedUser,
        profile,
        featured,
        pagination,
        recommendedTags,
        recommendedUsers,
        recommendedSpeakers,
        flaggedPosts,
        flaggedUsers,
        selectedFlag,
        selectedFlaggedUser,
        flaggedComments,
        selectedComment,
        notifTimer,
        notifData,
        searchNotifTarget,
        selectedPodcast,
        searchRecommendUser } = useStoreState(state => ({
            users: state.accounts,
            podcastCollections: state.podcastCollections,
            posts: state.posts,
            hashtags: state.hashtags,
            selectedUser: state.selectedUser,
            profile: state.session.profile,
            featured: state.posts.featured,
            pagination: state.pagination,
            recommendedTags: state.recommendedTags,
            recommendedSpeakers: state.recommendedSpeakers,
            recommendedUsers: state.recommendedUsers,
            flaggedPosts: state.posts.flagged,
            flaggedUsers: state.users?.flagged,
            selectedFlag: state.selectedFlag,
            selectedFlaggedUser: state.selectedFlaggedUser,
            flaggedComments: state.posts.flaggedComments,
            selectedComment: state.selectedComment,
            notifData: state.notifData,
            notifTimer: state.notifTimer,
            searchNotifTarget: state.searchNotifTarget,
            searchRecommendUser: state.searchRecommendUser,
            speakers: state.speakers,
            selectedPodcast: state.selectedPodcast
        }))

    const {
        getUsers,
        createUser,
        getPosts,
        createPost,
        uploadMedia,
        getHashTags,
        editHashTag,
        deleteHashTag,
        getProfile,
        upload,
        getFeatured,
        editUser,
        getFollows,
        recommendTag,
        searchList,
        getRecommendedTags,
        getRecommendedUsers,
        getAvailableUsers,
        updateUserLevel,
        clearPostSearchResults,
        getFlaggedPosts,
        getFlaggedUsers,
        removeUser,
        getFlaggedComments,
        sortList,
        getSpeakers,
        checkIsExpireToken,
        sendTargettedNotif } = useStoreActions(action => ({
            getUsers: action.getUsers,
            createUser: action.createUser,
            getPosts: action.getPosts,
            getHashTags: action.getHashTags,
            editHashTag: action.editHashTag,
            deleteHashTag: action.deleteHashTag,
            createPost: action.createPost,
            uploadMedia: action.uploadMedia,
            getProfile: action.getProfile,
            upload: action.upload,
            getFeatured: action.getFeatured,
            editUser: action.editUser,
            recommendTag: action.suggesTag,
            getRecommendedTags: action.getRecommendedTags,
            getRecommendedUsers: action.getRecommendedUsers,
            getAvailableUsers: action.getAvailableUsers,
            searchList: action.searchList,
            updateUserLevel: action.updateUserLevel,
            getFollows: action.getFollows,
            clearPostSearchResults: action.clearPostSearchResults,
            getFlaggedPosts: action.getFlaggedPost,
            getFlaggedUsers: action.getFlaggedUser,
            removeUser: action.deleteUser,
            getFlaggedComments: action.getFlaggedComments,
            sendTargettedNotif: action.sendTargettedNotif,
            sortList: action.sortList,
            getSpeakers: action.getSpeakers,
            checkIsExpireToken: action.checkIsExpireToken
        }))

    const backToApp = () => {
        props.history.push("/app/accounts")
    }

    const register = (payload) => {
        let user = {
            email: payload.email,
            firstName: payload.firstName,
            lastName: payload.lastName,
            phone: payload.phone,
            password: payload.password,
            file: payload.file,
            userLevel: payload.userLevel,
            about: payload.about,
            push: backToApp

        }
        createUser(user)
    }

    const deleteUser = (payload) => {
        confirmAlert({
            title: 'Delete Account',
            message: 'Are you sure to delete this account?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        removeUser(payload)
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }


    return (
        <AppLayout props={props} getProfile={getProfile}>
            <div className="content">
                <Switch>
                    <Route
                        exact
                        path="/app"
                        render={() => <Dashboard {...props} featured={getFeatured} featuredPost={featured} />}
                    />
                    <Route
                        path="/app/announcements/:type"
                        render={props =>
                            <AnnouncementCreate
                                {...props}
                                users={searchNotifTarget}
                                profile={profile}
                                searchList={searchList}
                                sendTargettedNotif={sendTargettedNotif}
                                notifData={notifData}
                                notifTimer={notifTimer}
                                fetchUsers={() => {
                                    getUsers({
                                        page: 1
                                    })
                                }} />}
                        />
                    <Route
                        exact
                        path="/app/accounts"
                        render={props =>
                            <Accounts
                                {...props}
                                users={users}
                                history={props.history}
                                searchList={searchList}
                                sortList={sortList}
                                updateUserLevel={updateUserLevel}
                                fetchUsers={() => {
                                    getUsers({
                                        page: 1
                                    })
                                }} />}
                    />
                    <Route
                        exact
                        path="/app/podcasts"
                        render={props => <Podcasts
                            checkIsExpireToken={checkIsExpireToken}
                             {...props} />}
                    />

                    <Route
                        exact
                        path="/app/podcasts/:id"
                        render={props => <Episodes
                            checkIsExpireToken={checkIsExpireToken}
                             {...props} />}
                    />

                    <Route
                        exact
                        path="/app/posts"
                        render={props => <Posts {...props}
                            posts={posts}
                            profile={profile}
                            pagination={pagination}
                            clearPostSearchResults={
                                () => {
                                    clearPostSearchResults()
                                }
                            }
                            fetchPosts={(payload) => {
                                getPosts(payload)
                            }} />}
                    />
                    <Route
                        exact
                        path="/app/hashtags"
                        render={props => <HashTags {...props}
                            hashtags={hashtags}
                            pagination={pagination}
                            editHashTag={(payload) => {
                                editHashTag(payload)
                            }}
                           deleteHashTag={(payload) => {
                               deleteHashTag(payload)
                            }}
                            fetchHashTags={(payload) => {
                                getHashTags(payload)
                            }} />}
                    />
                    <Route
                        path="/app/donations"
                        render={props => <Donations {...props} />}
                    />

                    <Route
                        exact
                        path="/app/accounts/create"
                        render={props => <Register {...props} register={register} />}
                    />

                    <Route
                        path="/app/podcasts/edit/:id"
                        render={props => <PodcastCreate {...props} selectedPodcast={selectedPodcast} podcastCollections={podcastCollections} />}
                    />

                    <Route
                        exact
                        path="/app/podcast_episode/create"
                        render={props => <PodcastCreate {...props} podcastCollections={podcastCollections} />}
                    />

                    <Route
                        exact
                        path="/app/podcast_collection/create"
                        render={props => <PodcastCollectionCreate {...props} />}
                    />

                    <Route
                        path="/app/podcast_collection/edit/:id"
                        render={props => <PodcastCollectionCreate {...props} />}
                    />

                    <Route
                        exact
                        path="/app/podcast_collection"
                        render={props => <PodcastCollection {...props} />}
                    />

                    <Route
                      exact
                      path="/app/thumbnails/create"
                      render={props => <ThumbnailCreate {...props} />}
                    />

                    <Route
                      path="/app/thumbnails/edit/:id"
                      render={props => <ThumbnailCreate {...props} />}
                    />

                    <Route
                      exact
                      path="/app/thumbnails"
                      render={props => <Thumbnails {...props} />}
                    />


                    <Route
                        path="/app/accounts/edit/:id"
                        render={props => <Register {...props} selectedUser={selectedUser} editUser={editUser} />}
                    />

                    <Route
                        path="/app/accounts/disable/:id"
                        render={props => <DisableUser {...props} selectedUser={selectedUser} editUser={editUser} />}
                    />

                    <Route
                        path="/app/accounts/:id"
                        render={props => <Profile {...props} selectedUser={selectedUser} editUser={editUser} getFollows={getFollows} />}
                    />

                    <Route
                        path="/app/posts/create/:type"
                        render={props => <Post {...props}
                            createPost={createPost}
                            uploadMedia={uploadMedia}
                            upload={upload}
                            users={speakers}
                            getSpeakers={() => {
                                getSpeakers()
                            }}
                            profile={profile} />}
                    />
                    <Route
                        exact
                        path="/app/recommend/users"
                        render={props =>
                            <RecommendUser
                                {...props}
                                users={searchRecommendUser}
                                history={props.history}
                                recommendedUsers={recommendedUsers}
                                searchList={searchList}
                                getRecommendedUsers={() => {
                                    getRecommendedUsers()
                                }}
                                fetchUsers={() => {
                                    getAvailableUsers()
                                }} />}
                    />
                    <Route
                        exact
                        path="/app/recommend/tags"
                        render={props =>
                            <RecommendTags
                                {...props}
                                recommendedTags={recommendedTags}
                                recommendedSpeakers={recommendedSpeakers}
                                recommendTag={recommendTag}
                                getRecommendedTags={() => {
                                    getRecommendedTags()
                                }}
                            />}
                    />

                    <Route
                        exact
                        path="/app/flagged/posts"
                        render={props =>
                            <FlaggedPosts
                                {...props}
                                flaggedPosts={flaggedPosts}
                                getFlaggedPosts={() => {
                                    getFlaggedPosts()
                                }}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/app/flagged/users"
                        render={props =>
                            <FlaggedUsers
                                {...props}
                                flaggedUsers={flaggedUsers}
                                getFlaggedUsers={() => {
                                    getFlaggedUsers()
                                }}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/app/flagged/comments"
                        render={props =>
                            <FlaggedComments
                                {...props}
                                flaggedComments={flaggedComments}
                                getFlaggedComments={() => {
                                    getFlaggedComments()
                                }} />
                        }
                    />
                    <Route
                        exact
                        path="/app/landing/registrations"
                        render={props =>
                            <LandingRegistrations
                                {...props} />
                        }
                    />
                    <Route
                        exact
                        path="/app/modals"
                        render={props =>
                            <ModalWindows
                                {...props} />
                        }
                    />
                    <Route
                      exact
                      path="/app/messages"
                      render={props =>
                        <ChatMessages {...props} />
                      }
                    />
                    <Route
                      exact
                      path="/app/chat/:id"
                      render={props =>
                        <Chat {...props} />
                      }
                    />
                    <Route
                        exact
                        path="/app/modal/create"
                        render={props =>
                            <ModalWindowCreate {...props} />
                        }
                    />
                    <Route
                        path="/app/modal/edit/:id"
                        render={props => <ModalWindowCreate {...props} />}
                    />
                    <Route
                      exact
                      path="/app/flagged/users/:id"
                      render={props =>
                        <FlaggedUserDetails
                          {...props}
                          profile={profile}
                          selectedUser={selectedFlaggedUser}
                          deleteUser={(payload) => {
                              deleteUser(payload)
                          }} />
                      }
                    />
                    <Route
                        exact
                        path="/app/flagged/:type/:id"
                        render={props =>
                            <FlaggedDetails
                                {...props}
                                profile={profile}
                                selectedFlag={selectedFlag}
                                selectedComment={selectedComment}
                                deleteUser={(payload) => {
                                    deleteUser(payload)
                                }} />
                        }
                    />


                </Switch>
            </div>
        </AppLayout>
    )
}

export default MainApp