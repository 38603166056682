import React from 'react';

function FriendOption({ user, selectData }) {
    const imgStyle = {
        borderRadius: '50%',
        verticalAlign: 'middle',
        marginRight: 10,
    };

    const selectUser = (e) => {
        e.preventDefault()

        selectData(user)

    }

    return (
        <div className="card card-option" onClick={selectUser}>
            <div className="option-container">
                    <img alt="" style={imgStyle} width="32" height="32" src={user.imageUrl} /><span>{user.email}</span>
            </div>

        </div>
    );
}

export default FriendOption;