import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import logo from '../../assets/images/logo.jpg';


class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: "",
            password: ""
        }
    }

    valueChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }
        )
    }

    passwordChangeHandler = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    login = (e) => {
        e.preventDefault()
        this.props.login(this.state, this.props.history)
    }

    render() {
        var Loader = require('react-loader');

        return (

            <Loader loaded={this.props.loaded}>
                <Form className="login-form" onSubmit={this.login}>
                    <img src={logo} alt="test logo" className="logo" />
                    <FormGroup>
                        <Label>Email</Label>
                        <Input type="email" name="email" placeholder="meaningfulminute@gmail.com" onChange={this.valueChangeHandler} />
                        <Label>Password</Label>
                        <Input type="password" name="password" placeholder="password" onChange={this.passwordChangeHandler} />
                    </FormGroup>

                    <Button className="btn-lg  btn-block mt-2">Login</Button>
                </Form>
            </Loader>
        );
    }
}

export default Login;