import React, {Component} from 'react';
import {Button, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import { imageAcceptFormat, videoFormats } from '../../constants/defaultValues';
import MultipleImageUploadComponent from '../items/MultipleImageUploadComponent';
import Spinner from '../common/Spinner';

let type = 'minute'

class Post extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: '',
      webUrl: '',
      body: '',
      mimeType: 'text/plain',
      kind: "DisplayablePost",
      thumbnailUrl: '',
      postType: 0,
      isVideo: false,
      file: null,
      uploadedMedias: [],
      videoInfo: null,
      isSubmitting: false,
    }
    this.props.getSpeakers()

    //Post type = 1 : Minute , 0 : Post
    type = this.props.match.params.type
  }


  UNSAFE_componentWillMount() {
    let {users, profile} = this.props
    users.forEach(user => {
      if (user._id === profile._id) {
        this.setState({
          otherId: user._id,
          title: user.firstName + " " + user.lastName
        })
      }
    })
  }

  onFileSelectHandler = (e) => {
    try {
      let files = e.target.files

      let reader = new FileReader()
      reader.readAsDataURL(files[0])

      if (files[0].type.includes("image")) {
        this.setState({
          mimeType: 'image',
          isVideo: false

        })
      } else {
        this.setState({
          mimeType: 'video',
          isVideo: true
        })
      }

      reader.onload = (e) => {
        this.setState({
          file: files[0]
        })
      }

    } catch (e) {
      this.setState({
        file: null,
        isVideo: false
      })
    }
  }

  changeEventHandler = (e) => {
    const {name, value} = e.target
    this.setState({
      [name]: value
    })
  }

  startSubmitting = () => {
    this.setState({ isSubmitting: true });
  }

  post = (e) => {
    e.preventDefault()
    let postType = 0
    switch (type) {
      case 'minute':
        postType = 1;
        break
      case 'post':
        postType = 0;
        break
      // case 'qna': postType = 2; break
      default:
        postType = 2;
        break
    }

    if (!this.state.file && this.state.body === '') return;

    this.startSubmitting()

    this.setState(
      {
        postType: postType,
      },
      () => {
        const payload = {
          ...this.state,
          medias: this.state.uploadedMedias,
          history: this.props.history,
        };
        if (this.state.videoInfo) {
          payload.videoInfo = this.state.videoInfo;
        }
        this.props.createPost(payload);
      }
    );
  };

  push = () => {
    this.props.history.push()
  }

  selectOther = (e) => {
    let {users} = this.props
    let i = e.target.value

    if (this.state.isVideo && type === "post") {
      this.setState({
        otherId: users[i]._id,
        title: users[i].firstName + " " + users[i].lastName
      })
    } else {
      this.setState({
        otherId: users[i]._id
      })
    }

  }

  uploadMediaFunc = async (file) => {
    const isVideo = file?.type.includes('video')

    const mimeType = file.type.includes('image') ? 'image/jpeg' : 'video/mp4'
    let payload = {
      file,
      mimeType,
    }
    let media = await this.props.uploadMedia(payload)
    const {
      origMediaUrl,
      videoUrl: mediaUrlIfVideo,
      thumbnailUrl,
      photoUrl,
      mediaUrl,
    } = media

    let newItem = {
      mimeType,
      mediaUrl,
      thumbnailUrl,
      id: file?.name,
      position: file?.number,
    }

    this.setState(
      {
        uploadedMedias: isVideo
          ? [newItem]
          : [...this.state.uploadedMedias, newItem],
      },
      () => {
        console.log(
          'MEDIAS:',
          this.state.uploadedMedias,
        )
      }
    )
  }

  onSelected = (medias) => {
    const isVideo = medias[0]?.type.includes('video')
    if (medias?.length) {
      this.setState({
        isVideo,
        mimeType: medias[0].type.includes("image") ? "image" : "video",
        file: medias[0],
      })

      if (!isVideo) {
        medias.forEach((file) => {
          this.uploadMediaFunc(file)
        })
      }
    }
  }

  clear = () => {
    this.setState({file: null, mimeType: 'text/plain'})
  }

  render() {
    const { isSubmitting } = this.state;
    let { profile } = this.props;

    return (
      <>
        <div className="container">
          <div>
            <Row>
              <Form onSubmit={this.post}>
                <FormGroup>
                  <h2>Create {type}</h2>
                  <MultipleImageUploadComponent
                    clear={this.clear}
                    updateVideoInfo={(el) =>
                      this.setState({ videoInfo: el })
                    }
                    onSelected={this.onSelected}
                    accept={
                      type === 'minute' || type === 'qna'
                        ? videoFormats
                        : `${videoFormats}, ${imageAcceptFormat}`
                    }
                  />
                </FormGroup>
                {this.state.isVideo && type !== 'post' ? (
                  <FormGroup>
                    <Label for="title">Title</Label>
                    <Input
                      type="text"
                      name="title"
                      onChange={this.changeEventHandler}
                      id="exampleText"
                    />
                  </FormGroup>
                ) : (
                  <></>
                )}

                <FormGroup>
                  <Label for="body">Create Post as: </Label>
                  <Input
                    type="select"
                    name="otherId"
                    id="exampleSelect"
                    onChange={this.selectOther}
                  >
                    {this.props.users.map((user, index) => {
                      return (
                        <option
                          selected={user._id === profile._id}
                          key={user._id}
                          value={index}
                        >
                          {user.firstName} {user.lastName} - {user.email}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>

                {type === 'minute' ||
                type === 'qna' ||
                this.state.isVideo ? (
                  <></>
                ) : (
                  <FormGroup>
                    <Label for="body">Caption</Label>
                    <Input
                      type="textarea"
                      onChange={this.changeEventHandler}
                      name="body"
                      id="exampleText"
                    />
                  </FormGroup>
                )}

                {isSubmitting && <Spinner />}

                <Button>Submit</Button>
              </Form>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default Post;
