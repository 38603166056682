import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';
import FriendOption from '../items/FriendOption';
import SearchBarSubmit from '../common/SearchBarSubmit';

class SearchFriend extends Component {
    constructor(props) {
        super(props)

        this.state = {
            originalList: this.props.users,
            users: this.props.users,
            selectedUsers: []
        }
    
    }

    componentWillReceiveProps(prevProps){
        if(prevProps.users != this.props.users){
            this.setState({
                users: []
            }, () => {
                this.setState({
                    users: this.props.users
                })
            })
        }
    }

    searchList = (value) => {
        if(value.length >= 3){
            this.props.searchList({
                query: value,
                type: 'notif'
            })
        }

        // let { originalList } = this.state
        // if (value === "") {
        //     this.setState({
        //         users: originalList
        //     })

        //     console.log("empty")
        //     return
        // }
        // console.log(value)
        // let newList = originalList
        // newList = newList.filter(user => user.email.includes(value))

        // this.setState({
        //     users: newList
        // })
    }

    removeReceiver = (user) => {
        let { selectedUsers, users, originalList } = this.state

        let selected = selectedUsers
        let userList = users
        let orig = originalList

        selected = selected.filter(e => e !== user)
        userList.push(user)
        orig.push(user)

        this.setState({
            selectedUsers: selected,
            users: userList,
            originalList: orig
        })

        this.setIds(selected)
    }

    addReceiver = (user) => {
        let { selectedUsers, users, originalList } = this.state

        let selected = selectedUsers
        let userList = users
        let orig = originalList

        selected.push(user)
        userList = userList.filter(e => e !== user)
        orig = orig.filter(e => e !== user)

        this.setState({
            selectedUsers: selected,
            users: userList,
            originalList: orig
        })

        this.setIds(selected)
    }

    setIds = (selected) => {
        let ids = []
        selected.forEach(user => {
            ids.push(user._id)
        });
        this.props.setSelectedIds(ids)
    }


    render() {
        const { users, selectedUsers } = this.state
        return (
            <>
                <Row>
                    <Col>
                        <Row>
                            <Colxx lg='6' xx='3' >
                                {/* <SearchBar searchHandler={this.searchList} /> */}
                                <SearchBarSubmit type={'notif'} />
                            </Colxx>
                        </Row>
                        <Row>
                            <div className="receiver-container">
                                {users.map(user => {
                                    return <FriendOption user={user} key={user._id} selectData={this.addReceiver} />
                                })}
                            </div>
                        </Row>
                    </Col>

                    <Col>
                        <Row>
                            <Colxx lg='6' xx='3' >
                                <h3>Selected</h3>
                            </Colxx>
                        </Row>
                        <Row>
                            {
                                selectedUsers ?
                                    <div className="receiver-container">
                                        {selectedUsers.map(user => {
                                            return <FriendOption user={user} key={user._id} selectData={this.removeReceiver} />
                                        })}
                                    </div> : ""
                            }


                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

export default SearchFriend;