import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { validEmailRegex } from '../../helpers/Regex'

class ModalUpdateEmail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: "",
            modal: false,
            error: ""
        }
    }

    componentDidMount() {

        this.setState({
            email: this.props.user.email
        })
    }

    valueChangeHandler = (e) => {
        const { value } = e.target
        let { error } = this.state

        error = validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';

        this.setState({
            error,
            email: value
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    submit = (e) => {

        e.preventDefault()

        const { _id, uid } = this.props.user
        const { updateEmail } = this.props

        if (this.state.error !== '') return

        updateEmail({
            email: this.state.email,
            _id: _id,
            uid : uid
        })
        this.toggle()
     

    }

    render() {

        let { email, modal, error } = this.state
        const { firstName, lastName } = this.props.user

        return (
            <div>
                <Button color="link" onClick={this.toggle}>Change Email</Button>
                <Modal isOpen={modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle}>{firstName} {lastName}</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.submit}>
                            <FormGroup >
                                <Label> New Email </Label>
                                <Input type="text" name="email" onChange={this.valueChangeHandler} placeholder="" defaultValue={email} autoComplete="off" />
                                <span className="error">{error}</span>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.submit}>Save Changes</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalUpdateEmail;