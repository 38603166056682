import React from 'react';
import { Card, CardText, Button } from 'reactstrap';
import logo from '../../assets/images/logo.jpg'
import { useStoreActions } from 'easy-peasy'


function UserCard(props) {
    const { user, index, forProfile } = props

    const { unrecommendUser } = useStoreActions(action => ({
        unrecommendUser: action.recommendUser
    }))

    return (
        <>
            <Card body className="text-center card-user">
                <img src={user.imageUrl || logo} alt="avatar" className="avatar card-avatar" />
                {!forProfile ?
                    <>
                        <CardText>
                            <b>{user.firstName} {user.lastName}</b>
                            <br />
                            {user.email} <br /> {user.phone}
                        </CardText>
                        <Button color="danger" onClick={() => unrecommendUser({
                            _id: user._id,
                            data: {
                                isFeatured: false
                            },
                            index: index
                        })}>Remove</Button>
                    </>
                    : <CardText><b>{user.fullName}</b></CardText>
                }

            </Card>
        </>
    );
}

export default UserCard;