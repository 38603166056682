import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
    Button
} from 'reactstrap'

function DonationItem(props) {

    const { donation, postId, closeModal, openOptions } = props

    var dateFormat = require('dateformat');

    // const displayName = !donation.isAnonymous ? donation.hebrewName : 'Anonymous'
    const donationStatus = donation.status === 'paid' ? "OldVersion" : "Failed"
    const displayName = donation.firstName + ' ' + donation.lastName
    const sponsorPost = () => {

        if (postId != null && closeModal != null) {
            const payload = {
                postId: postId,
                sponsorDetails: donation,
                sponsor: {
                    donationId: donation._id,
                }
            }
            openOptions(payload)
            // addSponsor(payload)
            // closeModal()
        }
    }

    const getSponsorValue = (sponsor) => {
        switch (sponsor) {
            case 'A': return 'All';
            case 'R': return 'Refua Shaleima'
            case 'S': return 'Shidduchim'
            case 'P': return 'Parnassa'
            case 'L': return `L'iluy Nishmas`
            case 'H': return 'Hatzlacha'
            case 'O': return 'Other'
            default: return sponsor
        }
    }


    return (
        <tr key={donation._id} onClick={sponsorPost}>
            <td>
                <Button active={false} color={donation.status === 'paid' ? "success" : "danger"}>
                    { donation.status === 'paid'
                    ? donation.comment === 'stripe'
                        ?  donation.comment === 'stripe/apple'
                            ? "Stripe "
                            : "Stripe"
                        : "OldVersion"
                    : "Failed"
                  }
                </Button>
            </td>
            <td>{displayName}</td>
            <td>${donation.amount}</td>
            <td>
                {dateFormat(donation.dateCreated, "mm/dd/yyyy - h:MM tt")}
            </td>
            <td>{getSponsorValue(donation.sponsorFor)}</td>
            <td>{donation.emailAddress}</td>
            <td>{donation.address}</td>
            <td>{donation.phoneNumber}</td>
            <td>{donation.city}</td>
            <td>{donation.zip}</td>
            <td>{donation.country}</td>
            <td>{donation.state}</td>
            <td>{donation.comment==='stripe'?'':donation.comment}</td>
            <td>{donation.hebrewName}</td>
            <td>{donation.fathersHebrewName}</td>
            <td>{donation.mothersHebrewName}</td>
            {/* <td>
                {getDedication()}

            </td> */}

        </tr>
    );
}

export default DonationItem;
