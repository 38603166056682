import React from 'react';
import { Button } from 'reactstrap';
import { useStoreActions } from 'easy-peasy'


function FlaggedCommentItem(props) {

    const { setSelectedComment } = useStoreActions(action => ({
        setSelectedComment: action.setSelectedComment
    }))

    const { comment } = props
    var dateFormat = require('dateformat')
    
    const viewFullPost = () => {
        setSelectedComment(comment)
        props.history.push(`/app/flagged/comments/${comment._id}`)
    }

    return (
        <tr>
            {/* <td><Button color="danger">Unresolved</Button></td> */}
            <td>{comment.authorName}</td>
            <td>{comment.flagCount}</td>
            <td>{dateFormat(comment.dateCreated, "mmm dd, yyyy - h:MM tt")}</td>
            <td>{comment._id}</td>
            <td>{comment.text}</td>
            <td><Button color="link" onClick={viewFullPost}>View Comment</Button></td>
        </tr>
    );
}

export default FlaggedCommentItem;