import React from 'react';
import { Button } from 'reactstrap'
import { useStoreActions } from 'easy-peasy'

function RecommendUserItem(props) {

    const { user } = props

    const { recommendUser } = useStoreActions(action => ({
        recommendUser: action.recommendUser
    }))

    return (
        <tr key={user._id}>
            <td >{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.email}</td>
            <td>{user.phone}</td>
            <td>
                <Button color="primary" onClick={() => recommendUser({
                    _id: user._id,
                    data: {
                        isFeatured: true,
                    },
                    user: user
                })}>Recommend</Button>

            </td>
        </tr >
    );
}

export default RecommendUserItem