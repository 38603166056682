import { config } from './config'

export const firebaseConfig = {
    apiKey: 'AIzaSyCXgp2iP0ggjLGXAY6_PJ2cFhgj-H8XsTk',
    authDomain: 'meaningful-minute.firebaseapp.com',
    databaseURL: 'https://meaningful-minute.firebaseio.com',
    projectId: 'meaningful-minute',
    storageBucket: 'meaningful-minute.appspot.com',
    messagingSenderId: '363694318259',
    appId: '1:363694318259:web:7d2d28c168eb68e2dc06c6',
    measurementId: 'G-7911K8VKJY'
}

export const imageAcceptFormat = 'image/jpg, image/jpeg, image/png'
export const videoFormats = '.mp4, .3gp, .mov, .avi, .flv, .m4v'

export const baseUrl = config.host.apiUrl
// export const baseUrl = ''

export let baseHeader = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("token") || ''
        }
    }
}

export let baseMultipartHeader = () => {
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token')
        }
    }
}

export const defaultScreens = [
    {},
    {
        name: 'Post',
        type: 'post',
        link: 'Today',
    },
    {
        name: 'Podcast',
        type: 'podcastCollection',
        link: 'Podcasts',
    },
    {
        name: 'Episode',
        type: 'podcast',
        link: 'Podcast',
    },
    {
        name: 'User',
        type: 'user',
        link: 'SpeakerProfile',
    },
    {
        name: 'Hash Tag',
        type: 'hashTag',
        link: 'PopularTagsDetails',
    },
    {
        name: 'External Link',
        type: 'externalLink',
        link: '',
    },
    {
        name: 'App Screen',
        type: 'screen',
        link: '',
    },
]

export const appScreen = [
    {},
    {
        name: 'Feed',
        type: 'screen',
        link: 'Feed',
    },
    {
        name: 'Donate',
        type: 'screen',
        link: 'Donations',
    },
    {
        name: 'My Profile',
        type: 'screen',
        link: 'Profile',
    },
    {
        name: 'Notifications',
        type: 'screen',
        link: 'Notifications',
    },
]
