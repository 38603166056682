import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Spinner, Button } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import UserCard from '../../../components/card/UserCard';
import RecommendUserItem from '../../../components/items/RecommendUserItem';
import SearchBarSubmit from '../../../components/common/SearchBarSubmit';
import axios from 'axios'
import { CSVLink } from "react-csv";
import { baseUrl } from '../../../constants/defaultValues'

class RecommendUser extends Component {

    constructor(props) {
        super(props)
        this.state = {
            users: this.props.users,
            recommendedUsers: [],
            originalList: [],
            csvData: [],
            isExporting: false,
            isLoaded: true
        }
    }

    componentDidMount() {
        // this.props.fetchUsers()
        this.getRecommendedUsers()
        // this.props.getRecommendedUsers()


        // var propUser = this.props.users
        // let recommendedUsers = propUser.filter(user => !user.isFeatured)

        // this.setState({
        //     users: this.props.users,
        //     recommendedUsers: recommendedUsers
        // })
    }

    componentWillReceiveProps(prevProps) {
        if (prevProps.users !== this.props.users) {
            this.setState({
                users: []
            }, () => {
                this.setState({
                    users: this.props.users
                })
            })
        }

    }

    getRecommendedUsers  = () => {
        axios.get(`${baseUrl}/users/featured`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            this.setState({
                recommendedUsers: res.data.d
            })
        }).catch(err => {
            // actions.checkError({ err: err })
        })
    }

    searchList = (query) => {

        if (query.length >= 3) {
            this.props.searchList({
                query: query,
                type: 'recommend'
            })
        }

    }

    getRecommendedForExport = async (newUrl) => {

        this.setState({
            isLoaded: false,
            isExporting: true
        })

        const url = newUrl === '' ? `${baseUrl}/users/featured` : newUrl
        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const { next, d } = res.data

            this.addToCsvData(d)

            if (next) {
                this.getRecommendedForExport(next)
            } else {
                this.setState({
                    isLoaded: true
                })
            }

        }).catch(err => {
            console.log(err)
            this.setState({
                isLoaded: true,
                isExporting: false
            })
        })
    }

    addToCsvData = async (newData) => {
        const { csvData } = this.state

        const newCsvData = []
        await newData.forEach(user => {
            let object = {
                _id: user._id,
                uId: user.uid,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                imageUrl: user.imageUrl,
                phone: user.phone,
                admin:user.claims.admin,
                speaker: user.claims.speaker,
                dateCreated: user.dateCreated,
                dateUpdated: user.dateUpdated
            }
            newCsvData.push(object)
        });

        let csvToUpdate = [...csvData, ...newCsvData]
        this.setState({
            csvData: csvToUpdate
        })
    }

    render() {
        const { csvData, isLoaded, isExporting } = this.state
        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Recommend</BreadcrumbItem>
                            <BreadcrumbItem active >Users</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>

                <Row>
                    <Colxx xxl='12'>
                        <h5 className="span-grey">Recommended Users</h5>
                    </Colxx>
                </Row>
                <div className="table-horizontal">

                    <Row>

                        {this.state.recommendedUsers.map((user, index) => {
                            return <UserCard key={index} user={user} index={index} />
                        })}

                    </Row>

                </div>

                <Separator />
                <Row>
                    <Colxx lg='6' xx='3' >
                        {/* <SearchBar searchHandler={this.searchList} /> */}
                        <SearchBarSubmit type={'recommend'} />
                    </Colxx>
                    <Colxx>

                        {!isExporting ?
                            <Button color="success" onClick={() => { this.getRecommendedForExport('') }}>Export</Button>
                            :
                            !isLoaded ?
                                <><Spinner size="sm" color="primary" /> Constructing data </>
                                :
                                <CSVLink
                                    filename={"meaningful-minute-recommended-users.csv"}
                                    className='csv-link'
                                    onClick={() => {
                                        this.setState({
                                            isExporting: false,
                                            isLoaded: true,
                                            csvData: []
                                        })
                                    }}
                                    data={csvData}>DOWNLOAD CSV</CSVLink>
                        }
                    </Colxx>
                </Row>
                <Separator />
                <table className='table'>
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.users.map((user, index) => (
                            <RecommendUserItem {...this.props} user={user} key={user._id} openModal={this.openModal} />
                        ))}
                    </tbody>
                </table>
            </>
        );
    }
}

export default RecommendUser;