import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/css/vendor/bootstrap.min.css";
import 'react-notifications/lib/notifications.css';
import * as serviceWorker from './serviceWorker';
import { StoreProvider, createStore, persist } from 'easy-peasy'
import model from './redux/model'


const store = createStore(persist(model, { storage: 'sessionStorage' }))

ReactDOM.render(<StoreProvider store={store}><App /> </StoreProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
