import React from 'react';
import { useStoreActions } from 'easy-peasy'
import { confirmAlert } from 'react-confirm-alert';
import { Button } from 'reactstrap'
import 'react-confirm-alert/src/react-confirm-alert.css';
import ModalUpdateEmail from '../common/ModalUpdateEmail';
import { checkIsAllowPostCommentOrReply, secondsToDhms } from '../../helpers/utils';
import apple from '../../assets/images/apple.png';
import google from '../../assets/images/google.png';
import facebook from '../../assets/images/facebook.png';

function AccountItem(props) {

    var dateFormat = require('dateformat');

    // eslint-disable-next-line
    const { updateUser, updateUserLevel, removeUser, editUserRequest, getUserFromId, resetPassword, editEmail } = useStoreActions(action => ({
        updateUser: action.updateUser,
        updateUserLevel: action.updateUserLevel,
        removeUser: action.deleteUser,
        getUserFromId: action.getUserFromId,
        resetPassword: action.resetPassword,
        editEmail: action.editEmail,
        editUserRequest: action.editUser
    }))

    const { user } = props

    let startTime = user &&  user.disableOptions &&  user.disableOptions.startTime
    let period = user && user.disableOptions && user.disableOptions.period
    const checkIsAllow = checkIsAllowPostCommentOrReply(startTime, period)
    const  isAccountBlocked = checkIsAllow && checkIsAllow.isAllow
    const leftMins = checkIsAllow && checkIsAllow.leftMins

    const getLevel = (claims) => {
        try {
            let { speaker, admin } = claims

            if (admin) return "Admin"
            else if (speaker) return "Speaker"
            else return "User"

        } catch (e) {
            return "User"
        }
    }

    const getColor = (claims) => {

        try {
            let { speaker, admin } = claims

            if (admin) return "primary"
            else if (speaker) return "info"
            else return "secondary"

        } catch (e) {
            return "secondary"
        }

    }

    const redirectUser = () => {
        getUserFromId(user._id)
        props.history.push(`/app/accounts/${user._id}`)
    }

    const editUser = () => {
        getUserFromId(user._id)
        props.history.push(`/app/accounts/edit/${user._id}`)
    }

    const disableUser = () => {
        if (isAccountBlocked) {
            confirmAlert({
                title: 'Enable Account',
                message: 'Are you sure to enable comments for this user?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            editUserRequest({
                                _id: user._id,
                                disableOptions: {}
                            })
                            setTimeout(() => window.location.reload(), 1000)
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } else {
            getUserFromId(user._id)
            props.history.push(`/app/accounts/disable/${user._id}`)
        }
    }

    const updateEmail = (payload) => {
        confirmAlert({
            title: 'Change Email',
            message: 'Are you sure to update this account?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        editEmail(payload)
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const deleteUser = () => {
        confirmAlert({
            title: 'Delete Account',
            message: 'Are you sure to delete this account?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        removeUser({
                            id: user._id
                        })
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const confirmReset = () => {
        confirmAlert({
            title: 'Reset Password',
            message: 'This account\'s password will be reset. Are you sure you want to reset password?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        resetPassword(user.email)
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const updateLevel = () => {
        props.openModal(user)
    }

    const getProvider = () => {
        switch (user?.provider) {
            case 'apple':
                return apple
            case 'google':
                return google
            case 'facebook':
              return facebook
            default:
               return null
        }
    }

    return (
        <tr style={{ position: 'relative' }} className={isAccountBlocked ? 'user-blocked' : ''} key={user._id}>
            {/* <td className='user-field'>{user.id}</td> */}
            <td className='user-field' onClick={redirectUser}>{user.firstName}</td>
            <td className='user-field' onClick={redirectUser}>{user.lastName}</td>
            <td className='user-field' onClick={redirectUser}>{user.userName}</td>
            <td className='user-field' onClick={redirectUser}>{user.email}</td>
            <td className='user-field' onClick={redirectUser}>{user.phone}</td>
            <td>
                <Button color={getColor(user.claims)} onClick={updateLevel}>{getLevel(user.claims)}</Button>
            </td>
            <td> {dateFormat(user.dateCreated, "mmm d, yyyy - h:MM tt")}</td>
            <td> {dateFormat(user.dateUpdated, "mmm d, yyyy - h:MM tt")}</td>
            <td> {user?.appVersion || ''}</td>
            <td>
                <ModalUpdateEmail user={user} updateEmail={updateEmail} />
                <Button color="link" onClick={confirmReset}>Reset Password</Button>
            </td>
            <td>
                <div className="action-container">
                    <Button color="primary" onClick={editUser}>Edit</Button>
                    <Button color="danger" onClick={deleteUser}>Delete</Button>
                    <Button color="warning" onClick={disableUser}>{isAccountBlocked ? 'Enable' : 'Disable'}</Button>

                    {/* <Button color="danger" onClick={updateUserStatus} >{getActionText(user.status)}</Button> */}
                </div>

            </td>
            {user.provider && <img src={getProvider()} className='left-time left-icon'  style={{ width: 30, height: 30 }} />}
            {isAccountBlocked ? <div style={user.provider ? { left: 30 } : {}} className='left-time'>BLOCKED TIME LEFT: {secondsToDhms(leftMins)}</div> : null}
        </tr >
    );
}

export default AccountItem;