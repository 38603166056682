import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Button, Spinner } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import FlaggedCommentItem from '../../../components/items/FlaggedCommentItem';
import axios from 'axios'
import { CSVLink } from "react-csv";
import { baseUrl } from '../../../constants/defaultValues'

class FlaggedComments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            csvData: [],
            isExporting: false,
            isLoaded: true
        }
    }

    componentDidMount(props) {
        this.props.getFlaggedComments()
    }

    getFlaggedForExport = async (newUrl) => {

        this.setState({
            isLoaded: false,
            isExporting: true
        })

        const url = newUrl === '' ? `${baseUrl}/comments/flagged` : newUrl
        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const { next, d } = res.data

            this.addToCsvData(d)

            if (next) {
                this.getFlaggedForExport(next)
            } else {
                this.setState({
                    isLoaded: true
                })
            }

        }).catch(err => {
            console.log(err)
            this.setState({
                isLoaded: true,
                isExporting: false
            })
        })
    }

    addToCsvData = async (newData) => {
        const { csvData } = this.state

        // const newCsvData = []
        // await newData.forEach(user => {
        //     let object = {
        //         _id: user._id,
        //         uId: user.uid,
        //         email: user.email,
        //         firstName: user.firstName,
        //         lastName: user.lastName,
        //         imageUrl: user.imageUrl,
        //         phone: user.phone,
        //         admin: user.claims.admin,
        //         speaker: user.claims.speaker,
        //         dateCreated: user.dateCreated,
        //         dateUpdated: user.dateUpdated
        //     }
        //     newCsvData.push(object)
        // });

        let csvToUpdate = [...csvData, ...newData]
        this.setState({
            csvData: csvToUpdate
        })
    }

    render() {
        const { flaggedComments } = this.props
        const { csvData, isLoaded, isExporting } = this.state
        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Comments</BreadcrumbItem>
                            <BreadcrumbItem active >Flagged</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>
                {!isExporting ?
                    <Button color="success" onClick={() => { this.getFlaggedForExport('') }}>Export</Button>
                    :
                    !isLoaded ?
                        <><Spinner size="sm" color="primary" /> Constructing data </>
                        :
                        <CSVLink
                            filename={"meaningful-minute-flagged-comments.csv"}
                            className='csv-link'
                            onClick={() => {
                                this.setState({
                                    isExporting: false,
                                    isLoaded: true,
                                    csvData: []
                                })
                            }}
                            data={csvData}>DOWNLOAD CSV</CSVLink>
                }
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Author</th>
                            <th>Flag count</th>
                            <th>Date of Comment</th>
                            <th>Comment ID</th>
                            <th>Comment</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {flaggedComments ? flaggedComments.map((comment, index) => {
                            return <FlaggedCommentItem {...this.props} comment={comment} key={index} />
                        }) : <tr></tr>
                        }
                    </tbody>
                </table>
            </>
        );
    }
}

export default FlaggedComments;