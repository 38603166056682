import React, {Component} from 'react';
import {Col, Button, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row,} from 'reactstrap';
import avatar from '../../assets/images/profile-pic.png';
import trash from '../../assets/images/trash.png';


class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: '1'
        }
    }

    getLevel = () => {
        try {
            let {speaker, admin} = this.props.selectedUser.claims

            if (admin) return "Admin"
            else if (speaker) return "Speaker"
            else return "User"

        } catch (e) {
            return "User"
        }
    }

    deleteUserImage = () => {
        this.props.editUser({ isCustomPayload: true, _id: this.props.selectedUser._id, imageUrl: null })
    }

    selectTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => {
                let {selectedUser, getFollows} = this.props
                let payload

                switch (tab) {
                    case "1":
                        payload = {
                            id: selectedUser._id,
                            type: '1'
                        }
                        getFollows(payload)
                        break;
                    default:
                        payload = {
                            id: selectedUser._id,
                            type: '2'
                        }
                        getFollows(payload)
                        break;
                }
            })

        }
    }

    render() {
        let {activeTab} = this.state
        let {selectedUser} = this.props

        return (
            <>
                <div className="personal-info">
                    <Row>
                        <div style={{ position: 'relative', flex: 1 }}>
                            <img src={selectedUser.imageUrl || avatar} alt="avatar" className="profile-avatar avatar"/>
                            
                            {selectedUser.imageUrl && <Button color="danger" className='delete-avatar' onClick={this.deleteUserImage}>
                                <img src={trash} alt="trash"  style={{ tintColor: 'red', width: 30, height: 30  }}/>
                            </Button>}
                        </div>

                        <Col>
                            <Form>
                                <FormGroup>
                                    <Label>ID</Label>
                                    <Input type="text" readOnly value={selectedUser._id}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type="text" readOnly
                                           value={selectedUser.firstName + ' ' + selectedUser.lastName}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input type="text" readOnly value={selectedUser.email}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>User Level</Label>
                                    <Input type="text" readOnly value={this.getLevel()}/>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col>
                            <Form>
                                <FormGroup>
                                    <Label>UID</Label>
                                    <Input type="text" readOnly value={selectedUser.uid}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>User Name</Label>
                                    <Input type="text" readOnly value={selectedUser.userName}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Contact Number</Label>
                                    <Input type="text" readOnly value={selectedUser.phone}/>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    {/* <div className="personal-container">

                        <table className="table-profile">
                            <tr>
                                <td><p>ID:</p></td>
                                <td>
                                    <h5>
                                        {selectedUser._id}
                                    </h5></td>
                            </tr>
                            <tr>
                                <td><p>Name:</p></td>
                                <td>
                                    <h5>
                                        {selectedUser.firstName} {selectedUser.lastName}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td><p>Email:</p></td>
                                <td>
                                    <h5>
                                        {selectedUser.email}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td><p>Contact Number:</p></td>
                                <td>
                                    <h5>
                                        {selectedUser.phone}
                                    </h5>
                                </td>
                            </tr>


                        </table>
                    </div> */}
                </div>
                <div className="follower-container">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                active={activeTab === '1'}
                                onClick={() => {
                                    this.selectTab('1');
                                }}>
                                Followers ({selectedUser.followersCount})
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={activeTab === '2'}
                                onClick={() => {
                                    this.selectTab('2');
                                }}>
                                Following ({selectedUser.followingCount})
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </>
        );
    }
}

export default Profile;