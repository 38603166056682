import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import FlaggedPostLayout from '../../../components/common/FlaggedPostLayout';
import FlaggedCommentLayout from '../../../components/common/FlaggedCommentLayout';

class FlaggedDetails extends Component {

    removeUser = () => {
        const { selectedFlag, deleteUser } = this.props
        deleteUser({
            id: selectedFlag.authorUid,
            pushHistory: this.pushHistory()

        })
    }

    pushHistory = () => {
        this.props.history.push(`/app/flagged/${this.props.match.params.type}`)
    }

    render() {

        const { selectedFlag, selectedComment } = this.props
        const { type } = this.props.match.params

        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>{type}</BreadcrumbItem>
                            <BreadcrumbItem>Flagged</BreadcrumbItem>
                            <BreadcrumbItem active >{type === 'posts' ? selectedFlag._id : selectedComment._id}</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>

                <Row>
                    {/* <Col>
                        <h3>Reporter Info</h3>
                        <Form>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input type="text" readOnly name="reporter" value="Jane Doe" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Date reported</Label>
                                <Input type="text" readOnly name="reporter" value="March 20, 2020" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Reason for reporting</Label>
                                <Input type="textarea" rows="4" readOnly name="reporter" value="This post is very offensive to me and the community" />
                            </FormGroup>
                        </Form>
                    </Col> */}

                    <Col>
                        <h3>Report Details</h3>
                        <FormGroup>
                            <Label>Author UID</Label>
                            <Input type="text" readOnly name="id" value={type === 'posts' ? selectedFlag.authorUid : selectedComment.authorUid} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Author Name</Label>
                            <Input type="text" readOnly name="name" value={type === 'posts' ? selectedFlag.authorName : selectedComment.authorName} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Times this post was flagged</Label>
                            <Input type="text" readOnly name="counter" value={type === 'posts' ? selectedFlag.flagCount : selectedComment.flagCount} />
                        </FormGroup>

                        {/* <FormGroup>
                            <ModalContactUser buttonType="primary" />
                            <> <Button color="danger" onClick={this.removeUser}>Delete User</Button></>
                        </FormGroup> */}

                    </Col>
                    {
                        type === 'post' ?
                            <Col>
                                <h3>Post Details</h3>
                                <FormGroup>
                                    <Label>Post ID</Label>
                                    <Input type="text" readOnly name="id" value={selectedFlag._id} />
                                </FormGroup>
                                <FlaggedPostLayout post={selectedFlag} pushHistory={this.pushHistory} />
                            </Col>
                            :
                            <Col>
                                <h3>Comment Details</h3>
                                <FlaggedCommentLayout comment={selectedComment} pushHistory={this.pushHistory} />
                            </Col>
                    }

                </Row>
            </>
        );
    }
}

export default FlaggedDetails;