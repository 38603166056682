import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Pagination from '../../../components/common/Pagination';
import HashTagItem from '../../../components/items/HashTagItem';

class HashTags extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  UNSAFE_componentWillMount() {
    this.props.fetchHashTags({
      page: 1
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    let { hashtags } = this.props
    return (
      <>
        <Row>
          {hashtags.map(item => {
            return <HashTagItem {...this.props} item={item} key={item._id} history={this.props.history} />
          })}
        </Row>
        <Row>
          <Col sm="12">
            <Pagination type={"hashtags"} />
          </Col>
        </Row>
      </>
    )
  }
}

export default HashTags