import React from 'react';
import { Button } from 'reactstrap'

function ThumbItem(props) {

    const { item, remove, edit, children } = props


    return (
      <div className="card card-hashtag">
        {item?.stack && item?.stack.map(el => {
          const id = el?.params?.id
          const path = el?.params?.name ? el?.params?.name : id
          return (
            <>
              {path ? <p><b>Navigate to:</b> {path}</p> : null}
              {id ? <p><b>{item.type === 'externalLink' ? 'URL' : 'ID'}</b> {`: ${id}`}</p> : null}
            </>
          )
        })}

        {children ? children() : null}

        <div>
          <Button color="danger" onClick={() => remove(item.id || item._id)}> Delete </Button>
          <Button className={'button-tag'} color={"primary"} onClick={() => edit(item ? item.id || item._id : null)}>Edit</Button>
        </div>
        <img alt="image" src={item?.originalImageUrl} />
      </div>
    )
}

export default ThumbItem