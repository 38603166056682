import React, { Component } from "react";
import Popup from "reactjs-popup";
import DonationItem from "../items/DonationItem";
import { Button, Form, FormGroup, Label, Input, Row, Modal, ModalHeader, ModalBody, ModalFooter, Col } from 'reactstrap';
import { Colxx, Separator } from './CustomBootstrap';
import Pagination from "./Pagination";

class ModalSponsors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            manual: false,
            sponsor: {
                name: "",
                imageUrl: "",
                sponsorFor: "",
                dedication: "",
            },
            selectedSponsor: {

            },
            dedication: {
                hebrewName: '',
                fathersHebrewName: '',
                mothersHebrewName: ''
            },
            errors: {
                name: 'Required',
                imageUrl: "",
                sponsorFor: "Required",
                dedication: "Required"
            },
            modal: false,
        };

    }

    openModal = () => {
        this.setState({ open: true });
    }
    closeModal = () => {
        this.setState({ open: false });
    }

    toggleOption = () => {
        this.setState({ manual: !this.state.manual })
    }

    checkChangeHander = (e) => {
        const { name, checked, value } = e.target

        let newVal = checked ? value : ''

        let { hebrewName, fathersHebrewName, mothersHebrewName } = this.state.dedication

        switch (name) {
            case 'hebrewName':
                this.setState({
                    dedication: {
                        hebrewName: newVal,
                        mothersHebrewName: mothersHebrewName,
                        fathersHebrewName: fathersHebrewName
                    }

                })
                    ; break;
            case 'fathersHebrewName':
                this.setState({
                    dedication: {
                        hebrewName: hebrewName,
                        mothersHebrewName: mothersHebrewName,
                        fathersHebrewName: newVal
                    }

                }); break;
            // case 'mothersHebrewName':
            //     this.setState({
            //         dedication: {
            //             hebrewName: hebrewName,
            //             mothersHebrewName: newVal,
            //             fathersHebrewName: fathersHebrewName
            //         }

            //     }); break;
            default: this.setState({
                dedication: {
                    hebrewName: hebrewName,
                    mothersHebrewName: newVal,
                    fathersHebrewName: fathersHebrewName
                }

            }); break;
        }
    }

    setDedication = (payload) => {
        const { name, value } = payload
        this.setState({
            dedication: {
                [name]: value
            }
        })
    }

    valueChangeHandler = (e) => {
        const { name, value } = e.target
        let errors = this.state.errors
        let sponsorForm = this.state.sponsor

        switch (name) {
            case 'name':
                errors.name =
                    value.length < 1
                        ? 'Name cannot be empty'
                        : '';
                break;
            case 'sponsorFor':
                errors.sponsorFor =
                    value.length < 1
                        ? 'Select something to sponsor'
                        : '';
                break;
            // case 'dedication':
            //     errors.dedication =
            //         value.length < 1
            //             ? 'Dedication cannot be empty'
            //             : '';
            //     break;
            default: errors.dedication =
                value.length < 1
                    ? 'Dedication cannot be empty'
                    : '';
                break;
        }


        sponsorForm[name] = value
        this.setState({
            sponsor: sponsorForm,
            errors
        })
    }

    submitForm = (e) => {
        e.preventDefault()
        if (this.validateform(this.state.errors)) {
            this.props.addSponsor({
                postId: this.props.postId,
                sponsor: this.state.sponsor
            })

            this.closeModal()
        } else {
            console.error('Invalid Form')
        }
    }

    validateform = (errors) => {
        let valid = true

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    openOptions = (payload) => {

        this.setState({
            selectedSponsor: payload
        }, () => {
            this.toggle()
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    submitSelectedSponsor = () => {
        let { sponsorDetails } = this.state.selectedSponsor
        let { dedication } = this.state

        let newDedication = ''
        let dedicationArray = []

        if (dedication.hebrewName) {
            dedicationArray.push(sponsorDetails.hebrewName)
        }
        if (dedication.fathersHebrewName) {
            dedicationArray.push(sponsorDetails.fathersHebrewName)
        }
        if (dedication.mothersHebrewName) {
            dedicationArray.push(sponsorDetails.mothersHebrewName)
        }

        dedicationArray.forEach((value, index) => {
            if (index === 0) {
                newDedication = dedicationArray[index]
            } else {
                newDedication = newDedication + ', ' + dedicationArray[index]
            }
        })

        this.setState({
            selectedSponsor: {
                postId: this.state.selectedSponsor.postId,
                sponsor: {
                    dedication: newDedication,
                    donationId: this.state.selectedSponsor.sponsor.donationId
                }
            }
        }, () => {
            this.toggle()
            this.closeModal()
            this.props.addSponsor(this.state.selectedSponsor)
        })

    }

    render() {

        const buttonName = this.state.manual ? "Select from Donations" : "Create Sponsor"
        let { modal, errors } = this.state
        let { sponsorDetails } = this.state.selectedSponsor
        return (
            <>
                <Modal isOpen={modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle}>Choose what you want to show in the App</ModalHeader>
                    <ModalBody>

                        {
                            sponsorDetails ?

                                <Form>
                                    {
                                        sponsorDetails.hebrewName ? <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="hebrewName" onChange={this.checkChangeHander} value={sponsorDetails.hebrewName} />{' '}
                                                Donating on behalf of: <b>{sponsorDetails.hebrewName}</b>
                                            </Label>
                                        </FormGroup> : ""
                                    }
                                    {
                                        sponsorDetails.fathersHebrewName ? <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="fathersHebrewName" onChange={this.checkChangeHander} value={sponsorDetails.fathersHebrewName} />{' '}
                                                Father's hebrew name: <b>{sponsorDetails.fathersHebrewName}</b>
                                            </Label>
                                        </FormGroup> : ""
                                    }
                                    {
                                        sponsorDetails.mothersHebrewName ? <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="mothersHebrewName" onChange={this.checkChangeHander} value={sponsorDetails.mothersHebrewName} />{' '}
                                                Mother's hebrew name: <b>{sponsorDetails.mothersHebrewName}</b>
                                            </Label>
                                        </FormGroup> : ""
                                    }

                                </Form> : ""
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.submitSelectedSponsor}>Sponsor</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Button color="primary" className="popup-button" onClick={this.openModal}> Sponsor </Button>
                {/* <span onClick={() => this.openModal}> Sponsor </span> */}
                <Popup
                    open={this.state.open}
                    closeOnDocumentClick
                    onClose={this.closeModal}>

                    <div>
                        <div>
                            <Row>
                                <Colxx xxs='12'>
                                    <span>Select Sponsor</span>
                                    <Button color="primary" className="popup-button" onClick={this.toggleOption}> {buttonName} </Button>
                                </Colxx>
                            </Row>
                        </div>

                        <div>
                            {
                                this.state.manual ?
                                    <div>
                                        <Form onSubmit={this.submitForm}>
                                            <FormGroup>
                                                <Label for="name">Name</Label>
                                                <Input onChange={this.valueChangeHandler} type="text" name="name" placeholder="" />
                                                <span className="error">{errors.name}</span>
                                            </FormGroup>
                                            <FormGroup>
                                                <Input type="select" name="sponsorFor" id="exampleSelect" onChange={this.valueChangeHandler} defaultValue=''>
                                                    <option
                                                        key=''
                                                        value=''>Sponsor For:</option>
                                                    <option
                                                        key='R'
                                                        value='R'>Refua Shaleima</option>
                                                    <option
                                                        key='S'
                                                        value='S'>Shidduchim</option>
                                                    <option
                                                        key='P'
                                                        value='P'>Parnassa</option>
                                                    <option
                                                        key='L'
                                                        value='L'>L'iluy Nishmas</option>
                                                    <option
                                                        key='H'
                                                        value='H'>Hatzlacha</option>
                                                    <option
                                                        key='O'
                                                        value='O'>Other</option>
                                                </Input>
                                                <span className="error">{errors.sponsorFor}</span>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="name">Dedication</Label>
                                                <Input onChange={this.valueChangeHandler} type="textarea" name="dedication" placeholder="" />
                                                <span className="error">{errors.dedication}</span>
                                            </FormGroup>
                                            <Button>Submit</Button>
                                        </Form>
                                    </div> :
                                    <div>
                                        <Separator />
                                        <table className='table table-horizontal'>
                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                    <th>Sponsor</th>
                                                    <th>Email</th>
                                                    <th>Address</th>
                                                    <th>Phone Number</th>
                                                    <th>City</th>
                                                    <th>Zip</th>
                                                    <th>Country</th>
                                                    <th>State</th>
                                                    <th>Comment</th>
                                                    <th>Hebrew Name</th>
                                                    <th>Father's Hebrew Name</th>
                                                    <th>Mother's Hebrew Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Add key to each iteration */}
                                                {this.props.donations?.d?.map(donation => (
                                                    <DonationItem
                                                        donation={donation}
                                                        key={donation._id}
                                                        postId={this.props.postId}
                                                        closeModal={this.closeModal}
                                                        openOptions={this.openOptions} />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                            }

                        </div>
                    </div>
                    <Row>
                        <Col sm="12">
                            <Pagination page={this.props.donations.p -1} maxPage={this.props.donations?.np} changePage={this.props.changePage} />
                        </Col>
                    </Row>
                </Popup>
            </>
        );
    }
}

export default ModalSponsors