import React, { useState, useCallback, useRef, useEffect } from 'react';
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../../helpers/cropImage'
import { Button } from 'reactstrap';

const CropImage = (props) => {
  const { uploadPhoto } = props

  const [imageSrc, setImageSrc] = React.useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    setImageSrc(null)
    setCroppedImage(null)
  }, [])

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      // const orientation = await getOrientation(file)
      // const rotation = ORIENTATION_TO_ANGLE[orientation]
      // if (rotation) {
      //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      // }

      setImageSrc(imageDataUrl)
    }
  }

  const onSave = () => {
    uploadPhoto(croppedImage)
    setTimeout(() => {
      setImageSrc(null)
      setCroppedImage(null)
      setCroppedAreaPixels(null)
    }, 1000)
  }

  return (
    <div>
      {imageSrc ? (
        <React.Fragment>
          <div style={{
            position: 'relative',
            width: '100%',
            height: 300,
            background: '#333',
          }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 4}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
            >
              Show Result
            </Button>
            <Button onClick={onClose}>Reset</Button>
          </div>
        </React.Fragment>
      ) : (
        <input type="file" onChange={onFileChange} accept={props.accept} />
      )}
      {croppedImage ?
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        <img alt='' src={croppedImage} height="220px" width="220px" />
        <Button style={{ width: 100, marginTop: 10 }} onClick={onSave}>Upload</Button>
      </div> : null}
    </div>
  )
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export default CropImage
