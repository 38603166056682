import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Form, Input, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';

class RecommendTags extends Component {
    constructor(props) {
        super(props)

        this.state = {
            newTag: "",
            activeTab: '1'

        }

    }

    componentDidMount() {
        this.props.getRecommendedTags()
    }

    valueChangeHandler = (e) => {
        const {  value } = e.target
        this.setState({
            newTag: value
        })
    }

    submitForm = (e) => {

        let currentTags = this.props.recommendedTags


        e.preventDefault()
        const { value } = e.target[0]
        if (value.trim() === '' || currentTags.includes(value)) return
        currentTags.push(value)
        let data = {
            keywords: currentTags,
            type: 'all'
        }

        this.props.recommendTag(data)

        this.setState({
            newTag: ""
        })
    }

    submitFormSpeaker = (e) => {

        let currentTags = this.props.recommendedSpeakers


        e.preventDefault()
        const { value } = e.target[0]
        if (value.trim() === '' || currentTags.includes(value)) return
        currentTags.push(value)
        let data = {
            keywords: currentTags,
            type: 'speaker'
        }

        this.props.recommendTag(data)

        this.setState({
            newTag: ""
        })
    }

    removeTag = (index, type) => {
        if (type === 'all') {
            let currentTags = this.props.recommendedTags
            currentTags.splice(index, 1)

            let data = {
                keywords: currentTags,
                type: 'all'
            }
            this.props.recommendTag(data)
        } else {
            let currentTags = this.props.recommendedSpeakers
            currentTags.splice(index, 1)

            let data = {
                keywords: currentTags,
                type: 'speaker'
            }
            this.props.recommendTag(data)
        }

    }

    selectTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => {
                this.props.getRecommendedTags()
            })
        }
    }

    render() {
        const { recommendedTags, recommendedSpeakers } = this.props
        let { activeTab } = this.state
        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Recommend</BreadcrumbItem>
                            <BreadcrumbItem active >Keywords</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={activeTab === '1'}
                            onClick={() => { this.selectTab('1'); }} >
                            All Results
                      </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={activeTab === '2'}
                            onClick={() => { this.selectTab('2'); }}>
                            Speakers
                        </NavLink>
                    </NavItem>
                </Nav>

                <Separator />

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Form onSubmit={this.submitForm}>
                                <Colxx xxl='12'>
                                    <Input onChange={this.valueChangeHandler} value={this.state.newTag} placeholder="Type new keyword..." />
                                    <span className="span-grey text-small">Press enter to submit</span>
                                </Colxx>
                            </Form>
                        </Row>

                        <Separator />

                        <Row>
                            <Colxx xxl='12'>
                                <h5 className="span-grey">Recommended Keywords</h5>
                            </Colxx>
                        </Row>
                        <Row>

                            <Colxx xxl='12'>
                                {recommendedTags.map((tag, index) => {
                                    return <span key={index} className="sponsor-tag"> <span> {tag} </span>   <span className="span-delete" onClick={() => this.removeTag(index, 'all')}>  x  </span>  </span>
                                })}
                            </Colxx>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">

                        <Row>
                            <Form onSubmit={this.submitFormSpeaker}>
                                <Colxx xxl='12'>
                                    <Input onChange={this.valueChangeHandler} value={this.state.newTag} placeholder="Type new keyword..." />
                                    <span className="span-grey text-small">Press enter to submit</span>
                                </Colxx>
                            </Form>
                        </Row>

                        <Separator />

                        <Row>
                            <Colxx xxl='12'>
                                <h5 className="span-grey">Recommended Speakers</h5>
                            </Colxx>
                        </Row>
                        <Row>

                            <Colxx xxl='12'>
                                {recommendedSpeakers.map((tag, index) => {
                                    return <span key={index} className="sponsor-tag"> <span> {tag} </span>   <span className="span-delete" onClick={() => this.removeTag(index, 'speaker')}>  x  </span>  </span>
                                })}
                            </Colxx>
                        </Row>
                    </TabPane>
                </TabContent>

            </>
        );
    }
}

export default RecommendTags;