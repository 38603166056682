import React from 'react';
import { Button, Input, FormGroup, Label } from 'reactstrap'
import { confirmAlert } from 'react-confirm-alert';
import { useStoreActions } from 'easy-peasy'

function FlaggedCommentLayout(props) {
    const { comment, pushHistory } = props

    const { deleteComment } = useStoreActions(action => ({
        deleteComment: action.deleteComment
    }))

    var dateFormat = require('dateformat')

    const deleteCommentConfirm = () => {
        confirmAlert({
            title: 'Delete Comment',
            message: 'Are you sure to delete this comment?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteComment({
                        // post: post,
                        comment: comment,
                        parentId: comment.commentType === "reply" ? comment.parentComment : null,
                        pushHistory: pushHistory
                    })

                },
                {
                    label: 'No'
                }
            ]
        });
    }

    return (
        <>
            <FormGroup>
                <Label>Comment Type</Label>
                <Input type="text" readOnly name="type" value={comment.commentType === 'comment' ? "Main comment" : "Reply"} />
            </FormGroup>
            <FormGroup>
                <Label>Comment</Label>
                <Input type="text" readOnly name="text" value={comment.text} />
            </FormGroup>
            <FormGroup>
                <Label>Comment date</Label>
                <Input type="text" readOnly name="date" value={dateFormat(comment.dateCreated, "mmm dd, yyyy - h:MM tt")} />
            </FormGroup>
            <Button color="danger" onClick={deleteCommentConfirm}>Delete Comment</Button>
        </>
    );
}

export default FlaggedCommentLayout;