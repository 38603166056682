import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useStoreActions, useStoreState } from "easy-peasy";
import { Breadcrumb, BreadcrumbItem, Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { baseUrl, imageAcceptFormat } from '../../../constants/defaultValues';
import { createNotification } from '../../../helpers/Notification';
import {compressImg} from "../../../helpers/compressImg";

let forEdit = false
const PodcastCollectionCreate = (props) => {

  const { selectedCollection } = useStoreState(state => ({
    selectedCollection: state.selectedCollection,
  }))

  const { createCollection, editCollection } = useStoreActions(action => ({
    createCollection: action.createCollection,
    editCollection: action.editCollection,
  }))

  const [state, setState] = useState({
    title: '',
    description: '',
    imageUrl: '',
    originalImageUrl: ''
  })

  const [isUploading, setUploading] = useState(false)

  useEffect(() => {
    if (props.match.params.id) {
      forEdit = true
      handleEditData()
    } else {
      forEdit = false
    }
  }, [])

  const handleEditData = () => {
    if (selectedCollection) {
      setState(selectedCollection)
    }
  }

  const updateState = (prop, value) => {
    setState({
      ...state,
      [prop]: value,
    })
  }

  const valueChangeHandler = (e) => {
    const { name, value } = e.target
    updateState(name, value)
  }

  const onFileSelectHandler = async (e) => {
    let files = e.target.files

    try {
      const compressedImg = await compressImg(files[0])

      let reader = new FileReader()
      reader.readAsDataURL(compressedImg)
      const formData = new FormData()

      reader.onload = (e) => {
        formData.append('photo', compressedImg)
        uploadPhoto(compressedImg)
      }
    } catch (err) {
      console.log(err)
      createNotification('waning', 'Failed to upload image')
    }
  }

  const uploadPhoto = (file) => {
    setUploading(true)
    let formData = new FormData()
    formData.append('photo', file)

    axios.post(`${baseUrl}/media/photos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('token')
      }
    }).then(res => {
      let imageUrl = res.data.d.thumbnailUrl
      let originalImageUrl = res.data.d.photoUrl
      setState({
        ...state,
        imageUrl,
        originalImageUrl
      })
      setUploading(false)

    }).catch(err => {
      console.log(err)
      createNotification('waning', 'Failed to upload image')
      updateState('imageUrl', '')
    })
  }

  const createCollectionFunc = () => {
    const { title, description, imageUrl, originalImageUrl } = state

    if (!imageUrl || imageUrl === '') {
      createNotification('warning', 'Upload an Image')
      return
    }

    if (!title && title.trim() === '') {
      createNotification('warning', 'Write title')
      return
    }

    if (!description && description.trim() === '') {
      createNotification('warning', 'Write description')
      return
    }

    let data = {
      title,
      description,
      imageUrl,
      originalImageUrl
    }

    if (forEdit) {
      editCollection({ data, history: props.history, id: selectedCollection?._id })
    } else {
      createCollection({ data, history: props.history })
    }
  }

  const resetFile = () => {
    document.getElementById("file").value = "";
    updateState('imageUrl', null)
  }

  const { imageUrl, title, description, originalImageUrl } = state

  return (
    <div style={{ height: '800px' }}>
      <Row>
        <Colxx xxs='12'>
          <Breadcrumb>
            <BreadcrumbItem>Podcast</BreadcrumbItem>
            <BreadcrumbItem active >{forEdit ? `Edit` : `Create`}</BreadcrumbItem>
          </Breadcrumb>
          <Separator />
        </Colxx>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Podcast Collection image</Label>
            <Input id={'file'} type="file" name="file" onChange={onFileSelectHandler} accept={imageAcceptFormat} />
            {isUploading ? <span>Uploading...</span> : null}
            <br></br>
            {
              originalImageUrl
                ?
                <>
                  <img alt="Profile" src={originalImageUrl} width="300px" />
                </>
                :
                null
            }
          </FormGroup>
          {originalImageUrl ? <Button onClick={resetFile} color="secondary">Delete</Button> : null}
        </Col>

        <Col>
          <Form>

            <FormGroup>
              <Label>Title</Label>
              <Input type="text" name="title" value={title} onChange={valueChangeHandler} />
            </FormGroup>
            <Separator />

            <FormGroup>
              <Label>Description</Label>
              <Input type="textarea" rows="4" name="description" value={description} onChange={valueChangeHandler} />
            </FormGroup>

            <Button onClick={createCollectionFunc} color="primary">{forEdit ? `Save` : `Send`}</Button>
          </Form>
        </Col>

      </Row>
    </div>
  );

}

export default PodcastCollectionCreate;