import React, {useEffect} from 'react';
import './helpers/Firebase'
import { useStoreState } from 'easy-peasy'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import './App.css';
import MainApp from './view/app';
import MainUser from './view/user';
import AuthRoute from './auth/AuthRoute';
import Default from './view';
import {NotificationContainer} from 'react-notifications';
import { FirestoreProvider } from "@react-firebase/firestore";
import firebase from "firebase";
import WindowFocusHandler from './WindowFocusHandler';

const DB_CONFIG = {
  apiKey: 'AIzaSyCXgp2iP0ggjLGXAY6_PJ2cFhgj-H8XsTk',
  authDomain: 'meaningful-minute.firebaseapp.com',
  databaseURL: 'https://meaningful-minute.firebaseio.com',
  projectId: 'meaningful-minute',
  storageBucket: 'meaningful-minute.appspot.com',
  messagingSenderId: '363694318259',
  appId: '1:363694318259:web:7d2d28c168eb68e2dc06c6',
  measurementId: 'G-7911K8VKJY',
}

function App() {

  const session = useStoreState(state => state.session)

  return (
    <FirestoreProvider {...DB_CONFIG} firebase={firebase}>
      <Router>
        <Switch>
          <AuthRoute
            path="/app"
            authUser={session}
            component={MainApp}
          />
          <Route
            path="/user"
            render={props => <MainUser {...props} />}
          />
          <Route
            exact
            path="/"
            render={props => <Default {...props} />}
          />
        </Switch>
        <NotificationContainer/>
      </Router>
      <WindowFocusHandler />
    </FirestoreProvider>
  );
}

export default App;
