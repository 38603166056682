import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Separator } from '../../../components/common/CustomBootstrap';
import axios from 'axios'
import { baseUrl } from '../../../constants/defaultValues';
import PodcastItem from '../../../components/items/PodcastItem';

class Episodes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            episodes: [],
            podcast: null,
        }
    }

    componentDidMount() {
      this.getPodcast()
      this.getEpisodes()
    }

    getPodcast = () => {
        this.props.checkIsExpireToken()
        axios.get(`${baseUrl}/podcastsCollections/${this.props.match.params.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            if (res.data.d) {
                this.setState({
                  podcast: res.data.d
                })
            }
        })
    }


    getEpisodes = () => {
      console.log(222, this.props.match.params.id)
        this.props.checkIsExpireToken()
        axios.get(`${baseUrl}/podcasts?podcastsCollection=${this.props.match.params.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            if (res.data.d) {
                this.setState({
                  episodes: res.data.d
                })
            }
        })
    }

    createPodcast = () => {
        this.props.history.push('/app/podcasts/create')
    }

    render() {
        const { podcast } = this.state
        return (
            <>
              {podcast && <div className="card card-hashtag" style={{ width: '100%' }}>
                <Row>
                  <Col>
                    <p>ID: {podcast?._id}</p>
                    <br />
                    <div className="hashtag-info">
                        <span className="span-author">{podcast?.title}</span>
                        <span className="span-grey">{podcast?.description}</span>
                    </div>
                  </Col>
                  <Col>
                    <img src={podcast?.imageUrl} alt="thumbnail" className='hashtag-image' />
                  </Col>
                </Row>
              </div>}
                <Separator />
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Artwork</th>
                            <th>Title</th>
                            <th>Date Released</th>
                            <th>Media URL</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.episodes.map(item => (
                            <PodcastItem {...this.props} key={item._id} podcast={item} loadPodcast={this.getEpisodes} />
                        ))}
                    </tbody>
                </table>
            </>
        );
    }
}

export default Episodes;