import imageCompression from "browser-image-compression";

export async function compressImg(imageFile) {

    const options = {
        maxSizeMB: 4,
        maxWidthOrHeight: 1024,
        useWebWorker: true
    }

    try {
        const compressedFile = await imageCompression(imageFile, options);
        const imgFile = new File([compressedFile], imageFile.name)
        return imgFile
    } catch (error) {
        console.log(error);
    }
    return imageFile
}