import React, { useState } from 'react';
import { Button, Row } from 'reactstrap';
import axios from 'axios'
import { baseUrl } from '../../constants/defaultValues';
import { createNotification } from '../../helpers/Notification';
import ReactAudioPlayer from 'react-audio-player';
import { useStoreActions } from 'easy-peasy'
import moment from 'moment';
// import {CopyToClipboard} from 'react-copy-to-clipboard';

function PodcastItem(props) {

    const { podcast, loadPodcast } = props

    const { setSelectedPodcast, setNotifData } = useStoreActions(action => ({
        setSelectedPodcast: action.setSelectedPodcast,
        setNotifData: action.setNotifData
    }))

    // const [arrCopy, setArr] = useState([
    //     { text: podcast?.mediaUrl, isCopy: false, title: 'URL' },
    //     { text: podcast?.originalArtwork, isCopy: false, title: 'Artwork' },
    //     { text: podcast?.description, isCopy: false, title: 'Desc' }
    // ])

    const announce = () => {
        setNotifData(podcast)
        props.history.push("/app/announcements/podcast")
    }


    function deletePodcast() {
        const url = `${baseUrl}/podcasts/${podcast._id}`
        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            createNotification("success", "Podcast was deleted")
            loadPodcast()

        }).catch(err => {
            createNotification("error", "Failed to delete podcast")
        })
    }

    function editPodcast() {
        setSelectedPodcast(podcast)
        props.history.push(`/app/podcasts/edit/${podcast._id}`)
    }
    
    return (
        <tr key={podcast._id} style={{ position: 'relative' }}>

            <td>{podcast._id}</td>
            <td>
                <img alt="artwork" src={podcast.artwork} width="80px" />
            </td>
            <td>
                <div className="flex-col">
                    {podcast.title}
                    <div className="flex-col podcast-count">
                        <span className="span-grey">{`${podcast.viewCount} Total view${podcast.viewCount !== 1 ? 's' : ''}`}</span>
                        <span className="span-grey">{`${podcast.uniqueViewCount || 0} Unique view${!podcast.uniqueViewCount || podcast.uniqueViewCount !== 1 ? 's' : ''}`}</span>
                        {/*<span className="span-grey">{`${podcast.downloadCount || 0} Download${!podcast.downloadCount || podcast.downloadCount !== 1 ? 's' : ''}`}</span>*/}
                    </div>
                </div>
            </td>
            <td>{moment.utc(podcast.dateReleased).format('MM/DD/YYYY')}</td>
            {/* <td>{podcast.mediaUrl}</td> */}
            <td>
                <ReactAudioPlayer
                    src={podcast.mediaUrl}
                    controls
                />
            </td>
            <td>
                <Row>
                    <Button style={{ width: 85 }} color="warning" onClick={announce}>Promote</Button>
                </Row>
                <Row>
                    <Button style={{ width: 85 }} color="primary" onClick={editPodcast}>Edit</Button>
                </Row>
                <Row>
                    <Button style={{ width: 85 }} color="danger" onClick={deletePodcast}> Delete </Button>
                </Row>
            </td>

            {/* <Row style={{ position: 'absolute', bottom: 10, left: 30 }}>
                {arrCopy.map(el => {
                    if (el.text) {
                        return (
                            <CopyToClipboard text={el.text || ''}
                                onCopy={() => {
                                    el.isCopy = true
                                    setArr([...arrCopy])
                                    setTimeout(() => {
                                        el.isCopy = false
                                        setArr([...arrCopy])
                                    }, 2000)
                                }}>
                                <Button style={{ marginRight: 10 }} color={el.isCopy ? "success" : "secondary"}>{el.isCopy ? 'Copied' : el.title}</Button>
                            </CopyToClipboard>
                        )
                    } else return null
                })}
            </Row> */}
        </tr>
    );
}

export default PodcastItem;