import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import axios from 'axios'
import {CSVLink} from "react-csv";
import {Colxx, Separator} from '../../../components/common/CustomBootstrap';
import {confirmAlert} from 'react-confirm-alert';
import AccountItem from '../../../components/items/AccountItem';
import Pagination from '../../../components/common/Pagination';
import SearchBarSubmit from '../../../components/common/SearchBarSubmit';
import {baseUrl} from '../../../constants/defaultValues'

//Account Management
class Accounts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal: false,
            selected: "3",
            selectedUser: {},
            order: "asc",
            sort: "level",
            users: [],
            page: 1,
            csvData: [],
            isExporting: false,
            isLoaded: true
        }
    }

    componentDidMount() {
        // this.props.fetchUsers({
        //     page: 1
        // })

        this.setState({
            users: this.props.users
        })
    }

    componentWillReceiveProps(prevProps) {
        if (prevProps.users !== this.props.users) {
            this.setState({
                users: []
            }, () => {
                this.setState({
                    users: [...this.props.users]
                })
            })
        }
    }

    searchList = (query) => {

        if (query.length >= 3) {
            this.props.searchList({
                query: query,
                type: 'accounts'
            })
        } else if (query.length === 0) {
            this.props.fetchUsers({
                page: this.state.page
            })
        }


        // var listToFilter = this.props.users
        // // var listToFilter = this.state.users

        // if (query.length > 0) {
        //     const lquery = query.toLowerCase()
        //     listToFilter = listToFilter.filter(item =>
        //         item.firstName.toLowerCase().includes(lquery) ||
        //         item.lastName.toLowerCase().includes(lquery) ||
        //         item.email.toLowerCase().includes(lquery)
        //     )
        // }

        // this.setState({
        //     users: listToFilter
        // })

    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }

    createUser = () => {
        this.props.history.push('/app/accounts/create')
    }

    openModal = (user) => {
        this.setState({
            selectedUser: user
        })
        this.toggle()
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    valueChangeHandler = (e) => {
        let value = e.target.value

        let newSelected = {...this.state.selectedUser}


        if (value === '1') {
            newSelected.claims = {
                admin: true,
                speaker: false
            }
        } else if (value === '2') {
            newSelected.claims = {
                admin: false,
                speaker: true
            }
        } else {
            newSelected.claims = {
                admin: false,
                speaker: false
            }
        }

        this.setState({
            selected: value,
            selectedUser: newSelected
        })
    }

    updateUserLevel = () => {
        const {firstName, lastName} = this.state.selectedUser
        this.toggle()

        let alertMessage = 'This account will be granted or revoked of some privileges.'

        // confirmAlert({
        //     title: 'Warning',
        //     message: alertMessage,
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: () => {
        //                 this.props.updateUserLevel({
        //                     user: this.state.selectedUser,
        //                     selected: this.state.selected
        //                 })
        //             }
        //         },
        //         {
        //             label: 'No'
        //         }
        //     ]
        // });


        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-dialog'>
                        <h1>Warning</h1>
                        <p>{alertMessage}</p>
                        <p>{'Are you sure to update ' + firstName + ' ' + lastName + '\'s user level?'}</p>
                        <p className="red-text">This process may take time due to the number of registered users.
                            Updating levels of multiple users at the same time may cause an error.</p>
                        <Button className="button-dialog" onClick={onClose}>No</Button>
                        <Button color="primary"
                                onClick={() => {
                                    this.props.updateUserLevel({
                                        user: this.state.selectedUser,
                                        selected: this.state.selected
                                    })
                                    onClose();
                                }}
                        >
                            Yes
                        </Button>
                    </div>
                );
            }
        });
    }

    filterData = () => {
        let {users} = this.props

        let newData = users
        newData = users.filter(user => user.claims.speaker)

        let csvData = []

        newData.forEach(user => {
            let object = {
                _id: user._id,
                uId: user.uid,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                dateCreated: user.dateCreated,
                dateUpdated: user.dateUpdated
            }
            csvData.push(object)
        });


        return csvData

    }

    sortData = (e) => {
        const {name, value} = e.target

        this.setState({
            [name]: value
        }, () => {
            // this.props.sortList({
            //     value: this.state.sort,
            //     order: this.state.order
            // })
            this.newSorting()
        })

    }

    newSorting = () => {

        const {sort, order} = this.state

        function compare(a, b) {

            let aDate
            let bDate
            let condition

            switch (sort) {

                case "firstName":
                    condition = order === 'asc' ? a.firstName > b.firstName : a.firstName < b.firstName
                    if (condition) {
                        return 1
                    } else {
                        return -1
                    }
                case "lastName":
                    condition = order === 'asc' ? a.lastName > b.lastName : a.lastName < b.lastName
                    if (condition) {
                        return 1
                    } else {
                        return -1
                    }
                case "email":
                    condition = order === 'asc' ? a.email > b.email : a.email < b.email
                    if (condition) {
                        return 1
                    } else {
                        return -1
                    }
                case "created":
                    aDate = new Date(a.dateCreated)
                    bDate = new Date(b.dateCreated)

                    condition = order === 'asc' ? aDate < bDate : aDate > bDate

                    if (condition) {
                        return -1
                    } else {
                        return 1
                    }
                case "updated":
                    aDate = new Date(a.dateUpdated)
                    bDate = new Date(b.dateUpdated)

                    condition = order === 'asc' ? aDate < bDate : aDate > bDate
                    if (condition) {
                        return -1
                    } else {
                        return 1
                    }
                    ;
                default:

                    if (a.claims.speaker > b.claims.speaker) {
                        return -1;
                    }
                    if (a.claims.admin > b.claims.admin) {
                        return -1;
                    }
                    return 1;

            }
        }

        var currentList = this.state.users

        currentList = currentList.sort(compare)
        this.setState({
            users: currentList
        })

    }

    getUsersForExport = async (newUrl) => {

        this.setState({
            isLoaded: false,
            isExporting: true
        })

        const url = newUrl === '' ? `${baseUrl}/usersForCsv?lim=500&p=1` : newUrl
        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token") || ''
            }
        }).then(res => {
            const {next, d} = res.data

            this.addToCsvData(d)

            if (next && d.length > 0) {
                this.getUsersForExport(next)
            } else {
                this.setState({
                    isLoaded: true
                })
            }

        }).catch(err => {
            console.log(err)
            this.setState({
                isLoaded: true,
                isExporting: false
            })
        })
    }

    addToCsvData = async (newData) => {
        const {csvData} = this.state

        const newCsvData = []
        await newData.forEach(user => {
            let object = {
                _id: user._id,
                uId: user.uid,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                imageUrl: user.imageUrl,
                phone: user.phone,
                admin: user.claims.admin,
                speaker: user.claims.speaker,
                dateCreated: user.dateCreated,
                dateUpdated: user.dateUpdated
            }
            newCsvData.push(object)
        });

        let csvToUpdate = [...csvData, ...newCsvData]
        this.setState({
            csvData: csvToUpdate
        })
    }

    removeSearching = () => {
        this.props.fetchUsers()
    }

    render() {
        let {modal, selectedUser, csvData, isLoaded, isExporting} = this.state
        return (
            <>
                {selectedUser._id ?
                    <Modal isOpen={modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>{selectedUser.firstName} {selectedUser.lastName}</ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup tag="fieldset">
                                    <Label>Update User Level</Label>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio"
                                                   onChange={this.valueChangeHandler}
                                                   checked={selectedUser.claims.admin}
                                                   name="selected"
                                                   value={"1"}/>
                                            Admin
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio"
                                                   onChange={this.valueChangeHandler}
                                                   checked={selectedUser.claims.speaker}
                                                   name="selected"
                                                   value={"2"}/>
                                            Speaker
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio"
                                                   onChange={this.valueChangeHandler}
                                                   checked={!selectedUser.claims.admin && !selectedUser.claims.speaker}
                                                   name="selected"
                                                   value={"3"}/>
                                            User
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.updateUserLevel}>Save Changes</Button>{' '}
                            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    : ""}

                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>Accounts</BreadcrumbItem>
                            <BreadcrumbItem active>List</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator/>
                    </Colxx>
                </Row>

                <Row>
                    <Colxx lg='6' xx='3'>
                        {/* <SearchBar searchHandler={this.searchList} /> */}
                        <SearchBarSubmit removeSearching={this.removeSearching} type='accounts'/>
                    </Colxx>
                    <Colxx lg='6' xxs='3'>
                        <Button color="primary" onClick={this.createUser}>Create User</Button>
                    </Colxx>

                    <Colxx lg='6' xxs='3'/>
                    <Colxx lg='6' xxs='3'>
                        {!isExporting ?
                            <Button color="success" onClick={() => {
                                this.getUsersForExport('')
                            }}>Export</Button>
                            :
                            !isLoaded ?
                                <><Spinner size="sm" color="primary"/> Constructing data </>
                                :
                                <CSVLink
                                    filename={"meaningful-minute-accounts.csv"}
                                    className='csv-link'
                                    onClick={() => {
                                        this.setState({
                                            isExporting: false,
                                            isLoaded: true,
                                            csvData: []
                                        })
                                    }}
                                    data={csvData}>DOWNLOAD CSV</CSVLink>
                        }
                    </Colxx>
                </Row>
                <Separator/>
                <Row>
                    <Colxx xxs='6' lg='3'>
                        <Label for="filter">Sort by:</Label>
                        <Input type="select" name="sort" id="sort" onChange={this.sortData} defaultValue="all">
                            <option value="level">User level</option>
                            <option value="firstName">First Name</option>
                            <option value="lastName">Last Name</option>
                            <option value="email">Email</option>
                            <option value="created">Date Created</option>
                            <option value="updated">Date Updated</option>
                        </Input>
                    </Colxx>
                    <Colxx xxs='3' lg='1'>
                        <Label for="filter">Order by:</Label>
                        <Input type="select" name="order" id="order" onChange={this.sortData} defaultValue="all">
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </Input>
                    </Colxx>
                </Row>
                <Separator/>
                <table className='table'>
                    <thead>
                    <tr>
                        {/* <th>ID</th> */}
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>User Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Role</th>
                        <th>Date Created</th>
                        <th>Last Updated</th>
                        <th>App Version</th>
                        <th>Reset</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.users.map(user => (
                        <AccountItem {...this.props} user={user} key={user._id} openModal={this.openModal}/>
                    ))}
                    </tbody>
                </table>

                <Row>
                    <Col sm="12">
                        <Pagination type={"user"} setPage={this.setPage}/>
                    </Col>
                </Row>

            </>
        )
    }
}

export default Accounts