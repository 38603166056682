import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import FlaggedUserLayout from "../../../components/common/FlaggedUserLayout";

class FlaggedUserDetails extends Component {

    removeUser = () => {
        const { selectedUser, deleteUser } = this.props
        deleteUser({
            id: selectedUser.authorUid,
            pushHistory: this.pushHistory()

        })
    }

    pushHistory = () => {
        this.props.history.push(`/app/flagged/${this.props.match.params.type}`)
    }

    render() {

        const { selectedUser } = this.props
        const { type } = this.props.match.params

        return (
            <>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb>
                            <BreadcrumbItem>{type}</BreadcrumbItem>
                            <BreadcrumbItem>Flagged</BreadcrumbItem>
                            <BreadcrumbItem active >{ selectedUser?._id }</BreadcrumbItem>
                        </Breadcrumb>
                        <Separator />
                    </Colxx>
                </Row>

                <Row>
                    <Col>
                        <h3>User Details</h3>
                        <FormGroup>
                            <Label>User UID</Label>
                            <Input type="text" readOnly name="id" value={ selectedUser?._id } />
                        </FormGroup>
                        <FormGroup>
                            <Label>Full Name</Label>
                            <Input type="text" readOnly name="name" value={ selectedUser?.fullName } />
                        </FormGroup>
                        <FormGroup>
                            <Label>Times this user was flagged</Label>
                            <Input type="text" readOnly name="counter" value={ selectedUser?.numberOfComplaints } />
                        </FormGroup>
                    </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input type="text" readOnly name="id" value={selectedUser?.email} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Phone</Label>
                                    <Input type="text" readOnly name="id" value={selectedUser?.phone} />
                                </FormGroup>
                                <FlaggedUserLayout post={selectedUser} pushHistory={this.pushHistory} />
                            </Col>
                </Row>
            </>
        );
    }
}

export default FlaggedUserDetails;