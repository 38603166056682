import React from 'react'
import {Carousel} from "react-bootstrap";

const ImagesSlider = ({images}) => {

    return (
        <Carousel interval={3000}>
            {images.map((img, i) =>
                <Carousel.Item key={i} >
                    <img
                        className="d-block w-100 img"
                        src={img.thumbnailUrl || img.uri}
                        alt={`Slide ${i}`}
                    />
                </Carousel.Item>
            )}
        </Carousel>
    )
}

export default ImagesSlider

