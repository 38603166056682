import React, {useEffect, useState} from 'react';
import {Breadcrumb, BreadcrumbItem, Row, Button, Nav, NavItem, NavLink} from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { useStoreActions } from "easy-peasy";
import { confirmAlert } from "react-confirm-alert";
import ThumbItem from '../../../components/items/ThumbItem';
import moment from 'moment';
import Api from '../../../helpers/Api';

const ApiReq = new Api()

const Modals = (props) => {
  const { checkIsExpireToken, checkError } = useStoreActions(action => ({
    checkIsExpireToken: action.checkIsExpireToken,
    checkError: action.checkError,
  }))

  ApiReq.checkError = checkError
  ApiReq.checkIsExpireToken = checkIsExpireToken

  const [dataModals, setModals] = useState(null)
  const [updateAppModals, setUpdateAppModals] = useState([])

  const [activeTab, setActiveTab] = useState(1)

  useEffect(() => {
    ApiReq.getModals(1, setModals)
    ApiReq.getUpdateModals(setUpdateAppModals)
  }, [])

  const createModal = () => {
    props.history.push('/app/modal/create')
  }

  const edit = (id) => {
    props.history.push(`/app/modal/edit/${id}`)
  }

 const rmMoodal = (id) => {
  const newModals  = {...dataModals}
  newModals.modals = newModals.modals.filter(item => item._id !== id)
  setModals(newModals)
  ApiReq.deleteModal({ id })
 }

  const removeModal = (id) => {
    confirmAlert({
      title: 'Delete Modal',
      message: 'Are you sure to delete this modal?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => rmMoodal(id)

        },
        {
          label: 'No'
        }
      ]
    });
  }

  const selectTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <>
      <Row>
        <Colxx xxs='12'>
          <Breadcrumb>
            <BreadcrumbItem>Modals</BreadcrumbItem>
            <BreadcrumbItem active >{'List'}</BreadcrumbItem>
          </Breadcrumb>
          <Separator />
          <Nav className={'landing-tabs'} tabs>
            <NavItem>
              <NavLink
                active={activeTab === 1}
                onClick={() => { selectTab(1); }}>
                Modals
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 2}
                onClick={() => { selectTab(2); }} >
                Update Modals
              </NavLink>
            </NavItem>
          </Nav>

          {activeTab === 1
            ?
            <>
              <Row>
                <Colxx lg='6' xxs='3'>
                  <Button className={'podcast-button'} color="primary" onClick={createModal}>Add Modal Window</Button>
                </Colxx>
              </Row>
              <Row>
                {dataModals && dataModals?.modals?.map(item => {
                  return <ThumbItem key={item?._id} item={item} remove={removeModal} edit={edit} children={() => (
                    <>
                      <p><b>User type:</b> {item.usersType || ''}</p>
                      <p><b>Button title:</b> {item.buttonTitle}</p>
                      <p><b>All count:</b> {item.allCount || 0}</p>
                      <p><b>Seen count:</b> {item.watchedCount || 0}</p>
                      {
                        item.expiryDate
                          ? <p><b>Expiration date:</b> {moment(item.expiryDate).format('MM/DD/YYYY') || 0}</p>
                          : <>
                            <p><b>Start date:</b> {moment(item.startDate).format('MM/DD/YYYY - hh:mm') || 0}</p>
                            <p><b>End date:</b> {moment(item.endDate).format('MM/DD/YYYY - hh:mm') || 0}</p>
                          </>
                      }
                      <p><b>Button click count:</b> {item.clickButtonCount || 0}</p>
                    </>
                  )}/>
                })}
              </Row>
            </>
            : <>
            <Row>
              {updateAppModals && updateAppModals.map((item) => {
                return (
                  <div key={item?._id} className="card card-hashtag">
                    <p><b>App version</b> {item?.versionApp || ''}</p>
                    <p><b>Updated:</b> {item.updated}</p>
                    <p><b>Later:</b> {item.skipped || 0}</p>
                    <p><b>Canceled:</b> {item.canceled || 0}</p>
                  </div>
                )
              })
              }
            </Row>
            </>
          }
        </Colxx>
      </Row>

    </>
  )
}

export default Modals
