import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Form, FormGroup, Label, Input, Row, Button } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import axios from 'axios'
import {appScreen, baseUrl, defaultScreens, imageAcceptFormat} from '../../../constants/defaultValues';
import { createNotification } from '../../../helpers/Notification';
import { useStoreActions } from "easy-peasy";

let forEdit = false
const ThumbnailCreate = (props) => {

  const { createThumbnail, editThumbnail } = useStoreActions(action => ({
    createThumbnail: action.createThumbnail,
    editThumbnail: action.editThumbnail,
  }))

  const [state, setState] = useState({
    id: '',
    imageUrl: '',
    originalImageUrl: '',
    path: '',
    type: '',
    screen: null,
    name: '',
    podcastId: '',
  })

  const [isUploading, setUploading] = useState(false)
  const [thumbItem, setThumbItem] = useState(null)


  useEffect(() => {
    if (props.match.params.id) {
      forEdit = true
      handleEditData()
    } else {
      forEdit = false
    }
  }, [])

  const handleEditData = () => {
    getThumbnail(props.match.params.id)
  }

  const getThumbnail = (id) => {
    axios.get(`${baseUrl}/thumbnails/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token") || ''
      }
    }).then(res => {
      const item = res?.data?.d
      if (item) {
        setThumbItem(item)
        const ind = item.stack.length > 1 ? 1 : 0
        const idPodcast = item.stack.length > 1 ? item?.stack[0]?.params?.id : null
        const newItem = {
          id: item?.stack[ind]?.params?.id,
          imageUrl: item?.imageUrl,
          originalImageUrl: item?.originalImageUrl,
          path: item?.stack[ind]?.path,
          type: item?.type,
          screen: item?.stack[ind]?.path,
          name: item?.name,
          podcastId: idPodcast,
        }
        setState(newItem)
      }
    }).catch(err => {

    })
  }

  const updateState = (prop, value) => {
    setState({
      ...state,
      [prop]: value,
    })
  }

  const valueChangeHandler = (e) => {
    const { name, value } = e.target
    updateState(name, value)
  }


  const onSelectScreen =  (el) => {
    const i = el.target.value
    const item = defaultScreens[i]
    setState({
      ...state,
      path: item?.link,
      type: item?.type,
      name: item?.name,
      podcastId: '',
      id: '',
    })
  }

  const onSelectAppScreen =  (el) => {
    const i = el.target.value
    const item = appScreen[i]
    setState({
      ...state,
      path: '',
      screen: item?.link,
      name: item?.name,
      podcastId: '',
      id: '',
    })
  }

  const onFileSelectHandler = (e) => {
    let files = e.target.files
    let reader = new FileReader()
    reader.readAsDataURL(files[0])
    const formData = new FormData()

    reader.onload = (e) => {
      formData.append('photo', files[0])
      uploadPhoto(files[0])
    }
  }

  const uploadPhoto = (file) => {
    setUploading(true)
    let formData = new FormData()
    formData.append('photo', file)

    axios.post(`${baseUrl}/media/photos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('token')
      }
    }).then(res => {

      let imageUrl = res.data.d.thumbnailUrl
      let originalImageUrl = res.data.d.photoUrl
      setState({
        ...state,
        imageUrl,
        originalImageUrl
      })
      setUploading(false)

    }).catch(err => {
      console.log(err)
      createNotification('waning', 'Failed to upload image')
      updateState('imageUrl', '')
    })
  }

  const createCollectionFunc = () => {
    const { id, imageUrl, originalImageUrl } = state

    if (!imageUrl || imageUrl === '') {
      createNotification('warning', 'Upload an Image')
      return
    }

    const objReq = thumbItem?.stack[0] || { params: {} }

    const obj = objReq

    obj.path = state?.path ? state?.path : state?.screen

    if (id) {
      obj.params.id = id
    }
    if (state?.name && state.name !== objReq?.name) {
      obj.params.name = state?.name
    }

    let data = {
      type: state?.type,
      stack: [obj],
      imageUrl: imageUrl,
      originalImageUrl: originalImageUrl,
    }

    if (state?.podcastId) {
      data.stack[0].params.name = 'Episode'
      data.stack.unshift(
        {
          path: 'Podcasts',
          params: {
            name: 'Podcast',
            id: state.podcastId,
          }
        }
      )
    }

    if (forEdit) {
      data.id = thumbItem._id
      editThumbnail({ data, history: props.history, id: thumbItem?._id })
    } else {
      createThumbnail({ data, history: props.history })
    }
  }

  const resetFile = () => {
    document.getElementById("file").value = "";
    updateState('imageUrl', null)
  }

  const { imageUrl, id } = state

  return (
    <div style={{ height: '800px' }}>
      <Row>
        <Colxx xxs='12'>
          <Breadcrumb>
            <BreadcrumbItem>Thumbnails</BreadcrumbItem>
            <BreadcrumbItem active>{forEdit ? `Edit` : `Create`}</BreadcrumbItem>
          </Breadcrumb>
          <Separator />
        </Colxx>
      </Row>
      <div className={'thumbnail-create'}>
        <div className={'thumb-part'}>
          <FormGroup>
            <Label>Thumbnail image</Label>
            <Input id={'file'} type="file" name="file" onChange={onFileSelectHandler} accept={imageAcceptFormat} />
            {isUploading ? <span>Uploading...</span> : null}
            <br></br>
            {
              imageUrl
                ?
                <>
                  <img alt="Profile" src={imageUrl} width="300px" />
                </>
                :
                null
            }
          </FormGroup>
          {imageUrl ? <Button onClick={resetFile} color="secondary">Delete</Button> : null}
        </div>
        <Separator />

        <div className={'thumb-part'}>
          <Form>
            <FormGroup>
              <Label for="body">Screens: </Label>
              <Input type="select" name="otherId" id="exampleSelect" onChange={onSelectScreen}>
                {defaultScreens.map((item, index) => {
                  return <option
                    selected={item?.type === state?.type}
                    key={index}
                    value={index}>{item.name}</option>
                })}
              </Input>
            </FormGroup>

            {state && state.hasOwnProperty('type') && state?.type ?
              state?.type !== 'screen' ?
              <>
                {state?.type === 'podcast' ?
                  <FormGroup>
                    <Label>Podcast Id</Label>
                    <Input type="text" name="podcastId" value={state?.podcastId} onChange={valueChangeHandler} />
                  </FormGroup>
                : null}
                <FormGroup>
                  <Label>{defaultScreens.find(i => i.type === state?.type)?.name} {state?.type !== 'externalLink' ? 'Id' : ''}</Label>
                  <Input type="text" name="id" value={id} onChange={valueChangeHandler} />
                </FormGroup>
              </>
              : <>
                <Label for="body">Application screens: </Label>
                <Input type="select" name="otherId" id="exampleSelect" onChange={onSelectAppScreen}>
                  {appScreen.map((item, index) => {
                    return <option
                      selected={item.link === state?.screen}
                      key={index}
                      value={index}>{item.name}</option>
                  })}
                </Input>
              </>
              : null
            }
            <Separator />
            <Button onClick={createCollectionFunc} color="primary">{forEdit ? `Save` : `Send`}</Button>
          </Form>
        </div>

      </div>
    </div>
  );

}

export default ThumbnailCreate;